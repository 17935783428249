import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class AuswertungPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);
    this.read = permissionChecker.match(Permissions.values.auswertungRead);
    this.navigate = permissionChecker.match(
      Permissions.values.auswertungNavigate,
    );
  }
}
