<template>
  <div>
    <img :src="logoImg" />
  </div>
</template>

<script>
export default {
  name: 'app-signup-logo',

  props: ['logoImg'],
};
</script>

<style>
</style>