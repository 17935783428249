import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import { GenericModel } from '@/shared/model/generic-model';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import StringField from '@/shared/fields/string-field';

function label(name) {
  return i18n(`entities.staatsbuergerschaft.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  langtext: new StringField('langtext', label('langtext'), {
    required: true,
    max: 255,
  }),
  nationscode: new StringField('nationscode', label('nationscode'), {
    required: true,
    max: 255,
  }),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class StaatsbuergerschaftModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
