<template>
  <div class="money-input">
    <el-input
      ref="inputRef"
      type="text"
      :value="formattedValue"
      :disabled="disabled"
      :readonly="readonly"
    />
  </div>
</template>

<script>
import { watch } from '@vue/composition-api';
import { useCurrencyInput } from 'vue-currency-input';

export default {
  name: 'app-money-input',
  props: {
    value: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  setup(props) {
    const { inputRef, formattedValue, setValue } = useCurrencyInput({
      locale: 'de-DE',
      currency: 'EUR',
      currencyDisplay: 'hidden',
      valueRange: {
        min: 0,
        max: 1000000,
      },
      precision: 2,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true,
    });

    watch(
      () => props.value,
      () => {
        // Vue 2: props.value
        setValue(props.value);
      },
    );

    return { inputRef, formattedValue };
  },
};
</script>
<style scoped></style>
