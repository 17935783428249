<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-on:close="doCancel"
      width="20%"
    >
      <div v-loading="saveLoading">
        <app-jahreskarte-form
          :isEditing="isEditing"
          :isViewing="isViewing"
          :record="record"
          :saveLoading="saveLoading"
          @cancel="doCancel"
          @submit="doSubmit"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import JahreskarteForm from '@/modules/jahreskarte/components/jahreskarte-form.vue';
import { i18n } from '@/i18n';
export default {
  name: 'app-jahreskarte-form-modal',
  props: ['record', 'mitarbeiter', 'visible', 'isExtending', 'isViewing'],
  components: {
    [JahreskarteForm.name]: JahreskarteForm,
  },

  async created() {
    if (!this.isEditing) {
      await this.doNew();
    }
  },

  computed: {
    ...mapGetters({
      saveLoading: 'jahreskarte/form/saveLoading',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    isEditing() {
      return !!this.record && !this.isExtending && !this.isViewing;
    },
    title() {
      return this.isEditing
        ? i18n('entities.jahreskarte.dialogTitle.edit')
        : i18n('entities.jahreskarte.dialogTitle.new');
    },
  },

  methods: {
    ...mapActions({
      doNew: 'jahreskarte/form/doNew',
      doUpdate: 'jahreskarte/form/doUpdate',
      doCreate: 'jahreskarte/form/doCreate',
    }),

    async doSubmit(payload) {
      if (this.isEditing) {
        await this.doUpdate(payload);
      } else {
        payload.values.mitarbeiter = this.mitarbeiter;
        payload.values.isExtending = this.isExtending;
        await this.doCreate(payload.values);
      }
      this.dialogVisible = false;
    },

    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style></style>
