import staatsbuergerschaftListStore from '@/modules/staatsbuergerschaft/staatsbuergerschaft-list-store';
import staatsbuergerschaftImporterStore from '@/modules/staatsbuergerschaft/staatsbuergerschaft-importer-store';
import staatsbuergerschaftFormStore from '@/modules/staatsbuergerschaft/staatsbuergerschaft-form-store';
import staatsbuergerschaftDestroyStore from '@/modules/staatsbuergerschaft/staatsbuergerschaft-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: staatsbuergerschaftDestroyStore,
    form: staatsbuergerschaftFormStore,
    list: staatsbuergerschaftListStore,
    importer: staatsbuergerschaftImporterStore,
  },
};
