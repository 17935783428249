import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class KnowledgeBasePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.knowledgeBaseRead,
    );
    this.create = permissionChecker.match(
      Permissions.values.knowledgeBaseCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.knowledgeBaseEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.knowledgeBaseDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.knowledgeBaseNavigate,
    );
  }
}
