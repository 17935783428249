import authAxios from '@/shared/axios/auth-axios';
export class VertragService {
  static async create(data) {
    const body = {
      data,
    };
    const response = await authAxios.post(`/vertrag`, body);
    return response.data;
  }
  static async createDraft(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.post(`/vertrag/entwurf`, body);
    return response.data;
  }
  static async createManual(data) {
    const body = {
      data,
    };
    const response = await authAxios.post(`/vertrag/manual`, body);
    return response.data;
  }
  static async list(id, orderBy, limit, offset) {
    const params = {
      id,
      orderBy,
      limit,
      offset,
    };
    const response = await authAxios.get(`/vertrag`, {
      params,
    });
    return response.data;
  }
  static async listPending() {
    const response = await authAxios.get(`/vertrag/pending`, {});
    return response.data;
  }
  static async update(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(`/vertrag/${id}`, body);
    return response.data;
  }
  static async updateStatus(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(`/vertrag/entwurf-status/${id}`, body);
    return response.data;
  }
  static async setInactive(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(`/vertrag/set-inactive/${id}`, body);
    return response.data;
  }
  static async setActive(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(`/vertrag/set-active/${id}`, body);
    return response.data;
  }
  static async sendEmailToMitarbeiter(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(`/vertrag/send-email/${id}`, body);
    return response.data;
  }
  static async destroyAll(ids) {
    const params = {
      ids,
    };
    const response = await authAxios.delete(`/vertrag`, {
      params,
    });
    return response.data;
  }
  static async listAutocomplete(limit, query, parentId, filter) {
    const params = {
      limit,
      query,
      parentId,
      filter,
    };
    const response = await authAxios.get(`/vertrag/autocomplete`, {
      params,
    });
    return response.data;
  }
}
