<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column
        type="selection"
        :selectable="selectable"
        width="40"
        v-if="hasPermissionToDestroy"
      ></el-table-column>
      <el-table-column
        :label="fields.vertrag.label"
        :prop="fields.vertrag.name"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.vertrag.label"
            :readPermission="fields.vertrag.readPermission"
            :navigatePermission="false"
            :value="presenter(scope.row, 'vertrag')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.entwurf.label"
        :prop="fields.entwurf.name"
      >
        <template v-slot="scope">
          <app-list-item-file
            :permissions="fields.entwurf.permissions"
            :value="presenter(scope.row, 'entwurf')"
          ></app-list-item-file>
        </template>
      </el-table-column>

      <el-table-column
        label="Inflation GA"
        width="80"
        :prop="fields.inflationGehaltsanpassung.name"
      >
        <template v-slot="scope">
          {{ isInflationGehaltsanpassung(scope.row) }}
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.entwurfStatus.label"
        :prop="fields.entwurfStatus.name"
        width="130"
      >
        <template v-slot="scope">
          <el-tag
            :type="getStatusTagType(scope.row[fields.entwurfStatus.name])"
            v-if="isStatusNull(scope.row[fields.entwurfStatus.name])"
          >
            {{ presenter(scope.row, 'entwurfStatus') }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.decisionDate.label"
        :prop="fields.decisionDate.name"
        width="145"
      >
        <template v-slot="scope">
          {{ presenter(scope.row, 'decisionDate') }}
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.sentAt.label"
        :prop="fields.sentAt.name"
        width="135"
      >
        <template v-slot="scope">{{ presenter(scope.row, 'sentAt') }}</template>
      </el-table-column>
      <el-table-column
        :label="fields.unterschriebenerVertragsaenderung.label"
        :prop="fields.unterschriebenerVertragsaenderung.name"
      >
        <template v-slot="scope">
          <app-list-item-file
            :permissions="fields.unterschriebenerVertragsaenderung.permissions"
            :value="presenter(scope.row, 'unterschriebenerVertragsaenderung')"
          ></app-list-item-file>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.vertragsaenderungStatus.label"
        :prop="fields.vertragsaenderungStatus.name"
        width="120"
      >
        <template v-slot="scope">
          <el-tag
            :type="
              getStatusTagType(scope.row[fields.vertragsaenderungStatus.name])
            "
            v-if="isStatusNull(scope.row[fields.vertragsaenderungStatus.name])"
          >
            {{ presenter(scope.row, 'vertragsaenderungStatus') }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        :label="fields.vertragsaenderungUploadDate.label"
        :prop="fields.vertragsaenderungUploadDate.name"
      >
        <template v-slot="scope">
          {{ presenter(scope.row, 'vertragsaenderungUploadDate') }}
        </template>
      </el-table-column>
      <el-table-column align="center" width="180">
        <template v-slot="scope">
          <div class="table-actions">
            <el-button
              v-if="editButtonEnabled(scope.row)"
              v-on:click="doOpenFormModal(scope.row)"
              type="text"
            >
              <app-i18n code="common.edit"></app-i18n>
            </el-button>
            <el-button
              v-on:click="doOpenPreviewModal(scope.row)"
              type="text"
              v-if="previewButtonEnabled(scope.row)"
            >
              <app-i18n code="common.preview"></app-i18n>
            </el-button>
            <el-button v-on:click="doOpenRequestsModal(scope.row)" type="text">
              <app-i18n code="common.status"></app-i18n>
            </el-button>
            <el-button
              v-if="setInactiveButtonEnabled(scope.row)"
              v-on:click="doSetInactive(scope.row)"
              type="text"
            >
              <app-i18n code="common.setInactive"></app-i18n>
            </el-button>
            <el-button
              v-if="sendButtonEnabled(scope.row)"
              v-on:click="doSendToMitarbeiter(scope.row)"
              type="text"
            >
              <app-i18n code="common.send"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { VertragsaenderungModel } from '@/modules/vertragsaenderung/vertragsaenderung-model';
import { mapGetters, mapActions } from 'vuex';
import { VertragsaenderungPermissions } from '@/modules/vertragsaenderung/vertragsaenderung-permissions';
import { i18n } from '@/i18n';

const { fields } = VertragsaenderungModel;

export default {
  name: 'app-vertragsaenderung-list-table',

  props: ['isDisabled'],

  data() {
    return {};
  },

  mounted() {
    this.$watch(
      () => {
        return this.$refs.table.selection;
      },
      (rows) => {
        this.$store.dispatch('vertragsaenderung/doSetSelectedRows', rows);
      },
    );
  },

  computed: {
    ...mapGetters({
      rows: 'vertragsaenderung/rows',
      count: 'vertragsaenderung/count',
      loading: 'vertragsaenderung/loading',
      hasRows: 'vertragsaenderung/hasRows',
      pagination: 'vertragsaenderung/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),
    hasPermissionToCreate() {
      return new VertragsaenderungPermissions(this.currentUser).create;
    },
    hasPermissionToEdit() {
      return new VertragsaenderungPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new VertragsaenderungPermissions(this.currentUser).destroy;
    },
    hasPermissionToSendEmailToMA() {
      return new VertragsaenderungPermissions(this.currentUser)
        .sendEmailToEmployee;
    },
    hasPermissionToSendEmailToMAMultipleTimes() {
      return new VertragsaenderungPermissions(this.currentUser)
        .sendEmailToEmployeeMultipleTimes;
    },
    hasPermissionToSetInactive() {
      return new VertragsaenderungPermissions(this.currentUser).setToInactive;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'vertragsaenderung/doChangeSort',
      doChangePaginationCurrentPage:
        'vertragsaenderung/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'vertragsaenderung/doChangePaginationPageSize',
      doSendEmailToMitarbeiter: 'vertragsaenderung/doSendEmailToMitarbeiter',
      doSetToInactive: 'vertragsaenderung/doSetToInactive',
    }),

    presenter(row, fieldName) {
      return VertragsaenderungModel.presenter(row, fieldName);
    },

    sendButtonEnabled(row) {
      if (row.entwurfStatus != 'active') {
        return false;
      }
      if (row.vertragsaenderungStatus === 'active') {
        return false;
      }
      const alreadySent = row.entwurfStatus === 'active' && Boolean(row.sentAt);

      return (
        (alreadySent
          ? this.hasPermissionToSendEmailToMAMultipleTimes
          : this.hasPermissionToSendEmailToMA) && !this.isDisabled
      );
    },

    editButtonEnabled(row) {
      if (row.entwurfStatus != 'active') {
        return false;
      }
      if (row.vertragsaenderungStatus === 'active') {
        return false;
      }

      return this.hasPermissionToEdit && !this.isDisabled;
    },
    previewButtonEnabled(row) {
      if (row.entwurfStatus != 'releasePending') {
        return false;
      } else return true;
    },

    setInactiveButtonEnabled(row) {
      if (
        row.vertragsaenderungStatus &&
        row.vertragsaenderungStatus === 'active' &&
        this.hasPermissionToSetInactive
      ) {
        return true;
      } else return false;
    },

    selectable(row) {
      return row.entwurfStatus !== 'active' || this.hasPermissionToDestroy;
    },

    doOpenPreviewModal(row) {
      return this.$emit('doOpenPreviewModal', row);
    },

    doOpenFormModal(row) {
      return this.$emit('doOpenFormModal', row);
    },

    doOpenRequestsModal(row) {
      return this.$emit('doOpenRequestsModal', row.approvalRequest);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },

    async doSendToMitarbeiter(row) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        const { id, ...values } = row;

        return this.doSendEmailToMitarbeiter({ id, values });
      } catch (error) {
        // no
      }
    },

    doSetInactive(row) {
      const { id, ...values } = row;
      return this.doSetToInactive({ id, values });
    },

    isStatusNull(entwurfStatus) {
      return entwurfStatus === null ? false : true;
    },

    getStatusTagType(entwurfStatus) {
      if (entwurfStatus === 'active') {
        return 'success';
      }
      if (
        entwurfStatus === 'requested' ||
        entwurfStatus === 'revisionRequested'
      ) {
        return 'warning';
      }
      if (entwurfStatus === 'rejected') {
        return 'danger';
      }
      if (entwurfStatus === 'releasePending') {
        return '';
      }
      if (entwurfStatus === 'inactive') {
        return 'info';
      }
      return '';
    },
    isInflationGehaltsanpassung(row) {
      return row.inflationGehaltsanpassung ? 'Ja' : '';
    },
  },
};
</script>

<style></style>
