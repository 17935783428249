import importerStore from '@/shared/importer/importer-store';
import { KostenstelleService } from '@/modules/kostenstelle/kostenstelle-service';
import kostenstelleImporterFields from '@/modules/kostenstelle/kostenstelle-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  KostenstelleService.import,
  kostenstelleImporterFields,
  i18n('entities.kostenstelle.importer.fileName'),
  i18n('entities.kostenstelle.importer.hint'),
);
