import vorlageDienstnehmerListStore from '@/modules/vorlage-dienstnehmer/vorlage-dienstnehmer-list-store';
import vorlageDienstnehmerFormStore from '@/modules/vorlage-dienstnehmer/vorlage-dienstnehmer-form-store';
import vorlageDienstnehmerDestroyStore from '@/modules/vorlage-dienstnehmer/vorlage-dienstnehmer-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: vorlageDienstnehmerDestroyStore,
    form: vorlageDienstnehmerFormStore,
    list: vorlageDienstnehmerListStore,
  },
};
