import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const KostentraegerListPage = () =>
  import('@/modules/kostentraeger/components/kostentraeger-list-page.vue');
const KostentraegerFormPage = () =>
  import('@/modules/kostentraeger/components/kostentraeger-form-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'kostentraeger',
        path: '/kostentraeger',
        component: KostentraegerListPage,
        meta: {
          auth: true,
          permission: Permissions.values.kostentraegerNavigate,
        },
      },
      {
        name: 'kostentraegerNew',
        path: '/kostentraeger/new',
        component: KostentraegerFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.kostentraegerNavigate,
        },
      },
      {
        name: 'kostentraegerEdit',
        path: '/kostentraeger/:id/edit',
        component: KostentraegerFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.kostentraegerEdit,
        },
        props: true,
      },
    ],
  },
];
