import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const VerwendungKostenstelleListPage = () =>
  import(
    '@/modules/verwendung-kostenstelle/components/verwendung-kostenstelle-list-page.vue'
  );
const VerwendungKostenstelleFormPage = () =>
  import(
    '@/modules/verwendung-kostenstelle/components/verwendung-kostenstelle-form-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'verwendungKostenstelle',
        path: '/verwendung-kostenstelle',
        component: VerwendungKostenstelleListPage,
        meta: {
          auth: true,
          permission: Permissions.values.verwendungKostenstelleNavigate,
        },
      },
      {
        name: 'verwendungKostenstelleNew',
        path: '/verwendung-kostenstelle/new',
        component: VerwendungKostenstelleFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.verwendungKostenstelleNavigate,
        },
      },
      {
        name: 'verwendungKostenstelleEdit',
        path: '/verwendung-kostenstelle/:id/edit',
        component: VerwendungKostenstelleFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.verwendungKostenstelleEdit,
        },
        props: true,
      },
    ],
  },
];
