import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const StaatsbuergerschaftListPage = () =>
  import(
    '@/modules/staatsbuergerschaft/components/staatsbuergerschaft-list-page.vue'
  );
const StaatsbuergerschaftFormPage = () =>
  import(
    '@/modules/staatsbuergerschaft/components/staatsbuergerschaft-form-page.vue'
  );
const StaatsbuergerschaftImporterPage = () =>
  import(
    '@/modules/staatsbuergerschaft/components/staatsbuergerschaft-importer-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'staatsbuergerschaft',
        path: '/staatsbuergerschaft',
        component: StaatsbuergerschaftListPage,
        meta: {
          auth: true,
          permission: Permissions.values.staatsbuergerschaftRead,
        },
      },
      {
        name: 'staatsbuergerschaftNew',
        path: '/staatsbuergerschaft/new',
        component: StaatsbuergerschaftFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.staatsbuergerschaftCreate,
        },
      },
      {
        name: 'staatsbuergerschaftImporter',
        path: '/staatsbuergerschaft/import',
        component: StaatsbuergerschaftImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.staatsbuergerschaftImport,
        },
      },
      {
        name: 'staatsbuergerschaftEdit',
        path: '/staatsbuergerschaft/:id/edit',
        component: StaatsbuergerschaftFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.staatsbuergerschaftEdit,
        },
        props: true,
      },
    ],
  },
];
