import importerStore from '@/shared/importer/importer-store';
import { HauptkostenstelleService } from '@/modules/hauptkostenstelle/hauptkostenstelle-service';
import hauptkostenstelleImporterFields from '@/modules/hauptkostenstelle/hauptkostenstelle-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  HauptkostenstelleService.import,
  hauptkostenstelleImporterFields,
  i18n('entities.hauptkostenstelle.importer.fileName'),
  i18n('entities.hauptkostenstelle.importer.hint'),
);
