import { MitarbeiterExportModel } from '@/modules/mitarbeiter/mitarbeiter-export-model';

const { fields } = MitarbeiterExportModel;

export default [
  fields.mitarbeiterNr,
  fields.nachname,
  fields.vorname,
  fields.svNummer,
  fields.betriebsumlage,
];
