import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const AuswertungListPage = () =>
  import('@/modules/auswertung/components/auswertung-list-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'auswertung',
        path: '/auswertung',
        component: AuswertungListPage,
        meta: {
          auth: true,
          permission: Permissions.values.auswertungNavigate,
        },
      },
    ],
  },
];
