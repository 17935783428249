import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class VorlageDienstnehmerPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(
      Permissions.values.vorlageDienstnehmerRead,
    );
    this.vorlageDienstnehmerAutocomplete = permissionChecker.match(
      Permissions.values.vorlageDienstnehmerAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.vorlageDienstnehmerCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.vorlageDienstnehmerEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.vorlageDienstnehmerDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.vorlageDienstnehmerNavigate,
    );
  }
}
