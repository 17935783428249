import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import EnumeratorField from '@/shared/fields/enumerator-field';
import FilesField from '@/shared/fields/files-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';
import DateField from '@/shared/fields/date-field';
import { VorlageDienstgeberField } from '@/modules/vorlage-dienstgeber/vorlage-dienstgeber-field';

function label(name) {
  return i18n(`entities.vertrag.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.vertrag.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  decisionDate: new DateTimeField('decisionDate', label('decisionDate')),
  vertragUploadDate: new DateTimeField(
    'vertragUploadDate',
    label('vertragUploadDate'),
  ),
  sentAt: new DateTimeField('sentAt', label('sentAt')),
  vertragsentwurf: new FilesField(
    'vertragsentwurf',
    label('vertragsentwurf'),
    Storage.values.vertragVertragsentwurf,
    Permissions.getFilePermissions('vertragFileFields', 'vertragsentwurf'),
    {
      size: 10485760,
      formats: ['pdf', 'doc', 'docx'],
      max: 1,
      required: true,
    },
  ),
  unterschriebenerVertrag: new FilesField(
    'unterschriebenerVertrag',
    label('unterschriebenerVertrag'),
    Storage.values.vertragUnterschriebenerVertrag,
    Permissions.getFilePermissions(
      'vertragFileFields',
      'unterschriebenerVertrag',
    ),
    {
      size: 10485760,
      formats: ['pdf'],
      required: true,
      max: 1,
    },
  ),
  entwurfStatus: new EnumeratorField(
    'entwurfStatus',
    label('entwurfStatus'),
    [
      {
        id: 'releasePending',
        label: enumeratorLabel('entwurfStatus', 'releasePending'),
      },
      {
        id: 'active',
        label: enumeratorLabel('entwurfStatus', 'active'),
      },
      {
        id: 'inactive',
        label: enumeratorLabel('entwurfStatus', 'inactive'),
      },
      {
        id: 'rejected',
        label: enumeratorLabel('entwurfStatus', 'rejected'),
      },
      {
        id: 'revisionRequested',
        label: enumeratorLabel('entwurfStatus', 'revisionRequested'),
      },
    ],
    {},
  ),
  vertragStatus: new EnumeratorField(
    'vertragStatus',
    label('vertragStatus'),
    [
      {
        id: 'active',
        label: enumeratorLabel('vertragStatus', 'active'),
      },
      {
        id: 'inactive',
        label: enumeratorLabel('vertragStatus', 'inactive'),
      },
    ],
    {},
  ),

  vorlageDienstgeber: new VorlageDienstgeberField.relationToOne(
    'vorlageDienstgeber',
    label('vorlageDienstgeber'),
    {
      required: true,
    },
  ),
  eintrittsdatum: new DateField('eintrittsdatum', label('eintrittsdatum')),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class VertragModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
