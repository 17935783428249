<template>
  <div v-if="!isBlank">
    <router-link
      :to="urlWithId"
      v-if="hasPermissionToNavigate && hasPermissionToRead"
    >
      <strong>{{ display }}</strong>
    </router-link>
    <strong
      v-if="hasPermissionToRead && !hasPermissionToNavigate"
      >{{ display }}</strong
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PermissionChecker from '@/modules/user/permission-checker';

export default {
  name: 'app-list-item-relation-to-one',

  props: [
    'value',
    'url',
    'readPermission',
    'navigatePermission',
  ],

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    hasPermissionToRead() {
      return new PermissionChecker(this.currentUser).match(
        this.readPermission,
      );
    },

    hasPermissionToNavigate() {
      return new PermissionChecker(this.currentUser).match(
        this.navigatePermission,
      );
    },

    urlWithId() {
      if (!this.value) {
        return null;
      }

      return `${this.url}/${this.value.id}`;
    },

    isBlank() {
      return !this.value || !this.value.id;
    },

    display() {
      if (!this.value) {
        return null;
      }

      return this.value.label;
    },
  },
};
</script>

<style></style>
