import importerStore from '@/shared/importer/importer-store';
import { MitarbeiterService } from '@/modules/mitarbeiter/mitarbeiter-service';
import mitarbeiterImportFields from '@/modules/mitarbeiter/mitarbeiter-importer-betriebsumlage-fields';
import { i18n } from '@/i18n';

export default importerStore(
  MitarbeiterService.updateBetriebsumlage,
  mitarbeiterImportFields,
  i18n('user.importer.fileName'),
  i18n('user.importer.hint'),
);
