import { CalendarService } from '@/modules/calendar/calendar-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    initLoading: false,
    saveLoading: false,
    data: [],
    loading: false,
    filter: {},
  },

  getters: {
    loading: (state) => state.loading,
    filter: (state) => state.filter,
    data: (state) => state.data,
    initLoading: (state) => state.initLoading,
    saveLoading: (state) => state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.initLoading = false;
      state.saveLoading = false;
      state.data = [];
      state.loading = false;
      state.filter = {};
    },

    INIT_STARTED(state) {
      state.initLoading = true;
    },

    INIT_SUCCESS(state) {
      state.initLoading = false;
    },

    INIT_ERROR(state) {
      state.initLoading = false;
    },

    FETCH_STARTED(state, payload) {
      state.loading = true;
      state.filter = payload && payload.filter ? payload.filter : {};
    },

    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.data = payload;
    },

    FETCH_ERROR(state) {
      state.loading = false;
      state.data = [];
    },

    CREATE_STARTED(state) {
      state.loading = true;
    },

    CREATE_SUCCESS(state) {
      state.loading = false;
    },

    CREATE_ERROR(state) {
      state.loading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },

    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },

    DESTROY_STARTED(state) {
      state.loading = true;
    },

    DESTROY_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    async doReset({ commit }) {
      return commit('RESET');
    },

    async doInit({ commit }, id) {
      try {
        commit('INIT_STARTED');
        let record = null;
        if (id) {
          record = await CalendarService.find(id);
        }
        commit('INIT_SUCCESS');
        return record;
      } catch (error) {
        Errors.handle(error);
        commit('INIT_ERROR');
      }
    },

    async doFetch({ commit }, { filter } = {}) {
      try {
        commit('FETCH_STARTED', { filter });
        const response = await CalendarService.list(filter);
        commit('FETCH_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doDestroy({ commit, dispatch, getters }, id) {
      try {
        await CalendarService.destroyAll([id]);
        commit('DESTROY_SUCCESS');
        Message.success(i18n('entities.calendar.destroy.success'));
        const { filter } = getters;
        dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doDestroyAll({ commit, dispatch, getters }, ids) {
      try {
        await CalendarService.destroyAll(ids);
        commit('DESTROY_ALL_SUCCESS');
        Message.success(i18n('entities.calendar.destroyAll.success'));
        const { filter } = getters;
        dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ALL_ERROR');
      }
    },

    async doCreate({ commit, getters, dispatch }, values) {
      try {
        commit('CREATE_STARTED');
        await CalendarService.create(values);
        commit('CREATE_SUCCESS');
        const { filter } = getters;
        dispatch('doFetch', { filter });
        Message.success(i18n('entities.calendar.create.success'));
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit, getters, dispatch }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        await CalendarService.update(id, values);
        commit('UPDATE_SUCCESS');
        const { filter } = getters;
        dispatch('doFetch', { filter });
        Message.success(i18n('entities.calendar.update.success'));
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
