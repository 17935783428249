import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const AenderungListPage = () =>
  import('@/modules/aenderung/components/aenderung-list-page.vue');
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'aenderung',
        path: '/aenderung',
        component: AenderungListPage,
        meta: {
          auth: true,
          permission: Permissions.values.aenderungNavigate,
        },
      },
    ],
  },
];
