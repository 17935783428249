import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateField from '@/shared/fields/date-field';
import DecimalField from '@/shared/fields/decimal-field';
import { GenericModel } from '@/shared/model/generic-model';
import { KostenstelleField } from '@/modules/kostenstelle/kostenstelle-field';
import { VerwendungKostenstelleField } from '@/modules/verwendung-kostenstelle/verwendung-kostenstelle-field';
import { KostentraegerField } from '@/modules/kostentraeger/kostentraeger-field';
import { FakultaetField } from '@/modules/fakultaet/fakultaet-field';
import MoneyField from '@/shared/fields/money-field';
import { HauptkostenstelleField } from '@/modules/hauptkostenstelle/hauptkostenstelle-field';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import BooleanField from '@/shared/fields/boolean-field';
import { EinstufungField } from '@/modules/einstufung/einstufung-field';


function label(name) {
  return i18n(`entities.grundgehalt.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.grundgehalt.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  kostenstelle: KostenstelleField.relationToOne(
    'kostenstelle',
    label('kostenstelle'),
    { required: true },
  ),
  hauptkostenstelle: HauptkostenstelleField.relationToOne(
    'hauptkostenstelle',
    label('hauptkostenstelle'),
    { required: true },
  ),
 einstufung: EinstufungField.relationToOne('einstufung', label('einstufung'), {
    required: false,
  }),
  kostentraeger: KostentraegerField.relationToOne(
    'kostentraeger',
    label('kostentraeger'),
    { required: true },
  ),
  prozent: new DecimalField('prozent', label('prozent'), {
    scale: 2,
    min: 0,
    max: 100,
    required: false,
  }),
  von: new DateField('von', label('von'), { required: true }),
  bis: new DateField('bis', label('bis')),
  wochenstunden: new MoneyField('wochenstunden', label('wochenstunden'), {
    required: true,
    min: 0,
    max: 100,
    scale: 2,
  }),
  brutto: new MoneyField('brutto', label('brutto'), {
    required: true,
    min: 0,
    max: 10000000,
    scale: 2,
  }),

  fakultaet: FakultaetField.relationToOne('fakultaet', label('fakultaet'), {
    required: true,
  }),
  verwendungKostenstelle: VerwendungKostenstelleField.relationToOne(
    'verwendungKostenstelle',
    label('verwendungKostenstelle'),
    { required: true },
  ),
  gesamtkosten: new MoneyField('gesamtkosten', label('gesamtkosten'), {
    required: false,
    min: 0,
    max: 10000000,
    scale: 2,
  }),
  anstellung: new StringField('anstellung', label('anstellung')),
  new: new BooleanField('new'),
  status: new EnumeratorField('status', label('status'), [
    {
      id: 'active',
      label: enumeratorLabel('status', 'active'),
    },
    {
      id: 'inactive',
      label: enumeratorLabel('status', 'inactive'),
    },
  ]),
  internalId: new IdField('internalId', 'internalId'),
};

export class GrundgehaltModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
