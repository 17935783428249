import { AenderungExportModel } from '@/modules/aenderung/aenderung-export-model';

const { fields } = AenderungExportModel;

export default [
  fields.nachname,
  fields.vorname,
  fields.svNummer,
  fields.eintrittsdatum,
  fields.austrittsdatum,
  fields.mandant,
  fields.kostenstelle,
  fields.hauptkostenstelle,
  fields.kostentraeger,
  fields.prozent,
  fields.von,
  fields.bis,
  fields.wochenstunden,
  fields.brutto,
  fields.zweckArt,
  fields.stand,
  fields.aenderung,
];
