<template>
  <div>
    <app-vertragsergaenzung-list-toolbar
      v-on:doOpenFormUploadModal="doOpenFormUploadModal"
      :isDisabled="isFormDisabled"
    ></app-vertragsergaenzung-list-toolbar>
    <app-vertragsergaenzung-list-table
      v-on:doOpenPreviewModal="doOpenVertragsergaenzungPreviewModal"
      v-on:doOpenRequestsModal="doOpenVertragsergaenzungRequestsModal"
      v-on:doOpenFormModal="doOpenFormModal"
      :isDisabled="isFormDisabled"
    ></app-vertragsergaenzung-list-table>
    <app-approval-request-list-table
      :visible="dialogAapprovalRequestVisible"
      v-on:close="doCloseEntwurfRequestsModal"
      :rows="entwurfRequests"
    ></app-approval-request-list-table>
    <div>
      <portal to="modal">
        <app-vertragsergaenzung-approval-modal
          :visible="dialogVertragsergaenzungApprovalVisible"
          v-on:close="doCloseVertragsergaenzungApprovalModal"
          v-on:success="doCloseVertragsergaenzungApprovalModal"
          v-on:doUpdateApprovalRequest="doUpdateApprovalRequest"
          v-if="dialogVertragsergaenzungApprovalVisible"
          :record="row"
          :approvers="approvers"
        ></app-vertragsergaenzung-approval-modal>
        <app-vertragsergaenzung-entwurf-modal
          :visible="dialogEntwurfVisible"
          v-on:close="closeEntwurfDialog"
          v-if="dialogEntwurfVisible"
          :file="entwurf"
        ></app-vertragsergaenzung-entwurf-modal>
        <app-vertragsergaenzung-form-modal
          :visible="formDialogVisible"
          :record="row"
          :grundgehalt="grundgehalt"
          :gehaltszulage="gehaltszulage"
          :mitarbeiterId="mitarbeiterId"
          v-on:close="doCloseFormModal"
          v-on:create="create"
          v-on:update="update"
          v-on:openEntwurfDialog="openEntwurfDialog"
          v-if="formDialogVisible"
          ref="formModal"
        ></app-vertragsergaenzung-form-modal>
        <app-vertragsergaenzung-form-upload-modal
          :visible="formUploadDialogVisible"
          :record="row"
          :mitarbeiterId="mitarbeiterId"
          v-on:close="doCloseFormUploadModal"
          v-on:create="createManual"
          v-if="formUploadDialogVisible"
        ></app-vertragsergaenzung-form-upload-modal>
      </portal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VertragsergaenzungApprovalModal from '@/modules/vertragsergaenzung/components/vertragsergaenzung-approval-modal.vue';
import VertragsergaenzungEntwurfModal from '@/modules/vertragsergaenzung/components/vertragsergaenzung-entwurf-modal.vue';
import VertragsergaenzungListToolbar from '@/modules/vertragsergaenzung/components/vertragsergaenzung-list-toolbar.vue';
import VertragsergaenzungFormModal from '@/modules/vertragsergaenzung/components/vertragsergaenzung-form-modal.vue';
import VertragsergaenzungFormUploadModal from '@/modules/vertragsergaenzung/components/vertragsergaenzung-form-upload-modal.vue';
import VertragsergaenzungListTable from '@/modules/vertragsergaenzung/components/vertragsergaenzung-list-table.vue';
import ApprovalRequestListTable from '@/modules/approval-request/components/approval-request-list-table.vue';
import { FileUploader } from '@/shared/file-upload/file-uploader';

export default {
  name: 'app-vertragsergaenzung-main',
  props: ['id', 'isDisabled'],
  components: {
    [VertragsergaenzungApprovalModal.name]: VertragsergaenzungApprovalModal,
    [VertragsergaenzungEntwurfModal.name]: VertragsergaenzungEntwurfModal,
    [VertragsergaenzungListToolbar.name]: VertragsergaenzungListToolbar,
    [VertragsergaenzungListTable.name]: VertragsergaenzungListTable,
    [ApprovalRequestListTable.name]: ApprovalRequestListTable,
    [VertragsergaenzungFormModal.name]: VertragsergaenzungFormModal,
    [VertragsergaenzungFormUploadModal.name]: VertragsergaenzungFormUploadModal,
  },
  data() {
    return {
      row: null,
      entwurfRequests: [],
      dialogVertragsergaenzungApprovalVisible: false,
      dialogAapprovalRequestVisible: false,
      dialogEntwurfVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      contractApprover: 'settings/contractApprover',
      mitarbeiterId: 'mitarbeiter/form/id',
      isFormDisabled: 'mitarbeiter/form/isFormDisabled',
      formDialogVisible: 'vertragsergaenzung/formDialogVisible',
      formUploadDialogVisible: 'vertragsergaenzung/formUploadDialogVisible',
      entwurf: 'vertragsergaenzung/entwurfFile',
      grundgehalt: 'mitarbeiter/form/grundgehalt',
      gehaltszulage: 'mitarbeiter/form/gehaltszulage',
    }),
    isEditing() {
      return !!this.id;
    },
    approvers() {
      return this.contractApprover.map((f) => f.id);
    },
  },
  async created() {},
  methods: {
    ...mapActions({
      loading: 'vertragsergaenzung/loading',
      saveLoading: 'vertragsergaenzung/saveLoading',
      doUpdateStatus: 'vertragsergaenzung/doUpdateStatus',
      doShowFormDialog: 'vertragsergaenzung/doShowFormDialog',
      doShowFormUploadDialog: 'vertragsergaenzung/doShowFormUploadDialog',
      doCreate: 'vertragsergaenzung/doCreate',
      doCreateManual: 'vertragsergaenzung/doCreateManual',
      doUpdate: 'vertragsergaenzung/doUpdate',
    }),

    async create(payload) {
      await this.doCreate(payload.values);
      this.doShowFormDialog();
    },
    async createManual(payload) {
      await this.doCreateManual(payload.values);
      this.doShowFormUploadDialog();
    },
    async update(payload) {
      await this.doUpdate(payload);
      this.doShowFormDialog();
    },

    onModalSuccess() {
      this.doCloseVertragsergaenzungApprovalModal();
    },
    doOpenVertragsergaenzungPreviewModal(row) {
      this.row = row;
      this.dialogVertragsergaenzungApprovalVisible = true;
    },
    doCloseVertragsergaenzungApprovalModal() {
      this.dialogVertragsergaenzungApprovalVisible = false;
      this.row = null;
    },
    doOpenVertragsergaenzungRequestsModal(rows) {
      this.entwurfRequests = rows;
      this.dialogAapprovalRequestVisible = true;
    },
    doCloseEntwurfRequestsModal() {
      this.dialogAapprovalRequestVisible = false;
    },
    doOpenFormModal(payload) {
      this.row = payload;
      this.doShowFormDialog();
    },
    doOpenFormUploadModal(payload) {
      this.row = payload;
      this.doShowFormUploadDialog();
    },
    doCloseFormModal() {
      this.doShowFormDialog();
      this.row = null;
    },
    doCloseFormUploadModal() {
      this.doShowFormUploadDialog();
      this.row = null;
    },
    openEntwurfDialog() {
      this.dialogEntwurfVisible = true;
    },
    closeEntwurfDialog() {
      this.dialogEntwurfVisible = false;
      if (this.entwurf) {
        FileUploader.removePendingFiles([this.entwurf]);
      }
    },

    async doUpdateApprovalRequest(payload) {
      this.dialogVertragsergaenzungApprovalVisible = false;
      await this.doUpdateStatus(payload);
    },
  },
};
</script>

<style></style>
