import { BestaetigungService } from '@/modules/bestaetigung/bestaetigung-service';
import { BestaetigungPermissions } from '@/modules/bestaetigung/bestaetigung-permissions';
import Errors from '@/shared/error/errors';

export default {
  namespaced: true,
  state: {
    loading: false,
    file: null,
  },
  getters: {
    loading: (state) => !!state.loading,
    file: (state) => state.file || null,
    hasPermissionToCreate: (state, getters, rootState, rootGetters) => {
      return new BestaetigungPermissions(rootGetters['auth/currentUser'])
        .create;
    },
  },
  mutations: {
    RESET(state) {
      state.loading = false;
      state.file = null;
    },
    CREATE_STARTED(state) {
      state.loading = true;
    },
    CREATE_SUCCESS(state, payload) {
      state.loading = false;
      state.file = payload;
    },
    CREATE_ERROR(state) {
      state.loading = false;
    },
  },
  actions: {
    async doReset({ commit }) {
      return commit('RESET');
    },
    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        const response = await BestaetigungService.create(values);
        commit('CREATE_SUCCESS', response);
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
  },
};
