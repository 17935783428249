import vertragsartListStore from '@/modules/vertragsart/vertragsart-list-store';
import vertragsartFormStore from '@/modules/vertragsart/vertragsart-form-store';
import vertragsartDestroyStore from '@/modules/vertragsart/vertragsart-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: vertragsartDestroyStore,
    form: vertragsartFormStore,
    list: vertragsartListStore,
  },
};
