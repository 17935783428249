import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const KostenstelleListPage = () =>
  import('@/modules/kostenstelle/components/kostenstelle-list-page.vue');
const KostenstelleFormPage = () =>
  import('@/modules/kostenstelle/components/kostenstelle-form-page.vue');
const KostenstelleImporterPage = () =>
  import('@/modules/kostenstelle/components/kostenstelle-importer-page.vue');
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'kostenstelle',
        path: '/kostenstelle',
        component: KostenstelleListPage,
        meta: {
          auth: true,
          permission: Permissions.values.kostenstelleNavigate,
        },
      },
      {
        name: 'kostenstelleNew',
        path: '/kostenstelle/new',
        component: KostenstelleFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.kostenstelleNavigate,
        },
      },
      {
        name: 'kostenstelleEdit',
        path: '/kostenstelle/:id/edit',
        component: KostenstelleFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.kostenstelleEdit,
        },
        props: true,
      },
      {
        name: 'kostenstelleImporter',
        path: '/kostenstelle/import',
        component: KostenstelleImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.kostenstelleImport,
        },
      },
    ],
  },
];
