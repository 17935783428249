import authAxios from '@/shared/axios/auth-axios';

export class VertragsergaenzungService {
  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/vertragsergaenzung`, body);

    return response.data;
  }
  static async createDraft(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.post(`/vertragsergaenzung/entwurf`, body);

    return response.data;
  }
  static async createManual(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/vertragsergaenzung/manual`, body);

    return response.data;
  }
  static async list(id, orderBy, limit, offset) {
    const params = {
      id,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/vertragsergaenzung`, {
      params,
    });

    return response.data;
  }
  static async listPending() {
    const response = await authAxios.get(`/vertragsergaenzung/pending`, {});

    return response.data;
  }
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/vertragsergaenzung/${id}`, body);

    return response.data;
  }
  static async updateStatus(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/vertragsergaenzung/entwurf-status/${id}`,
      body,
    );

    return response.data;
  }
  static async setInactive(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(
      `/vertragsergaenzung/set-inactive/${id}`,
      body,
    );
    return response.data;
  }
  static async sendEmailToMitarbeiter(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/vertragsergaenzung/send-email/${id}`,
      body,
    );

    return response.data;
  }
  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/vertragsergaenzung`, {
      params,
    });

    return response.data;
  }
}
