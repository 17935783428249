import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const VorlageDienstnehmerListPage = () =>
  import(
    '@/modules/vorlage-dienstnehmer/components/vorlage-dienstnehmer-list-page.vue'
  );
const VorlageDienstnehmerFormPage = () =>
  import(
    '@/modules/vorlage-dienstnehmer/components/vorlage-dienstnehmer-form-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'vorlageDienstnehmer',
        path: '/vorlage-dienstnehmer',
        component: VorlageDienstnehmerListPage,
        meta: {
          auth: true,
          permission: Permissions.values.vorlageDienstnehmerNavigate,
        },
      },
      {
        name: 'vorlageDienstnehmerNew',
        path: '/vorlage-dienstnehmer/new',
        component: VorlageDienstnehmerFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.vorlageDienstnehmerCreate,
        },
      },
      {
        name: 'vorlageDienstnehmerEdit',
        path: '/vorlage-dienstnehmer/:id/edit',
        component: VorlageDienstnehmerFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.vorlageDienstnehmerEdit,
        },
        props: true,
      },
    ],
  },
];
