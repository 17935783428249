<template>
  <div>
    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      :model="model"
      :rules="rules"
      @submit.native.prevent="doSubmit"
      class="form"
      ref="form"
      v-if="model"
    >
      <el-form-item
        :label="fields.langtext.label"
        :prop="fields.langtext.name"
        :required="fields.langtext.required"
      >
        <el-input v-model="model[fields.langtext.name]" ref="focus" />
      </el-form-item>

      <el-form-item
        :label="fields.nationscode.label"
        :prop="fields.nationscode.name"
        :required="fields.nationscode.required"
      >
        <el-input v-model="model[fields.nationscode.name]" ref="focus" />
      </el-form-item>

      <el-form-item>
        <div class="form-buttons">
          <el-button
            :disabled="saveLoading"
            @click="doSubmit"
            icon="el-icon-fa-floppy-o"
            type="primary"
          >
            <app-i18n code="common.save"></app-i18n>
          </el-button>

          <el-button
            :disabled="saveLoading"
            @click="doCancel"
            icon="el-icon-fa-close"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </el-button>
        </div>
      </el-form-item> </el-form
    >s
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { StaatsbuergerschaftModel } from '@/modules/staatsbuergerschaft/staatsbuergerschaft-model';

const { fields } = StaatsbuergerschaftModel;
const formSchema = new FormSchema([
  fields.id,
  fields.langtext,
  fields.nationscode,
]);

export default {
  name: 'app-staatsbuergerschaft-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel');
    },

    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      return this.$emit('submit', {
        id: this.record && this.record.id,
        values: formSchema.cast(this.model),
      });
    },
  },
};
</script>

<style></style>
