import { UserService } from '@/modules/user/user-service';
import userListExporterFields from '@/modules/user/user-list-exporter-fields';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';
import Exporter from '@/shared/exporter/exporter';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    filter: {},
    pagination: {},
    sorter: {},
    selectedRows: [],
    table: null,
    dataFetched: false,
  },

  getters: {
    loading: (state) => state.loading,
    exportLoading: (state) => state.exportLoading,
    rows: (state) => state.rows || [],
    count: (state) => state.count,
    dataFetched: (state) => state.dataFetched,
    hasRows: (state, getters) => getters.count > 0,
    orderBy: (state) => {
      const sorter = state.sorter;
      if (!sorter) {
        return null;
      }
      if (!sorter.prop) {
        return null;
      }
      let direction = sorter.order === 'descending' ? 'DESC' : 'ASC';

      return `${sorter.prop}_${direction}`;
    },
    filter: (state) => state.filter,
    limit: (state) => {
      const pagination = state.pagination;
      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }
      return pagination.pageSize;
    },
    offset: (state) => {
      const pagination = state.pagination;
      if (!pagination || !pagination.pageSize) {
        return 0;
      }
      const currentPage = pagination.currentPage || 1;
      return (currentPage - 1) * pagination.pageSize;
    },
    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },
    selectedRows: (state) => {
      return state.selectedRows || [];
    },
  },
  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.loading = false;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
      state.dataFetched = false;
    },
    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },
    SELECTED_ROWS_SET(state, payload) {
      state.selectedRows = payload;
    },
    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },
    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};
      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },
    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};
      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },
    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },
    FETCH_STARTED(state, payload) {
      state.loading = true;
      if (state.table) {
        state.table.clearSelection();
      }
      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination ? state.pagination : {};
      state.dataFetched = false;
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
      state.dataFetched = true;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },
    EXPORT_STARTED(state) {
      state.exportLoading = true;
    },
    EXPORT_SUCCESS(state) {
      state.exportLoading = false;
    },
    EXPORT_ERROR(state) {
      state.exportLoading = false;
    },
    REMOVE_ALL_SELECTED_STARTED(state) {
      state.loading = true;
    },
    REMOVE_ALL_SELECTED_ERROR(state) {
      state.loading = false;
    },
    REMOVE_ALL_SELECTED_SUCCESS(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },
    CHANGE_STATUS_SELECTED_STARTED(state) {
      state.loading = true;
    },
    CHANGE_STATUS_SELECTED_ERROR(state) {
      state.loading = false;
    },
    CHANGE_STATUS_SELECTED_SUCCESS(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },
    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },
    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },
    DESTROY_STARTED(state) {
      state.loading = true;
    },
    DESTROY_SUCCESS(state) {
      state.loading = false;
    },
    DESTROY_ERROR(state) {
      state.loading = false;
    },
    PASSWORD_CHANGE_START(state) {
      state.loading = true;
    },
    PASSWORD_CHANGE_SUCCESS(state) {
      state.loading = false;
    },
    PASSWORD_CHANGE_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },
    doSetSelectedRows({ commit }, rows) {
      commit('SELECTED_ROWS_SET', rows);
    },
    async doReset({ commit, dispatch }) {
      commit('RESETED');
      return dispatch('doFetch');
    },
    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    async doFetch({ commit, getters }, { filter, keepPagination } = {}) {
      try {
        commit('FETCH_STARTED', { filter, keepPagination });

        const response = await UserService.fetchUsers(
          filter,
          getters.orderBy,
          getters.limit,
          getters.offset,
        );

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },

    async doDisableAllSelected({ getters, commit, dispatch }) {
      try {
        const selectedRows = getters.selectedRows;

        commit('CHANGE_STATUS_SELECTED_STARTED');

        await UserService.disable(selectedRows.map((user) => user.id));

        commit('CHANGE_STATUS_SELECTED_SUCCESS');

        Message.success(i18n('user.doDisableAllSuccess'));

        const filter = getters.filter;
        dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);

        commit('CHANGE_STATUS_SELECTED_ERROR');

        const filter = getters.filter;
        dispatch('doFetch', { filter });
      }
    },

    async doEnableAllSelected({ commit, getters, dispatch }) {
      try {
        const selectedRows = getters.selectedRows;

        commit('CHANGE_STATUS_SELECTED_STARTED');

        await UserService.enable(selectedRows.map((user) => user.id));

        commit('CHANGE_STATUS_SELECTED_SUCCESS');

        Message.success(i18n('user.doEnableAllSuccess'));

        const filter = getters.filter;
        dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);

        commit('CHANGE_STATUS_SELECTED_ERROR');

        const filter = getters.filter;
        dispatch('doFetch', { filter });
      }
    },

    async doDestroy({ commit, dispatch, getters }, id) {
      try {
        commit('DESTROY_STARTED');
        await UserService.destroy([id]);
        commit('DESTROY_SUCCESS');
        Message.success(i18n('user.doDestroySuccess'));

        const filter = getters.filter;
        dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doDestroyAll({ commit, dispatch, getters }, ids) {
      try {
        commit('DESTROY_ALL_STARTED');

        await UserService.destroy(ids);

        commit('DESTROY_ALL_SUCCESS');

        dispatch(`${'user/list'}/doUnselectAll`, null, {
          root: true,
        });

        Message.success(i18n('user.doDestroyAllSuccess'));

        const filter = getters.filter;
        dispatch('doFetch', { filter });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ALL_ERROR');
      }
    },

    async doChangePassword({ commit }, values) {
      try {
        commit('PASSWORD_CHANGE_START');
        await UserService.changePassword(values);
        commit('PASSWORD_CHANGE_SUCCESS');
        Message.success(i18n('auth.passwordChange.success'));
      } catch (error) {
        Errors.handle(error);
        commit('PASSWORD_CHANGE_ERROR');
      }
    },

    async doExport({ commit, getters }) {
      try {
        if (!userListExporterFields || !userListExporterFields.length) {
          throw new Error('userListExporterFields is required');
        }
        commit('EXPORT_STARTED');
        const filter = getters.filter;
        const response = await UserService.fetchUsers(
          filter,
          getters.orderBy,
          null,
          null,
        );

        new Exporter(
          userListExporterFields,
          i18n('user.users.exporterFileName'),
        ).transformAndExportAsExcelFile(response.rows);

        commit('EXPORT_SUCCESS');
      } catch (error) {
        Errors.handle(error);

        commit('EXPORT_ERROR');
      }
    },
  },
};
