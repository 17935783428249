import { VertragService } from '@/modules/vertrag/vertrag-service';
import { VertragPermissions } from '@/modules/vertrag/vertrag-permissions';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

const INITIAL_PAGE_SIZE = 10;

export default {
  namespaced: true,

  state: {
    rows: [],
    count: 0,
    loading: false,
    saveLoading: false,
    formDialogVisible: false,
    formUploadDialogVisible: false,
    manualUploadMode: false,
    entwurfFile: null,
    filter: {},
    pagination: {},
    sorter: {},
    selectedRows: [],
    table: null,
  },

  getters: {
    loading: (state) => !!state.loading,
    saveLoading: (state) => !!state.saveLoading,
    formDialogVisible: (state) => !!state.formDialogVisible,
    formUploadDialogVisible: (state) => !!state.formUploadDialogVisible,
    manualUploadMode: (state) => !!state.manualUploadMode,
    rows: (state) => state.rows || [],
    count: (state) => state.count,
    hasRows: (state, getters) => getters.count > 0,
    orderBy: (state) => {
      const sorter = state.sorter;
      if (!sorter) {
        return null;
      }

      if (!sorter.prop) {
        return null;
      }

      let direction = sorter.order === 'descending' ? 'DESC' : 'ASC';
      return `${sorter.prop}_${direction}`;
    },
    filter: (state) => state.filter,
    limit: (state) => {
      const pagination = state.pagination;
      if (!pagination || !pagination.pageSize) {
        return INITIAL_PAGE_SIZE;
      }
      return pagination.pageSize;
    },
    offset: (state) => {
      const pagination = state.pagination;
      if (!pagination || !pagination.pageSize) {
        return 0;
      }
      const currentPage = pagination.currentPage || 1;
      return (currentPage - 1) * pagination.pageSize;
    },
    pagination: (state, getters) => {
      return {
        ...state.pagination,
        total: getters.count,
        showSizeChanger: true,
      };
    },
    selectedRows: (state) => {
      return state.selectedRows || [];
    },
    entwurfFile: (state) => {
      return state.entwurfFile;
    },
    hasPermissionToCreate: (state, getters, rootState, rootGetters) => {
      return new VertragPermissions(rootGetters['auth/currentUser']).create;
    },
  },

  mutations: {
    RESETED(state) {
      state.rows = [];
      state.count = 0;
      state.saveLoading = false;
      state.loading = false;
      state.formDialogVisible = false;
      state.formUploadDialogVisible = false;
      state.manualUploadMode = false;
      state.entwurfFile = null;
      state.filter = {};
      state.pagination = {};
      state.sorter = {};
      if (state.table) {
        state.table.clearSelection();
      }
    },
    UNSELECT_ALL(state) {
      if (state.table) {
        state.table.clearSelection();
      }
    },
    SELECTED_ROWS_SET(state, payload) {
      state.selectedRows = payload;
    },
    PAGINATION_CHANGED(state, payload) {
      state.pagination = payload || {};
    },
    PAGINATION_CURRENT_PAGE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: payload || 1,
        pageSize: previousPagination.pageSize || INITIAL_PAGE_SIZE,
      };
    },
    PAGINATION_PAGE_SIZE_CHANGED(state, payload) {
      const previousPagination = state.pagination || {};

      state.pagination = {
        currentPage: previousPagination.currentPage || 1,
        pageSize: payload || INITIAL_PAGE_SIZE,
      };
    },
    SORTER_CHANGED(state, payload) {
      state.sorter = payload || {};
    },
    FETCH_STARTED(state, payload) {
      state.loading = true;

      if (state.table) {
        state.table.clearSelection();
      }

      state.filter = payload && payload.filter ? payload.filter : {};
      state.pagination =
        payload && payload.keepPagination ? state.pagination : {};
    },
    FETCH_SUCCESS(state, payload) {
      state.loading = false;
      state.rows = payload.rows;
      state.count = payload.count;
    },
    FETCH_ERROR(state) {
      state.loading = false;
      state.rows = [];
      state.count = 0;
    },
    CREATE_STARTED(state) {
      state.saveLoading = true;
    },
    CREATE_SUCCESS(state) {
      state.saveLoading = false;
      state.entwurfFile = null;
    },
    CREATE_ERROR(state) {
      state.saveLoading = false;
    },
    UPDATE_STARTED(state) {
      state.saveLoading = true;
      state.isSaved = false;
    },
    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
      state.formModel = null;
      state.isSaved = true;
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },
    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },
    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },
    DESTROY_STARTED(state) {
      state.loading = true;
    },
    DESTROY_SUCCESS(state) {
      state.loading = false;
    },
    DESTROY_ERROR(state) {
      state.loading = false;
    },
    CREATE_ENTWURF_STARTED(state) {
      state.entwurfFile = null;
      state.saveLoading = true;
    },
    CREATE_ENTWURF_SUCCESS(state, payload) {
      state.entwurfFile = payload;
      state.saveLoading = false;
    },
    CREATE_ENTWURF_ERROR(state) {
      state.saveLoading = false;
    },
    CLEAR_ENTWURF(state) {
      state.entwurfFile = null;
    },
    SHOW_FORM_DIALOG(state) {
      state.formDialogVisible = !state.formDialogVisible;
    },
    SHOW_FORM_UPLOAD_DIALOG(state) {
      state.formUploadDialogVisible = !state.formUploadDialogVisible;
    },
    SET_UPLOAD_MODE(state, payload) {
      state.manualUploadMode = payload;
    },
  },

  actions: {
    doShowFormDialog({ commit }) {
      commit('SHOW_FORM_DIALOG');
    },
    doShowFormUploadDialog({ commit }) {
      commit('SHOW_FORM_UPLOAD_DIALOG');
    },
    doClearEntwurfFile({ commit }) {
      commit('CLEAR_ENTWURF');
    },
    doSetUploadMode({ commit }, value) {
      commit('SET_UPLOAD_MODE', value);
    },
    doUnselectAll({ commit }) {
      commit('UNSELECT_ALL');
    },
    doSetSelectedRows({ commit }, rows) {
      commit('SELECTED_ROWS_SET', rows);
    },
    async doReset({ commit }) {
      return commit('RESETED');
    },
    doChangePagination({ commit, getters, dispatch }, pagination) {
      commit('PAGINATION_CHANGED', pagination);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    doChangePaginationPageSize({ commit, getters, dispatch }, pageSize) {
      commit('PAGINATION_PAGE_SIZE_CHANGED', pageSize);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    doChangePaginationCurrentPage({ commit, getters, dispatch }, currentPage) {
      commit('PAGINATION_CURRENT_PAGE_CHANGED', currentPage);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },
    doChangeSort({ commit, getters, dispatch }, sorter) {
      commit('SORTER_CHANGED', sorter);
      const filter = getters.filter;
      dispatch('doFetch', { filter, keepPagination: true });
    },

    async doFetch({ commit, getters }, { filter, keepPagination } = {}) {
      try {
        commit('FETCH_STARTED', { filter, keepPagination });

        const response = await VertragService.list(
          filter.id,
          getters.orderBy,
          getters.limit,
          getters.offset,
        );

        commit('FETCH_SUCCESS', {
          rows: response.rows,
          count: response.count,
        });
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_ERROR');
      }
    },
    async doSendEmailToMitarbeiter(
      { dispatch, getters, state },
      { id, values },
    ) {
      try {
        state.loading = true;
        await VertragService.sendEmailToMitarbeiter(id, values);
        const { filter } = getters;
        dispatch('doFetch', { filter, keepPagination: true });
        Message.success(i18n('entities.vertrag.sendEmail.success'));
      } catch (error) {
        Errors.handle(error);
      }
    },
    async doDestroy({ commit, dispatch, getters }, id) {
      try {
        commit('DESTROY_STARTED');
        await VertragService.destroyAll([id]);
        commit('DESTROY_SUCCESS');
        Message.success(i18n('entities.vertrag.destroy.success'));
        const { filter } = getters;
        dispatch('doFetch', { filter, keepPagination: true });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },
    async doDestroyAll({ commit, dispatch, getters }, ids) {
      try {
        commit('DESTROY_ALL_STARTED');
        await VertragService.destroyAll(ids);
        commit('DESTROY_ALL_SUCCESS');
        dispatch('doUnselectAll', null, {
          root: true,
        });
        Message.success(i18n('entities.vertrag.destroyAll.success'));
        const { filter } = getters;
        dispatch('doFetch', { filter, keepPagination: true });
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ALL_ERROR');
      }
    },
    async doCreate({ commit, getters, dispatch }, values) {
      try {
        commit('CREATE_STARTED');
        await VertragService.create(values);
        commit('CREATE_SUCCESS');
        const { filter } = getters;
        dispatch('doFetch', { filter, keepPagination: true });
        dispatch('doSetUploadMode', false, {
          root: true,
        });
        Message.success(i18n('entities.vertrag.vertragsentwurf.success'));
      } catch (error) {
        dispatch('doShowFormDialog');
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doCreateManual({ commit, getters, dispatch }, values) {
      try {
        commit('CREATE_STARTED');
        await VertragService.createManual(values);
        commit('CREATE_SUCCESS');
        const { filter } = getters;
        dispatch('doFetch', { filter, keepPagination: true });
        Message.success(i18n('entities.vertrag.create.success'));
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
    async doUpdate({ commit, getters, dispatch }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        await VertragService.update(id, values);
        commit('UPDATE_SUCCESS');
        const { filter } = getters;
        dispatch('doFetch', { filter, keepPagination: true });
        dispatch('doSetUploadMode', false, {
          root: true,
        });
        Message.success(i18n('entities.vertrag.update.success'));
      } catch (error) {
        dispatch('doShowFormDialog');
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
    async doUpdateStatus({ commit, dispatch, getters }, { id, data }) {
      try {
        commit('UPDATE_STARTED');
        dispatch('mitarbeiter/form/doSetSpinner', true, {
          root: true,
        });

        await VertragService.updateStatus(id, data);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.vertrag.update.success'));
        const { filter } = getters;
        dispatch('doFetch', { filter, keepPagination: true });
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      } finally {
        dispatch('mitarbeiter/form/doSetSpinner', false, {
          root: true,
        });
      }
    },

    async doSetToInactive({ commit, dispatch, getters }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        dispatch('mitarbeiter/form/doSetSpinner', true, {
          root: true,
        });

        await VertragService.setInactive(id, values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.vertrag.update.success'));
        const { filter } = getters;
        dispatch('doFetch', { filter, keepPagination: true });
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      } finally {
        dispatch('mitarbeiter/form/doSetSpinner', false, {
          root: true,
        });
      }
    },

    async doSetToActive({ commit, dispatch, getters }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        dispatch('mitarbeiter/form/doSetSpinner', true, {
          root: true,
        });

        await VertragService.setActive(id, values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.vertrag.update.success'));
        const { filter } = getters;
        dispatch('doFetch', { filter, keepPagination: true });
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      } finally {
        dispatch('mitarbeiter/form/doSetSpinner', false, {
          root: true,
        });
      }
    },
    async doCreateDraft({ commit, dispatch }, { id, values }) {
      try {
        commit('CREATE_ENTWURF_STARTED');
        const payload = await VertragService.createDraft(id, values);
        commit('CREATE_ENTWURF_SUCCESS', payload);
      } catch (error) {
        Errors.handle(error);
        dispatch('doShowFormDialog');
        commit('CREATE_ENTWURF_ERROR');
      }
    },
  },
};
