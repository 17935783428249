import { JahreskarteExportModel } from '@/modules/jahreskarte/jahreskarte-export-model';

const { fields } = JahreskarteExportModel;

export default [
  fields.mitarbeiterNr,
  fields.anrede,
  fields.titel,
  fields.nachname,
  fields.vorname,
  fields.vollNameExport,
  fields.svNummer,
  fields.nummer,
  fields.zahlung,
  fields.von,
  fields.bis,
];
