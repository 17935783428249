import { i18n } from '@/i18n';
import _values from 'lodash/values';


//TODO
//Make sure that roles on the frontend adn backend are same


class Roles {
  static get values() {
    return {   
      itAdmin: 'itAdmin',
      personalAdmin: 'personalAdmin',
      personal: 'personal',
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }

  static get selectOptions() {
    return _values(this.values).map((value) => ({
      id: value,
      value: value,
      title: this.descriptionOf(value),
      label: this.labelOf(value),
    }));
  }
}

export default Roles;
