<template>
  <div>
    <app-beendigung-list-toolbar
      v-on:doOpenFormUploadModal="doOpenFormUploadModal"
      :isDisabled="isFormDisabled"
    ></app-beendigung-list-toolbar>
    <app-beendigung-list-table
      v-on:doOpenPreviewModal="doOpenBeendigungPreviewModal"
      v-on:doOpenRequestsModal="doOpenBeendigungRequestsModal"
      v-on:doOpenFormModal="doOpenFormModal"
      :isDisabled="isFormDisabled"
    ></app-beendigung-list-table>
    <app-approval-request-list-table
      :visible="dialogBeendigungsentwurfRequestsVisible"
      v-on:close="doCloseEntwurfRequestsModal"
      :rows="releaseRquests"
    ></app-approval-request-list-table>
    <div>
      <portal to="modal">
        <app-beendigung-approval-modal
          :visible="dialogBeendigungApprovalVisible"
          v-on:close="doCloseBeendigungApprovalModal"
          v-on:success="doCloseBeendigungApprovalModal"
          v-on:doUpdateBeendigungRequest="doUpdateBeendigungRequest"
          v-if="dialogBeendigungApprovalVisible"
          :record="row"
          :approvers="approvers"
        ></app-beendigung-approval-modal>
        <app-beendigung-entwurf-modal
          :visible="dialogEntwurfVisible"
          v-on:close="closeEntwurfDialog"
          v-if="dialogEntwurfVisible"
          :file="entwurf"
        ></app-beendigung-entwurf-modal>
        <app-beendigung-form-modal
          :visible="formDialogVisible"
          :record="row"
          :mitarbeiterId="mitarbeiterId"
          v-on:close="doCloseFormModal"
          v-on:create="create"
          v-on:update="update"
          v-on:openEntwurfDialog="openEntwurfDialog"
          v-if="formDialogVisible"
          ref="formModal"
        ></app-beendigung-form-modal>
        <app-beendigung-form-upload-modal
          :visible="formUploadDialogVisible"
          :record="row"
          :mitarbeiterId="mitarbeiterId"
          v-on:close="doCloseFormUploadModal"
          v-on:create="createManual"
          v-if="formUploadDialogVisible"
        ></app-beendigung-form-upload-modal>
      </portal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BeendigungApprovalModal from '@/modules/beendigung/components/beendigung-approval-modal.vue';
import BeendigungEntwurfModal from '@/modules/beendigung/components/beendigung-entwurf-modal.vue';
import BeendigungListToolbar from '@/modules/beendigung/components/beendigung-list-toolbar.vue';
import BeendigungFormModal from '@/modules/beendigung/components/beendigung-form-modal.vue';
import BeendigungFormUploadModal from '@/modules/beendigung/components/beendigung-form-upload-modal.vue';
import BeendigungListTable from '@/modules/beendigung/components/beendigung-list-table.vue';
import ApprovalRequestListTable from '@/modules/approval-request/components/approval-request-list-table.vue';
import { FileUploader } from '@/shared/file-upload/file-uploader';

export default {
  name: 'app-beendigung-main',
  props: ['id'],
  components: {
    [BeendigungApprovalModal.name]: BeendigungApprovalModal,
    [BeendigungEntwurfModal.name]: BeendigungEntwurfModal,
    [BeendigungListToolbar.name]: BeendigungListToolbar,
    [BeendigungListTable.name]: BeendigungListTable,
    [ApprovalRequestListTable.name]: ApprovalRequestListTable,
    [BeendigungFormModal.name]: BeendigungFormModal,
    [BeendigungFormUploadModal.name]: BeendigungFormUploadModal,
  },
  data() {
    return {
      row: null,
      releaseRquests: [],
      dialogBeendigungApprovalVisible: false,
      dialogBeendigungsentwurfRequestsVisible: false,
      dialogBeendigungFormVisible: false,
      dialogEntwurfVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      beendigungApprover: 'settings/beendigungApprover',
      mitarbeiterId: 'mitarbeiter/form/id',
      isFormDisabled: 'mitarbeiter/form/isFormDisabled',
      formDialogVisible: 'beendigung/formDialogVisible',
      formUploadDialogVisible: 'beendigung/formUploadDialogVisible',
      entwurf: 'beendigung/entwurfFile',
    }),
    isEditing() {
      return !!this.id;
    },
    approvers() {
      return this.beendigungApprover.map((f) => f.id);
    },
  },
  async created() {},
  methods: {
    ...mapActions({
      loading: 'beendigung/loading',
      saveLoading: 'beendigung/saveLoading',
      doUpdateStatus: 'beendigung/doUpdateStatus',
      doShowFormDialog: 'beendigung/doShowFormDialog',
      doShowFormUploadDialog: 'beendigung/doShowFormUploadDialog',
      doCreate: 'beendigung/doCreate',
      doCreateManual: 'beendigung/doCreateManual',
      doUpdate: 'beendigung/doUpdate',
    }),

    async create(payload) {
      await this.doCreate(payload.values);
      this.doShowFormDialog();
    },
    async createManual(payload) {
      await this.doCreateManual(payload.values);
      this.doShowFormUploadDialog();
    },
    async update(payload) {
      await this.doUpdate(payload);
      this.doShowFormDialog();
    },
    onModalSuccess() {
      this.doCloseBeendigungApprovalModal();
    },
    doOpenBeendigungPreviewModal(row) {
      this.row = row;
      this.dialogBeendigungApprovalVisible = true;
    },
    doCloseBeendigungApprovalModal() {
      this.dialogBeendigungApprovalVisible = false;
      this.row = null;
    },
    doOpenBeendigungRequestsModal(rows) {
      this.releaseRquests = rows;
      this.dialogBeendigungsentwurfRequestsVisible = true;
    },
    doCloseEntwurfRequestsModal() {
      this.dialogBeendigungsentwurfRequestsVisible = false;
    },
    doOpenFormModal(payload) {
      this.row = payload;
      this.doShowFormDialog();
    },
    doOpenFormUploadModal(payload) {
      this.row = payload;
      this.doShowFormUploadDialog();
    },
    doCloseFormModal() {
      this.doShowFormDialog();
      this.row = null;
    },
    doCloseFormUploadModal() {
      this.doShowFormUploadDialog();
      this.row = null;
    },
    openEntwurfDialog() {
      this.dialogEntwurfVisible = true;
    },
    closeEntwurfDialog() {
      this.dialogEntwurfVisible = false;
      if (this.entwurf) {
        FileUploader.removePendingFiles([this.entwurf]);
      }
    },

    async doUpdateBeendigungRequest(payload) {
      this.dialogBeendigungApprovalVisible = false;
      await this.doUpdateStatus(payload);
    },
  },
};
</script>

<style></style>
