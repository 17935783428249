import { UserModel } from '@/modules/user/user-model';

const { fields } = UserModel;

export default [
  fields.id,
  fields.email,
  fields.anrede,
  fields.academicTitle,
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.roles,
];
