import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class HauptkostenstellePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(
      Permissions.values.hauptkostenstelleRead,
    );
    this.hauptkostenstelleAutocomplete = permissionChecker.match(
      Permissions.values.hauptkostenstelleAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.hauptkostenstelleCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.hauptkostenstelleEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.hauptkostenstelleDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.hauptkostenstelleNavigate,
    );
    this.import = permissionChecker.match(
      Permissions.values.hauptkostenstelleImport,
    );
  }
}
