import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class VertragsaenderungPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(
      Permissions.values.vertragsaenderungRead,
    );
    this.autocomplete = permissionChecker.match(
      Permissions.values.vertragsaenderungAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.vertragsaenderungCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.vertragsaenderungEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.vertragsaenderungDestroy,
    );
    this.setToInactive = permissionChecker.match(
      Permissions.values.vertragsaenderungSetToInactive,
    );
    this.sendEmailToEmployee = permissionChecker.match(
      Permissions.values.vertragsaenderungSendEmailToEmployee,
    );
    this.sendEmailToEmployeeMultipleTimes = permissionChecker.match(
      Permissions.values.vertragsaenderungSendEmailToEmployeeMultipleTimes,
    );
  }
}
