import { JahreskarteService } from '@/modules/jahreskarte/jahreskarte-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doCreate({ commit, dispatch, rootGetters }, values) {
      try {
        commit('CREATE_STARTED');
        await JahreskarteService.create(values);

        dispatch(
          'jahreskarte/list/doFetch',
          { filter: rootGetters['jahreskarte/list/filter'] },
          {
            root: true,
          },
        );

        commit('CREATE_SUCCESS');
        Message.success(i18n('entities.jahreskarte.create.success'));
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit, dispatch, rootGetters }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        await JahreskarteService.update(id, values);

        dispatch(
          'jahreskarte/list/doFetch',
          { filter: rootGetters['jahreskarte/list/filter'] },
          {
            root: true,
          },
        );

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.jahreskarte.update.success'));
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
