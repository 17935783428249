import { i18n } from '@/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import DecimalField from '@/shared/fields/decimal-field';
import MoneyField from '@/shared/fields/money-field';

function label(name) {
  return i18n(`entities.auswertung.fields.${name}`);
}

const fields = {
  mitarbeiterNr: new StringField('mitarbeiterNr', label('mitarbeiterNr'), {
    required: false,
    max: 50,
  }),
  vollname: new StringField('vollname', label('vollname')),
  titel: new StringField('titel', label('titel')),
  beruf: new StringField('beruf', label('beruf')),
  funktion: new StringField('funktion', label('funktion')),
  fakultaet: new StringField('fakultaet', label('fakultaet')),
  mandant: new StringField('mandant', label('mandant'), {}),
  kostenstelle: new StringField('kostenstelle', label('kostenstelle'), {}),
  hauptkostenstelle: new StringField(
    'hauptkostenstelle',
    label('hauptkostenstelle'),
    {},
  ),
  kostentraeger: new StringField('kostentraeger', label('kostentraeger'), {}),
  verwendungKostenstelle: new StringField(
    'verwendungKostenstelle',
    label('verwendungKostenstelle'),
    {},
  ),
      einstufung: new StringField('einstufung', label('einstufung'), {}),
  gesamtwochenstunden: new DecimalField(
    'gesamtwochenstunden',
    label('gesamtwochenstunden'),
    {
      scale: 2,
      min: 0,
      max: 1000,
      required: false,
    },
  ),
  gesamtbrutto: new MoneyField('gesamtbrutto', label('gesamtbrutto'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  gesamtkosten: new MoneyField('gesamtkosten', label('gesamtkosten'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
};
export class AuswertungExportModel extends GenericModel {
  static get fields() {
    return fields;
  }
  static set addFields(months) {
    months.forEach((month) => {
      fields[`${month}`] = new StringField(`${month}`);
    });
  }
}
