import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import EnumeratorField from '@/shared/fields/enumerator-field';
import FilesField from '@/shared/fields/files-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';
import DateField from '@/shared/fields/date-field';
import { VorlageDienstgeberField } from '@/modules/vorlage-dienstgeber/vorlage-dienstgeber-field';
import { KostenstelleField } from '@/modules/kostenstelle/kostenstelle-field';
import { VertragField } from '@/modules/vertrag/vertrag-field';
import MoneyField from '@/shared/fields/money-field';
import BooleanField from '@/shared/fields/boolean-field';

function label(name) {
  return i18n(`entities.vertragsaenderung.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.vertragsaenderung.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  decisionDate: new DateTimeField('decisionDate', label('decisionDate')),
  vertragsaenderungUploadDate: new DateTimeField(
    'vertragsaenderungUploadDate',
    label('vertragsaenderungUploadDate'),
  ),
  sentAt: new DateTimeField('sentAt', label('sentAt')),
  entwurf: new FilesField(
    'entwurf',
    label('entwurf'),
    Storage.values.vertragsaenderungEntwurf,
    Permissions.getFilePermissions('vertragsaenderungFileFields', 'entwurf'),
    {
      size: 10485760,
      formats: ['pdf', 'doc', 'docx', 'xlsx', 'xltx', 'xltm'],
      max: 1,
      required: true,
    },
  ),
  unterschriebenerVertragsaenderung: new FilesField(
    'unterschriebenerVertragsaenderung',
    label('unterschriebenerVertragsaenderung'),
    Storage.values.vertragsaenderungUnterschriebenerVertrag,
    Permissions.getFilePermissions(
      'vertragsaenderungFileFields',
      'unterschriebenerVertragsaenderung',
    ),
    {
      size: 10485760,
      formats: ['pdf', 'doc', 'docx', 'xlsx', 'xltx', 'xltm'],
      required: true,
      max: 1,
    },
  ),
  entwurfStatus: new EnumeratorField(
    'entwurfStatus',
    label('entwurfStatus'),
    [
      {
        id: 'releasePending',
        label: enumeratorLabel('entwurfStatus', 'releasePending'),
      },
      {
        id: 'active',
        label: enumeratorLabel('entwurfStatus', 'active'),
      },
      {
        id: 'inactive',
        label: enumeratorLabel('entwurfStatus', 'inactive'),
      },
      {
        id: 'rejected',
        label: enumeratorLabel('entwurfStatus', 'rejected'),
      },
      {
        id: 'revisionRequested',
        label: enumeratorLabel('entwurfStatus', 'revisionRequested'),
      },
    ],
    {},
  ),
  vertragsaenderungStatus: new EnumeratorField(
    'vertragsaenderungStatus',
    label('vertragsaenderungStatus'),
    [
      {
        id: 'active',
        label: enumeratorLabel('vertragsaenderungStatus', 'active'),
      },
      {
        id: 'inactive',
        label: enumeratorLabel('vertragsaenderungStatus', 'inactive'),
      },
    ],
    {},
  ),
  vertrag: VertragField.relationToOne('vertrag', label('vertrag'), {
    required: false,
  }),
  vorlageDienstgeber: new VorlageDienstgeberField.relationToOne(
    'vorlageDienstgeber',
    label('vorlageDienstgeber'),
    {
      required: true,
    },
  ),
  eintrittsdatum: new DateField('eintrittsdatum', label('eintrittsdatum')),
  kostenstelle: KostenstelleField.relationToOne(
    'kostenstelle',
    label('kostenstelle'),
    {},
  ),
  von: new DateField('von', label('von')),
  bis: new DateField('bis', label('bis')),
  brutto: new MoneyField('brutto', label('brutto'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  inflationGehaltsanpassung: new BooleanField(
    'inflationGehaltsanpassung',
    label('inflationGehaltsanpassung'),
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class VertragsaenderungModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
