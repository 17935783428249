import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import EnumeratorField from '@/shared/fields/enumerator-field';
import FilesField from '@/shared/fields/files-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';
import DateField from '@/shared/fields/date-field';
import { VorlageDienstgeberField } from '@/modules/vorlage-dienstgeber/vorlage-dienstgeber-field';

function label(name) {
  return i18n(`entities.beendigung.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.beendigung.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  datum: new DateField('datum', label('datum')),
  austrittsdatum: new DateField('austrittsdatum', label('austrittsdatum'), {
    required: true,
  }),
  // datum u modal form od koga se racuna first
  kuendigungMitDatum: new DateField(
    'kuendigungMitDatum',
    label('kuendigungMitDatum'),
    {
      required: true,
    },
  ),
  kuendigungUploadDate: new DateTimeField(
    'kuendigungUploadDate',
    label('kuendigungUploadDate'),
  ),
  decisionDate: new DateTimeField('decisionDate', label('decisionDate')),
  sentAt: new DateTimeField('sentAt', label('sentAt')),
  kuendigungEntwurf: new FilesField(
    'kuendigungEntwurf',
    label('kuendigungEntwurf'),
    Storage.values.beendigungKuendigungEntwurf,
    Permissions.getFilePermissions('beendigungFileFields', 'kuendigungEntwurf'),
    {
      size: 10485760,
      formats: ['pdf', 'doc', 'docx'],
      max: 1,
      required: true,
    },
  ),
  unterschriebeneKuendigung: new FilesField(
    'unterschriebeneKuendigung',
    label('unterschriebeneKuendigung'),
    Storage.values.beendigungUnterschriebeneKuendigung,
    Permissions.getFilePermissions(
      'vertragFileFields',
      'unterschriebeneKuendigung',
    ),
    {
      size: 10485760,
      formats: ['pdf'],
      required: true,
      max: 1,
    },
  ),
  entwurfStatus: new EnumeratorField(
    'entwurfStatus',
    label('entwurfStatus'),
    [
      {
        id: 'releasePending',
        label: enumeratorLabel('entwurfStatus', 'releasePending'),
      },
      {
        id: 'active',
        label: enumeratorLabel('entwurfStatus', 'active'),
      },
      {
        id: 'inactive',
        label: enumeratorLabel('entwurfStatus', 'inactive'),
      },
      {
        id: 'rejected',
        label: enumeratorLabel('entwurfStatus', 'rejected'),
      },
      {
        id: 'revisionRequested',
        label: enumeratorLabel('entwurfStatus', 'revisionRequested'),
      },
    ],
    {},
  ),
  kuendigungStatus: new EnumeratorField(
    'kuendigungStatus',
    label('kuendigungStatus'),
    [
      {
        id: 'active',
        label: enumeratorLabel('kuendigungStatus', 'active'),
      },
      {
        id: 'inactive',
        label: enumeratorLabel('kuendigungStatus', 'inactive'),
      },
    ],
    {},
  ),
  type: new EnumeratorField(
    'type',
    label('type'),
    [
      {
        id: 'beendigung_kuendigung_durchDG',
        label: enumeratorLabel('type', 'beendigung_kuendigung_durchDG'),
      },
      {
        id: 'beendigung_kuendigung_durchDN',
        label: enumeratorLabel('type', 'beendigung_kuendigung_durchDN'),
      },
      {
        id: 'beendigung_einvernehmlicheAufloesung',
        label: enumeratorLabel('type', 'beendigung_einvernehmlicheAufloesung'),
      },
    ],
    {},
  ),
  vorlageDienstgeber: new VorlageDienstgeberField.relationToOne(
    'vorlageDienstgeber',
    label('vorlageDienstgeber'),
    {
      required: true,
    },
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class BeendigungModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
