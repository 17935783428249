import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class StatistikAustriaPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(
      Permissions.values.statistikAustriaRead,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.statistikAustriaNavigate,
    );
  }
}
