<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      @sort-change="doChangeSort"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.change.label"
        :prop="fields.change.name"
      >
        <template v-slot="scope">{{ presenter(scope.row, 'change') }}</template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.updatedAt.label"
        :prop="fields.updatedAt.name"
        width="180"
      >
        <template v-slot="scope">
          {{ presenter(scope.row, 'updatedAt') }}
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        :label="fields.updatedBy.label"
        :prop="fields.updatedBy.name"
        width="350"
      >
        <template v-slot="scope">
          <app-list-item-relation-to-one
            :label="fields.updatedBy.label"
            :readPermission="fields.updatedBy.readPermission"
            :url="false"
            :value="presenter(scope.row, 'updatedBy')"
          ></app-list-item-relation-to-one>
        </template>
      </el-table-column>

      <el-table-column
        :show-overflow-tooltip="true"
        :fixed="isMobile ? undefined : 'right'"
        align="center"
        width="180"
      >
        <template v-slot="scope">
          <div class="table-actions">
            <el-button
              type="text"
              @click="doOpenModal(scope.row.id)"
              :disabled="false"
            >
              <app-i18n code="common.view"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
    <portal to="modal">
      <app-mitarbeiter-anstellung-form-modal
        :modal="true"
        :visible="dialogVisible"
        :anstellung="anstellung"
        @close="onModalClose"
        v-if="dialogVisible"
      ></app-mitarbeiter-anstellung-form-modal>
    </portal>
  </div>
</template>

<script>
import { MitarbeiterAnstellungModel } from '@/modules/mitarbeiter/mitarbeiter-anstellung-model';
import MitarbeiterAnstellungFormModal from '@/modules/mitarbeiter/components/mitarbeiter-anstellung-form-modal.vue';
import { mapGetters, mapActions } from 'vuex';
/* import { AnstellungPermissions } from '@/modules/anstellung/anstellung-permissions';
import { i18n } from '@/i18n'; */

const { fields } = MitarbeiterAnstellungModel;

export default {
  name: 'app-mitarbeiter-history-list-table',

  components: {
    [MitarbeiterAnstellungFormModal.name]: MitarbeiterAnstellungFormModal,
  },

  data() {
    return {
      dialogVisible: false,
      anstellung: null,
    };
  },

  mounted() {
    this.$watch(
      () => {
        return this.$refs.table.selection;
      },
      (rows) => {
        this.$store.dispatch('mitarbeiter/list/doSetSelectedRows', rows);
      },
    );
  },

  computed: {
    ...mapGetters({
      rows: 'mitarbeiter/history/rows',
      count: 'mitarbeiter/history/count',
      loading: 'mitarbeiter/history/loading',
      pagination: 'mitarbeiter/history/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),

    /*     hasPermissionToEdit() {
      return new AnstellungPermissions(this.currentUser).edit;
    }, */

    /*     hasPermissionToDestroy() {
      return new AnstellungPermissions(this.currentUser).destroy;
    }, */

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'mitarbeiter/history/doChangeSort',
      doChangePaginationCurrentPage:
        'mitarbeiter/history/doChangePaginationCurrentPage',
      doChangePaginationPageSize:
        'mitarbeiter/history/doChangePaginationPageSize',
      doMountTable: 'mitarbeiter/history/doMountTable',
    }),

    presenter(row, fieldName) {
      return MitarbeiterAnstellungModel.presenter(row, fieldName);
    },

    doOpenModal(payload) {
      this.anstellung = payload;
      this.dialogVisible = true;
    },

    onModalClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
