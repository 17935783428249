<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-on:close="handleTempFiles"
      width="30%"
    >
      <el-form
        label-position="top"
        :label-width="labelWidthForm"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
        v-loading="saveLoading"
        :disabled="vertragUploaded"
      >
        <div v-if="!isEditing">
          <el-form-item
            :label="fields.vorlageDienstgeber.label"
            :prop="fields.vorlageDienstgeber.name"
            :required="fields.vorlageDienstgeber.required"
          >
            <app-vorlage-dienstgeber-autocomplete-input
              :fetchFn="fields.vorlageDienstgeber.fetchFn"
              :mapperFn="fields.vorlageDienstgeber.mapperFn"
              mode="single"
              filter="vertrag"
              v-model="model[fields.vorlageDienstgeber.name]"
            ></app-vorlage-dienstgeber-autocomplete-input>
          </el-form-item>

          <el-form-item>
            <el-checkbox v-model="uploadMode">
              <app-i18n code="common.manualPreparation"></app-i18n>
            </el-checkbox>
          </el-form-item>

          <el-form-item
            :label="fields.vertragsentwurf.label"
            :prop="fields.vertragsentwurf.name"
            :required="fields.vertragsentwurf.required"
            v-if="uploadMode"
          >
            <app-file-upload
              :disabled="isEditing"
              :max="fields.vertragsentwurf.max"
              :storage="fields.vertragsentwurf.storage"
              :formats="fields.vertragsentwurf.formats"
              :permissions="fields.vertragsentwurf.permissions"
              v-model="model[fields.vertragsentwurf.name]"
            ></app-file-upload>
          </el-form-item>
        </div>

        <div v-if="isEditing">
          <el-form-item
            :label="fields.entwurfStatus.label"
            :prop="fields.entwurfStatus.name"
            :required="fields.entwurfStatus.required"
          >
            <el-tag
              :type="getStatusTagType(model[fields.entwurfStatus.name])"
              v-if="isStatusNull(model[fields.entwurfStatus.name])"
            >
              {{ model[fields.entwurfStatus.name] }}
            </el-tag>
          </el-form-item>
          <el-form-item
            :label="fields.decisionDate.label"
            :prop="fields.decisionDate.name"
            :required="fields.decisionDate.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-date-picker
                :readonly="isEditing"
                :format="elementUiDateFormat"
                placeholder
                type="date"
                v-model="model[fields.decisionDate.name]"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item
            :label="fields.sentAt.label"
            :prop="fields.sentAt.name"
            :required="fields.sentAt.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-date-picker
                :readonly="isEditing"
                :format="elementUiDateFormat"
                placeholder
                type="date"
                v-model="model[fields.sentAt.name]"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item
            :label="fields.vertragStatus.label"
            :prop="fields.vertragStatus.name"
            :required="fields.vertragStatus.required"
          >
            <el-tag
              :type="getStatusTagType(model[fields.vertragStatus.name])"
              v-if="isStatusNull(model[fields.vertragStatus.name])"
            >
              {{ model[fields.vertragStatus.name] }}
            </el-tag>
          </el-form-item>

          <el-form-item
            :label="fields.unterschriebenerVertrag.label"
            :prop="fields.unterschriebenerVertrag.name"
            :required="fields.unterschriebenerVertrag.required"
          >
            <app-file-upload
              :max="fields.unterschriebenerVertrag.max"
              :storage="fields.unterschriebenerVertrag.storage"
              :formats="fields.unterschriebenerVertrag.formats"
              :permissions="fields.unterschriebenerVertrag.permissions"
              v-model="model[fields.unterschriebenerVertrag.name]"
            ></app-file-upload>
          </el-form-item>
        </div>

        <el-form-item>
          <div class="form-buttons">
            <el-button
              :disabled="saveLoading"
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
              v-if="uploadMode || isEditing"
            >
              <app-i18n code="common.save"></app-i18n>
            </el-button>
            <el-button
              :disabled="saveLoading"
              @click="createDraft"
              icon="el-icon-fa-floppy-o"
              type="primary"
              v-if="!isEditing"
            >
              <app-i18n code="common.prepare"></app-i18n>
            </el-button>

            <el-button
              :disabled="saveLoading"
              @click="doCancel"
              icon="el-icon-fa-close"
            >
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { VertragModel } from '@/modules/vertrag/vertrag-model';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { FormSchema } from '@/shared/form/form-schema';
import { i18n } from '@/i18n';
import { EventBus } from '@/eventBus';

const { fields } = VertragModel;
const formSchema = new FormSchema([
  fields.id,
  fields.eintrittsdatum,
  fields.entwurfStatus,
  fields.decisionDate,
  fields.sentAt,
  fields.vertragsentwurf,
  fields.unterschriebenerVertrag,
  fields.vorlageDienstgeber,
  fields.vertragStatus,
]);

export default {
  name: 'app-vertrag-form-modal',

  props: ['record', 'mitarbeiterId', 'visible'],

  data() {
    return {
      model: null,
      rules: formSchema.rules(),
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});

    EventBus.$once('doSubmitVertragsentwurf', () => {
      this.doSubmit();
    });
    EventBus.$once('doSetVertragsentwurf', () => {
      this.doSetVertragsentwurf();
    });
  },

  beforeDestroy() {
    EventBus.$off('doSubmitVertragsentwurf');
    EventBus.$off('doSetVertragsentwurf');
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      loading: 'vertrag/loading',
      saveLoading: 'vertrag/saveLoading',

      entwurf: 'vertrag/entwurfFile',
      manualUploadMode: 'vertrag/manualUploadMode',
    }),

    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$store.dispatch('vertrag/doSetUploadMode', false);
          this.$emit('close');
        }
      },
    },
    uploadMode: {
      get() {
        return this.manualUploadMode;
      },
      set(value) {
        this.$store.dispatch('vertrag/doSetUploadMode', value);
      },
    },
    isEditing() {
      return !!this.record;
    },
    pendingFiles() {
      if (!this.model) return [];

      let unsaved = [];
      const vertragsentwurf = this.model.vertragsentwurf.filter((f) => f.new);
      if (this.model.vertrag) {
        const vertrag = this.model.vertrag.filter((f) => f.new);
        unsaved = unsaved.concat(vertrag);
      }

      unsaved = unsaved.concat(vertragsentwurf);
      return unsaved;
    },
    fields() {
      return fields;
    },
    title() {
      return this.isEditing
        ? i18n('entities.vertrag.edit.title')
        : i18n('entities.vertrag.new.title');
    },
    vertragUploaded() {
      if (this.record) {
        return !!this.record.vertragStatus;
      } else return false;
    },
  },

  methods: {
    ...mapActions({
      doCreateDraft: 'vertrag/doCreateDraft',
    }),
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);
      values.mitarbeiter = this.mitarbeiterId;

      let action = 'create';
      if (this.isEditing) {
        action = 'update';
      }

      return this.$emit(action, {
        id,
        values,
      });
    },
    async createDraft() {
      try {
        if (!this.model.vorlageDienstgeber) {
          await this.$refs.form.validateField('vorlageDienstgeber');
          return;
        }
      } catch (error) {
        console.log(error);
        return;
      }
      const { id, ...values } = formSchema.cast(this.model);

      values.mitarbeiter = this.mitarbeiterId;
      await this.doCreateDraft({
        id,
        values,
      });

      this.$emit('openEntwurfDialog');
    },
    doSetVertragsentwurf() {
      this.model.vertragsentwurf = [this.entwurf];
    },
    doCancel() {
      this.handleTempFiles();
      this.dialogVisible = false;
    },
    handleTempFiles() {
      if (this.pendingFiles.length > 0) {
        FileUploader.removePendingFiles(this.pendingFiles);
      }
    },
    isStatusNull(status) {
      return status === null ? false : true;
    },
    getStatusTagType(status) {
      if (status === 'active') {
        return 'success';
      }
      if (status === 'requested' || status === 'revisionRequested') {
        return 'warning';
      }
      if (status === 'rejected') {
        return 'danger';
      }
      if (status === 'releasePending') {
        return '';
      }
      if (status === 'inactive') {
        return 'info';
      }
      return '';
    },
  },
};
</script>

<style></style>
