<template>
  <div>
    <app-vertragsaenderung-list-toolbar
      v-on:doOpenFormUploadModal="doOpenFormUploadModal"
      :isDisabled="isFormDisabled"
    ></app-vertragsaenderung-list-toolbar>
    <app-vertragsaenderung-list-table
      v-on:doOpenPreviewModal="doOpenVertragsaenderungPreviewModal"
      v-on:doOpenRequestsModal="doOpenVertragsaenderungRequestsModal"
      v-on:doOpenFormModal="doOpenFormModal"
      :isDisabled="isFormDisabled"
    ></app-vertragsaenderung-list-table>
    <app-approval-request-list-table
      :visible="dialogAapprovalRequestVisible"
      v-on:close="doCloseEntwurfRequestsModal"
      :rows="entwurfRequests"
    ></app-approval-request-list-table>
    <div>
      <portal to="modal">
        <app-vertragsaenderung-approval-modal
          :visible="dialogVertragsaenderungApprovalVisible"
          v-on:close="doCloseVertragsaenderungApprovalModal"
          v-on:success="doCloseVertragsaenderungApprovalModal"
          v-on:doUpdateApprovalRequest="doUpdateApprovalRequest"
          v-if="dialogVertragsaenderungApprovalVisible"
          :record="row"
          :approvers="approvers"
        ></app-vertragsaenderung-approval-modal>
        <app-vertragsaenderung-entwurf-modal
          :visible="dialogEntwurfVisible"
          v-on:close="closeEntwurfDialog"
          v-if="dialogEntwurfVisible"
          :file="entwurf"
        ></app-vertragsaenderung-entwurf-modal>
        <app-vertragsaenderung-form-modal
          :visible="formDialogVisible"
          :record="row"
          :grundgehalt="grundgehalt"
          :mitarbeiterId="mitarbeiterId"
          v-on:close="doCloseFormModal"
          v-on:create="create"
          v-on:update="update"
          v-on:openEntwurfDialog="openEntwurfDialog"
          v-if="formDialogVisible"
          ref="formModal"
        ></app-vertragsaenderung-form-modal>
        <app-vertragsaenderung-form-upload-modal
          :visible="formUploadDialogVisible"
          :record="row"
          :mitarbeiterId="mitarbeiterId"
          v-on:close="doCloseFormUploadModal"
          v-on:create="createManual"
          v-if="formUploadDialogVisible"
        ></app-vertragsaenderung-form-upload-modal>
      </portal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VertragsaenderungApprovalModal from '@/modules/vertragsaenderung/components/vertragsaenderung-approval-modal.vue';
import VertragsaenderungEntwurfModal from '@/modules/vertragsaenderung/components/vertragsaenderung-entwurf-modal.vue';
import VertragsaenderungListToolbar from '@/modules/vertragsaenderung/components/vertragsaenderung-list-toolbar.vue';
import VertragsaenderungFormModal from '@/modules/vertragsaenderung/components/vertragsaenderung-form-modal.vue';
import VertragsaenderungFormUploadModal from '@/modules/vertragsaenderung/components/vertragsaenderung-form-upload-modal.vue';
import VertragsaenderungListTable from '@/modules/vertragsaenderung/components/vertragsaenderung-list-table.vue';
import ApprovalRequestListTable from '@/modules/approval-request/components/approval-request-list-table.vue';
import { FileUploader } from '@/shared/file-upload/file-uploader';

export default {
  name: 'app-vertragsaenderung-main',
  props: ['id'],
  components: {
    [VertragsaenderungApprovalModal.name]: VertragsaenderungApprovalModal,
    [VertragsaenderungEntwurfModal.name]: VertragsaenderungEntwurfModal,
    [VertragsaenderungListToolbar.name]: VertragsaenderungListToolbar,
    [VertragsaenderungListTable.name]: VertragsaenderungListTable,
    [ApprovalRequestListTable.name]: ApprovalRequestListTable,
    [VertragsaenderungFormModal.name]: VertragsaenderungFormModal,
    [VertragsaenderungFormUploadModal.name]: VertragsaenderungFormUploadModal,
  },
  data() {
    return {
      row: null,
      entwurfRequests: [],
      dialogVertragsaenderungApprovalVisible: false,
      dialogAapprovalRequestVisible: false,
      dialogEntwurfVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      contractApprover: 'settings/contractApprover',
      mitarbeiterId: 'mitarbeiter/form/id',
      isFormDisabled: 'mitarbeiter/form/isFormDisabled',
      formDialogVisible: 'vertragsaenderung/formDialogVisible',
      formUploadDialogVisible: 'vertragsaenderung/formUploadDialogVisible',
      entwurf: 'vertragsaenderung/entwurfFile',
      grundgehalt: 'mitarbeiter/form/grundgehalt',
    }),
    isEditing() {
      return !!this.id;
    },
    approvers() {
      return this.contractApprover.map((f) => f.id);
    },
  },
  async created() {},
  methods: {
    ...mapActions({
      loading: 'vertragsaenderung/loading',
      saveLoading: 'vertragsaenderung/saveLoading',
      doUpdateStatus: 'vertragsaenderung/doUpdateStatus',
      doShowFormDialog: 'vertragsaenderung/doShowFormDialog',
      doShowFormUploadDialog: 'vertragsaenderung/doShowFormUploadDialog',
      doCreate: 'vertragsaenderung/doCreate',
      doCreateManual: 'vertragsaenderung/doCreateManual',
      doUpdate: 'vertragsaenderung/doUpdate',
    }),

    async create(payload) {
      await this.doCreate(payload.values);
      this.doShowFormDialog();
    },
    async createManual(payload) {
      await this.doCreateManual(payload.values);
      this.doShowFormUploadDialog();
    },
    async update(payload) {
      await this.doUpdate(payload);
      this.doShowFormDialog();
    },

    onModalSuccess() {
      this.doCloseVertragsaenderungApprovalModal();
    },
    doOpenVertragsaenderungPreviewModal(row) {
      this.row = row;
      this.dialogVertragsaenderungApprovalVisible = true;
    },
    doCloseVertragsaenderungApprovalModal() {
      this.dialogVertragsaenderungApprovalVisible = false;
      this.row = null;
    },
    doOpenVertragsaenderungRequestsModal(rows) {
      this.entwurfRequests = rows;
      this.dialogAapprovalRequestVisible = true;
    },
    doCloseEntwurfRequestsModal() {
      this.dialogAapprovalRequestVisible = false;
    },
    doOpenFormModal(payload) {
      this.row = payload;
      this.doShowFormDialog();
    },
    doOpenFormUploadModal(payload) {
      this.row = payload;
      this.doShowFormUploadDialog();
    },
    doCloseFormModal() {
      this.doShowFormDialog();
      this.row = null;
    },
    doCloseFormUploadModal() {
      this.doShowFormUploadDialog();
      this.row = null;
    },
    openEntwurfDialog() {
      this.dialogEntwurfVisible = true;
    },
    closeEntwurfDialog() {
      this.dialogEntwurfVisible = false;
      if (this.entwurf) {
        FileUploader.removePendingFiles([this.entwurf]);
      }
    },

    async doUpdateApprovalRequest(payload) {
      this.dialogVertragsaenderungApprovalVisible = false;
      await this.doUpdateStatus(payload);
    },
  },
};
</script>

<style></style>
