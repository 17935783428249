import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';

function label(name) {
  return i18n(`entities.templateType.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`entities.templateType.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
    max: 255,
  }),
  type: new EnumeratorField(
    'type',
    label('type'),
    [
      {
        id: 'bestaetigung',
        label: enumeratorLabel('type', 'bestaetigung'),
      },
      {
        id: 'kuendigung',
        label: enumeratorLabel('type', 'kuendigung'),
      },
      {
        id: 'vertrag',
        label: enumeratorLabel('type', 'vertrag'),
      },
      {
        id: 'karenz',
        label: enumeratorLabel('type', 'karenz'),
      },
    ],
    { required: true },
  ),
};

export class TemplateTypeModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
