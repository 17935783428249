import { i18n } from '@/i18n';
import EnumeratorField from '@/shared/fields/enumerator-field';
import StringField from '@/shared/fields/string-field';
import { JahreskarteModel } from '@/modules/jahreskarte/jahreskarte-model';

function label(name) {
  return i18n(`entities.mitarbeiter.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.mitarbeiter.enumerators.${name}.${value}`);
}

const fields = {
  mitarbeiterNr: new StringField('mitarbeiterNr', label('mitarbeiterNr'), {
    required: false,
    max: 50,
  }),
  anrede: new EnumeratorField('anrede', label('anrede'), [
    {
      id: 'Herr',
      label: enumeratorLabel('anrede', 'herr'),
    },
    {
      id: 'Frau',
      label: enumeratorLabel('anrede', 'frau'),
    },
  ]),
  titel: new StringField('titel', label('titel'), {
    required: false,
    max: 50,
  }),
  nachname: new StringField('nachname', label('nachname'), {
    required: false,
    max: 100,
  }),
  vorname: new StringField('vorname', label('vorname'), {
    required: false,
    max: 100,
  }),
  vollNameExport: new StringField('vollNameExport', label('vollNameExport')),
  svNummer: new StringField('svNummer', label('svNummer'), {
    required: false,
    matches: /^[0-9]{4}/,
    max: 20,
  }),
};

export class JahreskarteExportModel extends JahreskarteModel {
  static get fields() {
    return {
      ...super.fields, // Access fields from JahreskarteModel
      ...fields,
    };
  }
}
