<template>
  <div>
    <div>
      <el-upload
        :accept="accept"
        :file-list="fileList"
        :http-request="uploadFromRequest"
        :limit="max"
        :on-error="onError"
        :on-preview="doOpenModal"
        :on-remove="onRemove"
        :on-success="onSuccess"
        :disabled="isDisabled"
        :before-upload="beforeUpload"
        :before-remove="beforeRemove"
        action
        ref="files"
      >
        <el-button
          :disabled="loading || isFull || isDisabled"
          size="small"
          type="primary"
        >
          <app-i18n code="fileUploader.upload"></app-i18n>
        </el-button>
      </el-upload>
    </div>
    <div v-if="dialogVisible">
      <app-pdf-view-modal
        :file="file"
        :visible="dialogVisible"
        @close="onModalClose"
        :hasPermissionToDownload="hasPermissionToDownload"
      ></app-pdf-view-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import FilePermissionChecker from '@/security/filePermissionChecker';
import PdfPreview from '@/shared/pdf-view/components/pdf-view-modal.vue';

import Message from '@/shared/message/message';
import Errors from '@/shared/error/errors';
import { i18n } from '@/i18n';

export default {
  name: 'app-file-upload',

  props: ['storage', 'value', 'formats', 'max', 'disabled', 'permissions'],

  components: { [PdfPreview.name]: PdfPreview },

  data() {
    return {
      /* fileList: (this.value || []).map((item) => ({
        ...item,
        url: item.downloadUrl,
      })), */
      loading: false,
      dialogVisible: false,
      file: null,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    fileList() {
      return (this.value || []).map((item) => ({
        ...item,
        url: item.downloadUrl,
      }));
    },
    isFull() {
      const hasInputReference = !!this.$refs.files;

      return (
        (this.max &&
          hasInputReference &&
          this.$refs.files.uploadFiles.length >= this.max) ||
        (!hasInputReference && (this.value || []).length >= this.max)
      );
    },
    accept() {
      return this.formats
        ? this.formats.map((format) => `.${format}`).join(',')
        : undefined;
    },
    isDisabled() {
      return !!this.disabled;
    },
    hasPermissionToDownload() {
      return new FilePermissionChecker(this.currentUser).download(
        this.permissions,
      );
    },
  },

  methods: {
    async uploadFromRequest(request) {
      this.loading = true;
      return FileUploader.uploadFromRequest(request, {
        storage: this.storage,
        formats: this.formats,
      });
    },

    onSuccess(file) {
      if (!file) {
        return;
      }

      this.$emit('input', [...(this.value || []), file]);
      this.loading = false;
    },

    onError(error) {
      Errors.showMessage(error);
      this.loading = false;
    },

    onRemove(file, files) {
      if (file.new) {
        //FileUploader.removePendingFiles([file.response]);
        FileUploader.removePendingFiles([file]);
      }

      this.$emit(
        'input',
        (this.value || []).filter((item) =>
          files.some((file) =>
            file.response ? file.response.id === item.id : file.id === item.id,
          ),
        ),
      );
    },

    beforeUpload(file) {
      if (file) {
        const hasPermission = new FilePermissionChecker(
          this.currentUser,
        ).upload(this.permissions);

        if (!hasPermission) {
          Message.warning(i18n('errors.files.noPermissions', 'upload'));
        }

        return hasPermission;
      }
    },

    beforeRemove(file, fileList) {
      if (file && fileList) {
        //check if user is removing the file which was not yet saved
        if (file.new) {
          return true;
        }

        const hasPermission = new FilePermissionChecker(
          this.currentUser,
        ).remove(this.permissions);

        if (!hasPermission) {
          Message.warning(i18n('errors.files.noPermissions', 'remove'));
        }

        return hasPermission;
      }
    },

    download(file) {
      const fileUrl = file.url ? file.url : file.response.downloadUrl;
      window.open(fileUrl, '_blank');
    },

    doOpenModal(file) {
      if (!file.new) {
        this.file = file;
        this.dialogVisible = true;
      }
    },
    onModalClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
