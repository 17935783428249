<template>
  <el-form-item
    :label="label"
    v-if="!isBlank && hasPermissionToDownload"
  >
    <el-col :lg="13" :md="16" :sm="24">
      <div :key="item.name" v-for="item in value">
        <strong>
          <a :href="item.downloadUrl" download target="_self">{{
            truncate(item.name)
          }}</a>
        </strong>
      </div>
    </el-col>
  </el-form-item>
</template>

<script>
import { mapGetters } from 'vuex';
import { truncate } from 'lodash';
import  FilePermissionChecker from '@/security/filePermissionChecker';

export default {
  name: 'app-view-item-file',

  props: ['label', 'value', 'permissions'],

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    
    isBlank() {
      return !this.value || !this.value.length;
    },

    hasPermissionToDownload() {
      return new FilePermissionChecker(this.currentUser).download(this.permissions);
    },
  },
  methods: {
    truncate(name) {
      return truncate(name);
    },
  },
};
</script>

<style>
</style>
