import verwendungKostenstelleListStore from '@/modules/verwendung-kostenstelle/verwendung-kostenstelle-list-store';
import verwendungKostenstelleFormStore from '@/modules/verwendung-kostenstelle/verwendung-kostenstelle-form-store';
import verwendungKostenstelleDestroyStore from '@/modules/verwendung-kostenstelle/verwendung-kostenstelle-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: verwendungKostenstelleDestroyStore,
    form: verwendungKostenstelleFormStore,
    list: verwendungKostenstelleListStore,
  },
};
