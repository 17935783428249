<template>
  <div style="display: flex">
    <app-autocomplete-one-input
      :fetchFn="fetchFn"
      v-if="mode !== 'multiple'"
      v-model="model"
      :disabled="disabled"
    ></app-autocomplete-one-input>

    <app-autocomplete-many-input
      :fetchFn="fetchFn"
      v-if="mode === 'multiple'"
      v-model="model"
      :disabled="disabled"
    ></app-autocomplete-many-input>
  </div>
</template>

<script>
export default {
  name: 'app-hauptkostenstelle-autocomplete-input',
  props: ['value', 'mode', 'fetchFn', 'disabled'],

  data() {
    return {
      dialogVisible: false,
    };
  },

  computed: {
    model: {
      get: function() {
        return this.value;
      },

      set: function(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style>
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
</style>
