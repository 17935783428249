let inMemoryToken = null;
let inMemoryRefreshToken = null;

export class AuthToken {
  static async get() {
    return (
      inMemoryToken || localStorage.getItem('jwt') || null
    );
  }

  static async getRefreshToken() {
    return (
      inMemoryRefreshToken || localStorage.getItem('jwtRefresh') || null
    );
  }

  static async setTokens(token, refreshToken, rememberMe) {
    if (rememberMe) {
      localStorage.setItem('jwt', token || '');
      localStorage.setItem('jwtRefresh', refreshToken || '');
    } else {
      inMemoryToken = token;
      inMemoryRefreshToken = refreshToken;
      localStorage.setItem('jwt', '');
      localStorage.setItem('jwtRefresh', '');
    }
  }

  static async setAccessToken(token) {
    localStorage.setItem('jwt', token || '');
    inMemoryToken = token;
  }

  static async clearTokens() {
    inMemoryToken = null;
    inMemoryRefreshToken = null;
    localStorage.setItem('jwt', '');
    localStorage.setItem('jwtRefresh', '');
  }

  static applyFromLocationUrlIfExists() {
    const urlParams = new URLSearchParams(
      window.location.search,
    );
    const authToken = urlParams.get('authToken');

    if (!authToken) {
      return;
    }

    this.set(authToken, true);
    window.history.replaceState(
      {},
      document.title,
      window.location.origin,
    );
  }
}
