import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const KnowledgeBaseListPage = () =>
  import('@/modules/knowledge-base/components/knowledge-base-list-page.vue');
const KnowledgeBaseFormPage = () =>
  import('@/modules/knowledge-base/components/knowledge-base-form-page.vue');
const KnowledgeBaseViewPage = () =>
import('@/modules/knowledge-base/components/knowledge-base-view-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'knowledgeBase',
        path: '/knowledge-base',
        component: KnowledgeBaseListPage,
        meta: {
          auth: true,
          permission: Permissions.values.knowledgeBaseNavigate,
        },
      },
      {
        name: 'knowledgeBaseNew',
        path: '/knowledge-base/new',
        component: KnowledgeBaseFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.knowledgeBaseNavigate,
        },
      },   
      {
        name: 'knowledgeBaseEdit',
        path: '/knowledge-base/:id/edit',
        component: KnowledgeBaseFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.knowledgeBaseNavigate,
        },
        props: true,
      },      
      {
        name: 'knowledgeBaseView',
        path: '/knowledge-base/:id',
        component: KnowledgeBaseViewPage,
        meta: {
          auth: true,
          permission: Permissions.values.knowledgeBaseNavigate,
        },
        props: true,
      },
    ],
  },
];
