import { storeAsync } from '@/app-module';
import PermissionChecker from '@/modules/user/permission-checker';
export default {
  async beforeRouteEnter(to, from, next) {
    if (!to.meta || !to.meta.auth) {
      next();
      return;
    }
    const store = storeAsync();
    await store.dispatch('auth/doWaitUntilInit');
    const permissionChecker = new PermissionChecker(
      store.getters['auth/currentUser'],
    );
    if (!permissionChecker.isAuthenticated) {
      next({ path: '/auth/signin' });
      //if user is trying to open link directly from email here we save this url to the store so we can route later after success signin
      await store.dispatch('auth/doSetAuthRedirectUrl', to.fullPath);
      return;
    }
    if (
      to.path !== '/auth/empty-permissions' &&
      permissionChecker.isEmptyPermissions
    ) {
      next({ path: '/auth/empty-permissions' });
      return;
    }
    if (
      to.path !== '/auth/password-initial-change' &&
      to.path !== '/auth/empty-permissions' &&
      !permissionChecker.isPasswordChanged
    ) {
      next({ path: '/auth/password-initial-change' });
      return;
    }
    next();
  },
};
