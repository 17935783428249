import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class VertragPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(Permissions.values.vertragRead);
    this.autocomplete = permissionChecker.match(
      Permissions.values.vertragAutocomplete,
    );
    this.create = permissionChecker.match(Permissions.values.vertragCreate);
    this.edit = permissionChecker.match(Permissions.values.vertragEdit);
    this.destroy = permissionChecker.match(Permissions.values.vertragDestroy);
    this.setToInactive = permissionChecker.match(
      Permissions.values.vertragSetToInactive,
    );
    this.sendEmailToEmployee = permissionChecker.match(
      Permissions.values.vertragSendEmailToEmployee,
    );
    this.sendEmailToEmployeeMultipleTimes = permissionChecker.match(
      Permissions.values.vertragSendEmailToEmployeeMultipleTimes,
    );
  }
}
