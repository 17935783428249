import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class JahreskartePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);
    this.read = permissionChecker.match(Permissions.values.jahreskarteRead);
    this.create = permissionChecker.match(Permissions.values.jahreskarteCreate);
    this.edit = permissionChecker.match(Permissions.values.jahreskarteEdit);
    this.destroy = permissionChecker.match(
      Permissions.values.jahreskarteDestroy,
    );
  }
}
