<template>
  <div style="display: flex">
    <app-autocomplete-one-input
      :fetchFn="fetchFn"
      :filter="filter"
      v-if="mode !== 'multiple'"
      v-model="model"
      :disabled="disabled"
    ></app-autocomplete-one-input>
    <app-autocomplete-many-input
      :fetchFn="fetchFn"
      :filter="filter"
      v-if="mode === 'multiple'"
      v-model="model"
      :disabled="disabled"
    ></app-autocomplete-many-input>

    <!--
      <el-button
      @click="doOpenModal()"
      icon="el-icon-plus"
      style="margin-left: 16px"
      type="primary"
      v-if="hasPermissionToCreate && showCreate"
    ></el-button>
    <portal to="modal">
      <app-vertragsart-form-modal
        :visible="dialogVisible"
        @close="onModalClose"
        @success="onModalSuccess"
        v-if="dialogVisible"
      ></app-vertragsart-form-modal>
    </portal>
    -->
  </div>
</template>

<script>
import VertragsartFormModal from '@/modules/vertragsart/components/vertragsart-form-modal';
import { VertragsartPermissions } from '@/modules/vertragsart/vertragsart-permissions';
import { mapGetters } from 'vuex';

export default {
  name: 'app-vertragsart-autocomplete-input',
  props: [
    'value',
    'mode',
    'fetchFn',
    'mapperFn',
    'showCreate',
    'filter',
    'disabled',
  ],

  components: {
    [VertragsartFormModal.name]: VertragsartFormModal,
  },

  data() {
    return {
      dialogVisible: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    model: {
      get: function () {
        return this.value;
      },

      set: function (value) {
        this.$emit('input', value);
      },
    },

    hasPermissionToCreate() {
      return new VertragsartPermissions(this.currentUser).create;
    },
  },

  methods: {
    doOpenModal() {
      this.dialogVisible = true;
    },

    onModalSuccess(record) {
      if (this.mode === 'multiple') {
        this.model = [...this.model, this.mapperFn(record)];
      } else {
        this.model = this.mapperFn(record);
      }

      this.onModalClose();
    },

    onModalClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
</style>
