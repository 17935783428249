import { VerwendungKostenstelleService } from '@/modules/verwendung-kostenstelle/verwendung-kostenstelle-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class VerwendungKostenstelleField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/verwendung-kostenstelle',
      Permissions.values.mandantRead,
      Permissions.values.verwendungKostenstelleNavigate,
      VerwendungKostenstelleService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: record.name,
        };
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/verwendung-kostenstelle',
      Permissions.values.mandantRead,
      Permissions.values.verwendungKostenstelleNavigate,
      VerwendungKostenstelleService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: record.name,
        };
      },
      options,
    );
  }
}
