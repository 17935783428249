import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class VorlageDienstgeberPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(
      Permissions.values.vorlageDienstgeberRead,
    );
    this.vorlageDienstgeberAutocomplete = permissionChecker.match(
      Permissions.values.vorlageDienstgeberAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.vorlageDienstgeberCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.vorlageDienstgeberEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.vorlageDienstgeberDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.vorlageDienstgeberNavigate,
    );
  }
}
