import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class AenderungPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(Permissions.values.aenderungRead);
    this.navigate = permissionChecker.match(
      Permissions.values.aenderungNavigate,
    );
    this.sendToLohnverrechnung = permissionChecker.match(
      Permissions.values.aenderungSendToLohnverrechnung,
    );
  }
}
