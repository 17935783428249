<template>
  <div>
    <el-table
      :border="true"
      :data="rows"
      ref="table"
      row-key="id"
      v-loading="loading"
    >
      <el-table-column
        type="selection"
        :selectable="selectable"
        width="40"
      ></el-table-column>

      <el-table-column
        :label="fields.austrittsdatum.label"
        :prop="fields.austrittsdatum.name"
      >
        <template v-slot="scope">
          {{ presenter(scope.row, 'austrittsdatum') }}
        </template>
      </el-table-column>

      <el-table-column :label="fields.type.label" :prop="fields.type.name">
        <template v-slot="scope">{{ presenter(scope.row, 'type') }}</template>
      </el-table-column>

      <el-table-column
        :label="fields.kuendigungEntwurf.label"
        :prop="fields.kuendigungEntwurf.name"
      >
        <template v-slot="scope">
          <app-list-item-file
            :permissions="fields.kuendigungEntwurf.permissions"
            :value="presenter(scope.row, 'kuendigungEntwurf')"
          ></app-list-item-file>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.entwurfStatus.label"
        :prop="fields.entwurfStatus.name"
      >
        <template v-slot="scope">
          <el-tag
            :type="getStatusTagType(scope.row[fields.entwurfStatus.name])"
            v-if="isStatusNull(scope.row[fields.entwurfStatus.name])"
          >
            {{ presenter(scope.row, 'entwurfStatus') }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.decisionDate.label"
        :prop="fields.decisionDate.name"
      >
        <template v-slot="scope">
          {{ presenter(scope.row, 'decisionDate') }}
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.unterschriebeneKuendigung.label"
        :prop="fields.unterschriebeneKuendigung.name"
      >
        <template v-slot="scope">
          <app-list-item-file
            :permissions="fields.unterschriebeneKuendigung.permissions"
            :value="presenter(scope.row, 'unterschriebeneKuendigung')"
          ></app-list-item-file>
        </template>
      </el-table-column>

      <el-table-column
        :label="fields.kuendigungStatus.label"
        :prop="fields.kuendigungStatus.name"
        width="120"
      >
        <template v-slot="scope">
          <el-tag
            :type="getStatusTagType(scope.row[fields.kuendigungStatus.name])"
            v-if="isStatusNull(scope.row[fields.kuendigungStatus.name])"
          >
            {{ presenter(scope.row, 'kuendigungStatus') }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="fields.sentAt.label" :prop="fields.sentAt.name">
        <template v-slot="scope">{{ presenter(scope.row, 'sentAt') }}</template>
      </el-table-column>
      <el-table-column
        :label="fields.kuendigungUploadDate.label"
        :prop="fields.kuendigungUploadDate.name"
      >
        <template v-slot="scope">
          {{ presenter(scope.row, 'kuendigungUploadDate') }}
        </template>
      </el-table-column>
      <el-table-column align="center" width="180">
        <template v-slot="scope">
          <div class="table-actions">
            <el-button
              v-if="editButtonEnabled(scope.row)"
              v-on:click="doOpenFormModal(scope.row)"
              type="text"
            >
              <app-i18n code="common.edit"></app-i18n>
            </el-button>
            <el-button
              v-on:click="doOpenPreviewModal(scope.row)"
              type="text"
              v-if="previewButtonEnabled(scope.row)"
            >
              <app-i18n code="common.preview"></app-i18n>
            </el-button>
            <el-button v-on:click="doOpenRequestsModal(scope.row)" type="text">
              <app-i18n code="common.status"></app-i18n>
            </el-button>
            <el-button
              v-if="sendButtonEnabled(scope.row)"
              v-on:click="doSendToMitarbeiter(scope.row)"
              type="text"
            >
              <app-i18n code="common.send"></app-i18n>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="el-pagination-wrapper">
      <el-pagination
        :current-page="pagination.currentPage || 1"
        :disabled="loading"
        :layout="paginationLayout"
        :total="count"
        @current-change="doChangePaginationCurrentPage"
        @size-change="doChangePaginationPageSize"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { BeendigungModel } from '@/modules/beendigung/beendigung-model';
import { mapGetters, mapActions } from 'vuex';
import { BeendigungPermissions } from '@/modules/beendigung/beendigung-permissions';
import { i18n } from '@/i18n';

const { fields } = BeendigungModel;

export default {
  name: 'app-beendigung-list-table',

  props: ['isDisabled'],

  data() {
    return {};
  },

  mounted() {
    this.$watch(
      () => {
        return this.$refs.table.selection;
      },
      (rows) => {
        this.$store.dispatch('beendigung/doSetSelectedRows', rows);
      },
    );
  },

  computed: {
    ...mapGetters({
      rows: 'beendigung/rows',
      count: 'beendigung/count',
      loading: 'beendigung/loading',
      hasRows: 'beendigung/hasRows',
      pagination: 'beendigung/pagination',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),
    hasPermissionToCreate() {
      return new BeendigungPermissions(this.currentUser).create;
    },
    hasPermissionToEdit() {
      return new BeendigungPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new BeendigungPermissions(this.currentUser).destroy;
    },
    hasPermissionToSendEmailToMA() {
      return new BeendigungPermissions(this.currentUser).sendEmailToEmployee;
    },
    hasPermissionToSendEmailToMAMultipleTimes() {
      return new BeendigungPermissions(this.currentUser)
        .sendEmailToEmployeeMultipleTimes;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doChangeSort: 'beendigung/doChangeSort',
      doChangePaginationCurrentPage: 'beendigung/doChangePaginationCurrentPage',
      doChangePaginationPageSize: 'beendigung/doChangePaginationPageSize',
      doSendEmailToMitarbeiter: 'beendigung/doSendEmailToMitarbeiter',
    }),

    presenter(row, fieldName) {
      return BeendigungModel.presenter(row, fieldName);
    },

    sendButtonEnabled(row) {
      if (row.entwurfStatus != 'active') {
        return false;
      }
      const alreadySent = row.entwurfStatus === 'active' && Boolean(row.sentAt);
      return (
        (alreadySent
          ? this.hasPermissionToSendEmailToMAMultipleTimes
          : this.hasPermissionToSendEmailToMA) && !this.isDisabled
      );
    },

    editButtonEnabled(row) {
      if (row.entwurfStatus != 'active') {
        return false;
      }
      if (row.kuendigungStatus === 'active') {
        return false;
      }

      return this.hasPermissionToEdit && !this.isDisabled;
    },
    previewButtonEnabled(row) {
      if (row.entwurfStatus != 'releasePending') {
        return false;
      } else return true;
    },

    selectable(row) {
      return row.entwurfStatus !== 'active' || this.hasPermissionToDestroy;
    },

    doOpenPreviewModal(row) {
      return this.$emit('doOpenPreviewModal', row);
    },

    doOpenFormModal(row) {
      return this.$emit('doOpenFormModal', row);
    },

    doOpenRequestsModal(row) {
      return this.$emit('doOpenRequestsModal', row.approvalRequest);
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },

    async doSendToMitarbeiter(row) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        const { id, ...values } = row;

        return this.doSendEmailToMitarbeiter({
          id,
          values,
        });
      } catch (error) {
        // no
      }
    },

    isStatusNull(entwurfStatus) {
      return entwurfStatus === null ? false : true;
    },

    getStatusTagType(entwurfStatus) {
      if (entwurfStatus === 'active') {
        return 'success';
      }
      if (
        entwurfStatus === 'requested' ||
        entwurfStatus === 'revisionRequested'
      ) {
        return 'warning';
      }
      if (entwurfStatus === 'rejected') {
        return 'danger';
      }
      if (entwurfStatus === 'releasePending') {
        return '';
      }
      if (entwurfStatus === 'inactive') {
        return 'info';
      }
      return '';
    },
  },
};
</script>

<style></style>
