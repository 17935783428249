import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const MandantListPage = () =>
  import('@/modules/mandant/components/mandant-list-page.vue');
const MandantFormPage = () =>
  import('@/modules/mandant/components/mandant-form-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'mandant',
        path: '/mandant',
        component: MandantListPage,
        meta: {
          auth: true,
          permission: Permissions.values.mandantNavigate,
        },
      },
      {
        name: 'mandantNew',
        path: '/mandant/new',
        component: MandantFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.mandantNavigate,
        },
      },
      {
        name: 'mandantEdit',
        path: '/mandant/:id/edit',
        component: MandantFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.mandantEdit,
        },
        props: true,
      },
    ],
  },
];
