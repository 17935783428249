import kostenstelleListStore from '@/modules/hauptkostenstelle/hauptkostenstelle-list-store';
import kostenstelleFormStore from '@/modules/hauptkostenstelle/hauptkostenstelle-form-store';
import kostenstelleDestroyStore from '@/modules/hauptkostenstelle/hauptkostenstelle-destroy-store';
import kostenstelleImporterStore from '@/modules/hauptkostenstelle/hauptkostenstelle-importer-store';

export default {
  namespaced: true,

  modules: {
    destroy: kostenstelleDestroyStore,
    form: kostenstelleFormStore,
    list: kostenstelleListStore,
    importer: kostenstelleImporterStore,
  },
};
