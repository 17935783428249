import authAxios from '@/shared/axios/auth-axios';

export class BestaetigungService {
  static async create(data) {
    const params = {
      data,
    };

    const response = await authAxios({
      url: '/bestaetigung',
      method: 'GET',
      responseType: 'blob', // important
      params: params,
    }).then(async (response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf',
      });
      return await new Response(blob).arrayBuffer();
    });
    return response;
  }
}
