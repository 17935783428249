import { MitarbeiterExportModel } from '@/modules/mitarbeiter/mitarbeiter-export-model';

const { fields } = MitarbeiterExportModel;

export default [
  fields.mitarbeiterNr,
  fields.anrede,
  fields.titel,
  fields.nachname,
  fields.vorname,
  fields.vollNameExport,
  fields.email,
  fields.strasse,
  fields.plz,
  fields.stadt,
  fields.svNummer,
  fields.geburtsdatum,
  fields.telefon,
  fields.beruf,
      fields.funktion,
  fields.vertragsart,
  fields.iban,
  fields.bic,
  fields.eintrittsdatum,
  fields.austrittsdatum,
  fields.dienstjahre,
  fields.dienstjahreInt,
  fields.gehaltProJahr,
  fields.uBahn,
  fields.mandant,
  fields.kostenstelle,
  fields.hauptkostenstelle,
  fields.kostentraeger,
  fields.prozent,
  fields.von,
  fields.bis,
  fields.wochenstunden,
  fields.brutto,
  fields.gesamtkosten,
  fields.fakultaet,
  fields.verwendungKostenstelle,
  fields.einstufung,
  fields.saPers,
  fields.sa_sex,
  fields.saStaat,
  fields.sa_ausb,
  fields.sa_taet1,
  fields.sa_taet2,
  fields.saFte,
  fields.sa_verw,
  fields.sa_fkt,
  fields.saStudienrichtung,
];
