import knowledgeBaseListStore from '@/modules/knowledge-base/knowledge-base-list-store';
import knowledgeBaseFormStore from '@/modules/knowledge-base/knowledge-base-form-store';
import knowledgeBaseDestroyStore from '@/modules/knowledge-base/knowledge-base-destroy-store';
import knowledgeBaseViewStore from '@/modules/knowledge-base/knowledge-base-view-store';

export default {
  namespaced: true,

  modules: {
    destroy: knowledgeBaseDestroyStore,
    form: knowledgeBaseFormStore,
    list: knowledgeBaseListStore,
    view: knowledgeBaseViewStore,
  },
};
