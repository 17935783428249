import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class MandantPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.mandantRead,
    );
    this.mandantAutocomplete = permissionChecker.match(
      Permissions.values.mandantAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.mandantCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.mandantEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.mandantDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.mandantNavigate,
    );
  }
}
