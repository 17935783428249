<template>
  <div>
    <el-row :gutter="10">
      <el-col :md="12" :lg="8" :xl="6">
        <el-form
          :label-position="labelPosition"
          :label-width="labelWidthForm"
          :model="model"
          :rules="rules"
          @submit.native.prevent="doSubmit"
          class="form"
          ref="form"
        >
          <el-form-item
            :label="fields.email.label"
            :prop="fields.email.name"
            :required="fields.email.required"
          >
            <el-col>
              <el-input ref="focus" v-model="model[fields.email.name]" />
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.anrede.label"
            :prop="fields.anrede.name"
            :required="fields.anrede.required"
          >
            <el-col>
              <el-select
                placeholder="Bitte wählen Sie Anrede"
                v-model="model[fields.anrede.name]"
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.anrede.options"
                ></el-option>
              </el-select>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.academicTitle.label"
            :prop="fields.academicTitle.name"
            :required="fields.academicTitle.required"
          >
            <el-col>
              <el-input v-model="model[fields.academicTitle.name]" />
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.firstName.label"
            :prop="fields.firstName.name"
            :required="fields.firstName.required"
          >
            <el-col>
              <el-input v-model="model[fields.firstName.name]" />
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.lastName.label"
            :prop="fields.lastName.name"
            :required="fields.lastName.required"
          >
            <el-col>
              <el-input v-model="model[fields.lastName.name]" />
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.phoneNumber.label"
            :prop="fields.phoneNumber.name"
            :required="fields.phoneNumber.required"
          >
            <el-col>
              <el-input
                prefix-icon="el-icon-fa-plus"
                v-model="model[fields.phoneNumber.name]"
              />
            </el-col>
          </el-form-item>
          <el-form-item
            :label="fields.roles.label"
            :prop="fields.roles.name"
            :required="true"
          >
            <el-col>
              <el-select
                multiple
                placeholder
                v-model="model[fields.roles.name]"
              >
                <el-option
                  :key="option.value"
                  :label="option.label"
                  :value="option.value"
                  v-for="option in fields.roles.options"
                ></el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item
            :label="fields.password.label"
            :prop="fields.password.name"
            :required="fields.password.required"
          >
            <el-col>
              <el-input v-model="model[fields.password.name]" />
            </el-col>
          </el-form-item>
          <el-form-item>
            <div class="form-buttons">
              <el-button
                :disabled="saveLoading"
                @click="doSubmit"
                icon="el-icon-fa-floppy-o"
                type="primary"
              >
                <app-i18n code="common.save"></app-i18n>
              </el-button>

              <el-button
                :disabled="saveLoading"
                @click="doCancel"
                icon="el-icon-fa-close"
              >
                <app-i18n code="common.cancel"></app-i18n>
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { UserModel } from '@/modules/user/user-model';
import { i18n } from '@/i18n';

const { fields } = UserModel;
const formSchema = new FormSchema([
  fields.email,
  fields.anrede,
  fields.academicTitle,
  fields.firstName,
  fields.lastName,
  fields.phoneNumber,
  fields.roles,
  fields.password,
  fields.updatedAt,
]);

export default {
  name: 'app-user-new-form',

  props: ['saveLoading'],

  data() {
    return {
      rules: formSchema.rules(),
      model: {},
    };
  },

  async created() {
    this.model = this.formSchema.initialValues();
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
    }),

    formSchema() {
      return formSchema;
    },

    fields() {
      return fields;
    },
  },

  methods: {
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = this.formSchema.cast(this.model);

      if (values.email) {
        values.emails = [values.email];
        delete values.email;
      }

      return this.$emit('submit', {
        id,
        values,
      });
    },

    doCancel() {
      this.$emit('cancel');
    },

    i18n(code) {
      return i18n(code);
    },
  },
};
</script>
