<template>
  <div>
    <div v-if="!isBlank && hasPermissionToDownload">
      <div :key="item.id" v-for="item in value">
        <el-button @click="doOpenModal(item)" type="text">
          {{ item.name }}
        </el-button>
      </div>
    </div>
    <div v-if="dialogVisible">
      <app-pdf-view-modal
        :file="file"
        :visible="dialogVisible"
        @close="onModalClose"
        :hasPermissionToDownload="hasPermissionToDownload"
      >
      </app-pdf-view-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { truncate } from 'lodash';
import FilePermissionChecker from '@/security/filePermissionChecker';
import PdfPreview from '@/shared/pdf-view/components/pdf-view-modal.vue';

export default {
  name: 'app-list-item-file',
  props: ['value', 'permissions'],
  components: { [PdfPreview.name]: PdfPreview },
  data() {
    return { dialogVisible: false, file: null };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    isBlank() {
      return !this.value || !this.value.length;
    },
    hasPermissionToDownload() {
      return new FilePermissionChecker(this.currentUser).download(
        this.permissions,
      );
    },
  },
  methods: {
    truncate(name) {
      return truncate(name);
    },
    doOpenModal(item) {
      this.file = item;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
