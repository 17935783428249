import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class VerwendungKostenstellePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(
      Permissions.values.verwendungKostenstelleRead,
    );
    this.autocomplete = permissionChecker.match(
      Permissions.values.verwendungKostenstelleAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.verwendungKostenstelleCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.verwendungKostenstelleEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.verwendungKostenstelleDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.verwendungKostenstelleNavigate,
    );
  }
}
