import dateHelper from './dateHelper';
import calculationHelper from './calculationHelper';
import formatter from './formatter';

const plugin = {};

// eslint-disable-next-line
plugin.install = function(Vue, options) {
  Vue.DateHelper = dateHelper;
  Vue.CalculationHelper = calculationHelper;
  Vue.Formatter = formatter;
}; // install

export default plugin;
