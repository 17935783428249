export default class FilePermissionChecker {
  constructor(currentUser) {
    this.currentUser = currentUser;
    this.userRoles = currentUser ? currentUser.roles : [];
  }

  download(permission) {
    if (!permission) {
      return true;
    }

    return this.rolesMatchOneOf(permission.download);
  }

  upload(permission) {
    if (!permission) {
      return true;
    }

    return this.rolesMatchOneOf(permission.upload);
  }

  remove(permission) {
    if (!permission) {
      return true;
    }

    return this.rolesMatchOneOf(permission.remove);
  }

  rolesMatchOneOf(arg) {
    if (!this.userRoles) {
      return false;
    }

    if (!arg) {
      return false;
    }

    if (Array.isArray(arg)) {
      if (!arg.length) {
        return false;
      }

      return arg.some((role) => this.userRoles.includes(role));
    }

    return this.userRoles.includes(arg);
  }
}
