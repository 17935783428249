<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-on:close="handleTempFiles"
      width="40%"
    >
      <el-form
        label-position="top"
        :label-width="labelWidthForm"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
        v-loading="saveLoading"
      >
        <el-form-item
          :label="fields.type.label"
          :prop="fields.type.name"
          :required="fields.type.required"
        >
          <el-select
            placeholder="Bitte wählen Sie eine"
            v-model="model[fields.type.name]"
            @change="handleTypeChange"
            :disabled="isEditing"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.type.options"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="fields.kuendigungMitDatum.label"
          :prop="fields.kuendigungMitDatum.name"
          :required="fields.kuendigungMitDatum.required"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-date-picker
              @change="handleDateChange"
              :readonly="isEditing"
              :format="elementUiDateFormat"
              placeholder
              type="date"
              v-model="model[fields.kuendigungMitDatum.name]"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <el-form-item
          :label="fields.austrittsdatum.label"
          :prop="fields.austrittsdatum.name"
          :required="fields.austrittsdatum.required"
        >
          <el-col :lg="13" :md="16" :sm="24">
            <el-date-picker
              readonly
              :format="elementUiDateFormat"
              placeholder
              type="date"
              v-model="model[fields.austrittsdatum.name]"
            ></el-date-picker>
          </el-col>
        </el-form-item>

        <div v-if="isDGTermination && !isEditing">
          <el-form-item
            :label="fields.vorlageDienstgeber.label"
            :prop="fields.vorlageDienstgeber.name"
            :required="fields.vorlageDienstgeber.required"
          >
            <app-vorlage-dienstgeber-autocomplete-input
              :fetchFn="fields.vorlageDienstgeber.fetchFn"
              :mapperFn="fields.vorlageDienstgeber.mapperFn"
              mode="single"
              filter="kuendigung"
              v-model="model[fields.vorlageDienstgeber.name]"
            ></app-vorlage-dienstgeber-autocomplete-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="uploadMode">
              <app-i18n code="common.manualPreparation"></app-i18n>
            </el-checkbox>
          </el-form-item>
        </div>

        <el-form-item
          :label="fields.kuendigungEntwurf.label"
          :prop="fields.kuendigungEntwurf.name"
          :required="fields.kuendigungEntwurf.required"
          v-if="isDGTermination && uploadMode && !kuendigungUploaded"
        >
          <app-file-upload
            :max="fields.kuendigungEntwurf.max"
            :storage="fields.kuendigungEntwurf.storage"
            :formats="fields.kuendigungEntwurf.formats"
            :permissions="fields.kuendigungEntwurf.permissions"
            v-model="model[fields.kuendigungEntwurf.name]"
          ></app-file-upload>
        </el-form-item>

        <el-form-item
          :label="fields.unterschriebeneKuendigung.label"
          :prop="fields.unterschriebeneKuendigung.name"
          :required="fields.unterschriebeneKuendigung.required"
          v-if="(isEditing || !isDGTermination) && !kuendigungUploaded"
        >
          <app-file-upload
            :max="fields.unterschriebeneKuendigung.max"
            :storage="fields.unterschriebeneKuendigung.storage"
            :formats="fields.unterschriebeneKuendigung.formats"
            :permissions="fields.unterschriebeneKuendigung.permissions"
            v-model="model[fields.unterschriebeneKuendigung.name]"
          ></app-file-upload>
        </el-form-item>

        <el-form-item>
          <div class="form-buttons">
            <el-button
              :disabled="saveLoading"
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
              v-if="uploadMode || isEditing || !isDGTermination"
            >
              <app-i18n code="common.save"></app-i18n>
            </el-button>
            <el-button
              :disabled="saveLoading"
              @click="createDraft"
              icon="el-icon-fa-floppy-o"
              type="primary"
              v-if="!isEditing && isDGTermination"
            >
              <app-i18n code="common.prepare"></app-i18n>
            </el-button>

            <el-button
              :disabled="saveLoading"
              @click="doCancel"
              icon="el-icon-fa-close"
            >
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { BeendigungModel } from '@/modules/beendigung/beendigung-model';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { FormSchema } from '@/shared/form/form-schema';
import { i18n } from '@/i18n';
import { EventBus } from '@/eventBus';

const { fields } = BeendigungModel;
const formSchema = new FormSchema([
  fields.id,
  fields.kuendigungMitDatum,
  fields.austrittsdatum,
  fields.vorlageDienstgeber,
  fields.type,
  fields.kuendigungEntwurf,
  fields.unterschriebeneKuendigung,
]);

export default {
  name: 'app-beendigung-form-modal',
  props: ['record', 'mitarbeiterId', 'visible'],
  data() {
    return {
      model: null,
      rules: formSchema.rules(),
    };
  },
  created() {
    this.model = formSchema.initialValues(this.record || {});
    this.model.type = 'beendigung_kuendigung_durchDG';

    EventBus.$once('doSubmitBeendigungsentwurf', () => {
      this.doSubmit();
    });
    EventBus.$once('doSetBeendigungentwurf', () => {
      this.doSetKuendigungDraft();
    });
  },

  beforeDestroy() {
    EventBus.$off('doSubmitBeendigungsentwurf');
    EventBus.$off('doSetBeendigungentwurf');
  },
  beforeRouteLeave() {
    this.deleteTmpFiles();
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      loading: 'beendigung/loading',
      saveLoading: 'beendigung/saveLoading',
      entwurf: 'beendigung/entwurfFile',
      dienstjahr: 'mitarbeiter/form/dienstjahr',
      eintrittsdatum: 'mitarbeiter/form/eintrittsdatum',
      manualUploadMode: 'beendigung/manualUploadMode',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$store.dispatch('beendigung/doSetUploadMode', false);
          this.$emit('close');
        }
      },
    },
    uploadMode: {
      get() {
        return this.manualUploadMode;
      },
      set(value) {
        this.$store.dispatch('beendigung/doSetUploadMode', value);
      },
    },
    isEditing() {
      return !!this.record;
    },
    pendingFiles() {
      if (!this.model) return [];

      let unsaved = [];
      if (this.model.kuendigungEntwurf.length > 0) {
        unsaved.concat(this.model.kuendigungEntwurf.filter((f) => f.new));
      }
      if (this.model.unterschriebeneKuendigung.length > 0) {
        unsaved = unsaved.concat(
          this.model.unterschriebeneKuendigung.filter((f) => f.new),
        );
      }

      return unsaved;
    },
    isDGTermination() {
      return this.model.type === 'beendigung_kuendigung_durchDG';
    },
    fields() {
      return fields;
    },
    title() {
      return this.isEditing
        ? i18n('entities.beendigung.edit.title')
        : i18n('entities.beendigung.new.title');
    },
    kuendigungUploaded() {
      if (this.record) {
        return !!this.record.kuendigungStatus;
      } else return false;
    },
  },
  methods: {
    ...mapActions({
      doCreateDraft: 'beendigung/doCreateDraft',
    }),
    async handleTypeChange() {
      await this.handleTempFiles();
      this.model.kuendigungEntwurf = [];
      this.model.unterschriebeneKuendigung = [];

      this.model.kuendigungMitDatum = undefined;
      this.model.austrittsdatum = undefined;
      this.uploadMode = false;
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);
      values.mitarbeiter = this.mitarbeiterId;

      let action = 'create';
      if (this.isEditing) {
        action = 'update';
      }

      return this.$emit(action, {
        id,
        values,
      });
    },
    async createDraft() {
      try {
        if (!this.model.vorlageDienstgeber) {
          await this.$refs.form.validateField('vorlageDienstgeber');
          return;
        }
      } catch (error) {
        console.log(error);
        return;
      }
      const { id, ...values } = formSchema.cast(this.model);

      values.mitarbeiter = this.mitarbeiterId;
      await this.doCreateDraft({
        id,
        values,
      });

      this.$emit('openEntwurfDialog');
    },
    doSetKuendigungDraft() {
      this.model.kuendigungEntwurf = [this.entwurf];
    },

    handleDateChange(value) {
      if (this.model.type === 'beendigung_kuendigung_durchDG') {
        this.model.austrittsdatum = Vue.DateHelper.calculateAustrittsdatum(
          this.eintrittsdatum,
          this.dienstjahr,
          value,
        );
      } else this.model.austrittsdatum = value;
    },
    async doCancel() {
      this.handleTempFiles();
      this.dialogVisible = false;
    },

    async handleTempFiles() {
      if (this.pendingFiles.length > 0) {
        FileUploader.removePendingFiles(this.pendingFiles);
      }
    },
  },
};
</script>

<style></style>
