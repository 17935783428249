import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const EinstufungListPage = () =>
  import('@/modules/einstufung/components/einstufung-list-page.vue');
const EinstufungFormPage = () =>
  import('@/modules/einstufung/components/einstufung-form-page.vue');
const EinstufungImporterPage = () =>
  import('@/modules/einstufung/components/einstufung-importer-page.vue');
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'einstufung',
        path: '/einstufung',
        component: EinstufungListPage,
        meta: {
          auth: true,
          permission: Permissions.values.einstufungNavigate,
        },
      },
      {
        name: 'einstufungNew',
        path: '/einstufung/new',
        component: EinstufungFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.einstufungNavigate,
        },
      },
      {
        name: 'einstufungEdit',
        path: '/einstufung/:id/edit',
        component: EinstufungFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.einstufungEdit,
        },
        props: true,
      },
      {
        name: 'einstufungImporter',
        path: '/einstufung/import',
        component: EinstufungImporterPage,
        meta: {
          auth: true,
          permission: Permissions.values.einstufungImport,
        },
      },
    ],
  },
];
