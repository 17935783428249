import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const StatistikAustriaListPage = () =>
  import(
    '@/modules/statistik-austria/components/statistik-austria-list-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'statisticAustria',
        path: '/statistik-austria',
        component: StatistikAustriaListPage,
        meta: {
          auth: true,
          permission: Permissions.values.statistikAustriaRead,
        },
      },
    ],
  },
];
