<template>
  <div class="anstellung">
    <div style="padding-top: 10px">
      <el-col>
        <el-table
          :border="true"
          :data="rows"
          row-key="id"
          v-loading="loading"
          :height="tableHeight"
        >
          <el-table-column width="20">
            <template v-slot="scope">
              <el-badge is-dot :type="getStatusTagType(scope.row)"></el-badge>
            </template>
          </el-table-column>
          <el-table-column
            :label="fields.nummer.label"
            :prop="fields.nummer.name"
            width="200"
          >
            <template v-slot="scope">
              {{ presenter(scope.row, 'nummer') }}
            </template>
          </el-table-column>
          <el-table-column
            :label="fields.zahlung.label"
            :prop="fields.zahlung.name"
            width="200"
          >
            <template v-slot="scope">
              {{ presenter(scope.row, 'zahlung') }}
            </template>
          </el-table-column>
          <el-table-column
            :label="fields.von.label"
            :prop="fields.von.name"
            width="200"
          >
            <template v-slot="scope">
              {{ presenter(scope.row, 'von') }}
            </template>
          </el-table-column>
          <el-table-column
            :label="fields.bis.label"
            :prop="fields.bis.name"
            width="200"
          >
            <template v-slot="scope">
              {{ presenter(scope.row, 'bis') }}
            </template>
          </el-table-column>
          <el-table-column label="Aktion" width="140">
            <template v-slot="scope">
              <div>
                <!--   <el-tooltip
                  :content="extendButtonTooltip"
                  :disabled="!extendButtonTooltip"
                  v-if="hasPermissionToEdit"
                  style="margin-right: 5px"
                >
                  <span>
                    <el-button
                      @click="$emit('doExtend', scope.row)"
                      type="success"
                      size="small"
                      circle
                      plain
                      icon="el-icon-refresh-right"
                      v-if="hasPermissionToEdit"
                    ></el-button>
                  </span>
                </el-tooltip> -->
                <el-tooltip
                  :content="openButtonTooltip"
                  :disabled="!openButtonTooltip"
                  v-if="hasPermissionToRead"
                  style="margin-right: 5px"
                >
                  <span>
                    <el-button
                      @click="$emit('doOpen', scope.row)"
                      type="warning"
                      size="small"
                      circle
                      plain
                      icon="el-icon-view"
                      v-if="hasPermissionToRead"
                    ></el-button>
                  </span>
                </el-tooltip>
                <el-tooltip
                  :content="editButtonTooltip"
                  :disabled="!editButtonTooltip"
                  v-if="hasPermissionToEdit"
                  style="margin-right: 5px"
                >
                  <span>
                    <el-button
                      @click="$emit('doEdit', scope.row)"
                      type="warning"
                      size="small"
                      circle
                      plain
                      icon="el-icon-edit"
                      v-if="hasPermissionToEdit"
                    ></el-button>
                  </span>
                </el-tooltip>
                <el-tooltip
                  :content="deleteButtonTooltip"
                  :disabled="!deleteButtonTooltip"
                  v-if="hasPermissionToDestroy"
                >
                  <span>
                    <el-button
                      @click="$emit('doDelete', scope.row.id)"
                      type="danger"
                      size="small"
                      circle
                      plain
                      icon="el-icon-delete"
                      v-if="hasPermissionToDestroy"
                    ></el-button>
                  </span>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import { JahreskarteModel } from '@/modules/jahreskarte/jahreskarte-model';
import { JahreskartePermissions } from '@/modules/jahreskarte/jahreskarte-permissions';
const { fields } = JahreskarteModel;

export default {
  name: 'app-jahreskarte-list-table',

  computed: {
    ...mapGetters({
      rows: 'jahreskarte/list/rows',
      count: 'jahreskarte/list/count',
      loading: 'jahreskarte/list/loading',
      mitarbeiter: 'mitarbeiter/form/id',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),
    fields() {
      return fields;
    },
    hasPermissionToRead() {
      return new JahreskartePermissions(this.currentUser).read;
    },
    hasPermissionToEdit() {
      return new JahreskartePermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new JahreskartePermissions(this.currentUser).destroy;
    },
    openButtonTooltip() {
      return i18n('common.view');
    },
    editButtonTooltip() {
      return i18n('common.edit');
    },
    extendButtonTooltip() {
      return i18n('common.extend');
    },
    deleteButtonTooltip() {
      return i18n('common.destroy');
    },
    tableHeight() {
      return this.count > 3 ? '200px' : '100%';
    },
  },

  methods: {
    presenter(row, fieldName) {
      return JahreskarteModel.presenter(row, fieldName);
    },
    getStatusTagType(row) {
      if (row.status === 'active') {
        return 'success';
      }
      if (row.status === 'inactive') {
        return 'danger';
      }
      return 'info';
    },
    i18n(code) {
      return i18n(code);
    },
  },
};
</script>

<style></style>
