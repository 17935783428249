import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class VertragsergaenzungPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(
      Permissions.values.vertragsergaenzungRead,
    );
    this.autocomplete = permissionChecker.match(
      Permissions.values.vertragsergaenzungAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.vertragsergaenzungCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.vertragsergaenzungEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.vertragsergaenzungDestroy,
    );
    this.setToInactive = permissionChecker.match(
      Permissions.values.vertragsergaenzungSetToInactive,
    );
    this.sendEmailToEmployee = permissionChecker.match(
      Permissions.values.vertragsergaenzungSendEmailToEmployee,
    );
    this.sendEmailToEmployeeMultipleTimes = permissionChecker.match(
      Permissions.values.vertragsergaenzungSendEmailToEmployeeMultipleTimes,
    );
  }
}
