import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class BeendigungPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(Permissions.values.beendigungRead);
    this.create = permissionChecker.match(Permissions.values.beendigungCreate);
    this.edit = permissionChecker.match(Permissions.values.beendigungEdit);
    this.destroy = permissionChecker.match(
      Permissions.values.beendigungDestroy,
    );
    this.sendEmailToEmployee = permissionChecker.match(
      Permissions.values.beendigungSendEmailToEmployee,
    );
    this.sendEmailToEmployeeMultipleTimes = permissionChecker.match(
      Permissions.values.sendEmailToEmployeeMultipleTimes,
    );
  }
}
