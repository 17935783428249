import authAxios from '@/shared/axios/auth-axios';

export class KnowledgeBaseService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/knowledge-base/${id}`,
      body,
    );

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/knowledge-base`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(
      `/knowledge-base`,
      body,
    );

    return response.data;
  }


  static async find(id) {
    const response = await authAxios.get(`/knowledge-base/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/knowledge-base`, {
      params,
    });

    return response.data;
  }
}
