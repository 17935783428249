import * as yup from 'yup';
import moment from 'moment';
import { i18n } from '@/i18n';
import GenericField from '@/shared/fields/generic-field';

export default class DateField extends GenericField {
  constructor(name, label, { required = false, format = undefined } = {}) {
    super(name, label);

    this.required = required;
    this.format = format;
  }

  isDateValid = (value) => {
    const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/; // Regex to match DD.YY.MMMM format
    const match = regex.exec(value);

    if (!match) return false;

    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10);
    const year = parseInt(match[3], 10);

    // Check if the date is valid
    const date = new Date(year, month - 1, day);
    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  };

  forPresenter(value) {
    return value ? moment(value).format('DD.MM.YYYY') : null;
  }

  forFilterCast() {
    return yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .transform((value) =>
        value ? moment(value).format('YYYY-MM-DD') : null,
      );
  }

  forFormRules() {
    const output = [];

    if (this.required) {
      output.push({
        required: true,
        message: i18n('validation.mixed.required').replace(
          '${path}',
          this.label,
        ),
      });
    }

    return output;
  }

  forFilterRules() {
    return undefined;
  }

  forFormInitialValue(value) {
    return value ? moment(value).format('YYYY-MM-DD') : null;
  }

  forFormCast() {
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .transform((value) =>
        value ? moment(value).format('YYYY-MM-DD') : null,
      );

    return yupChain;
  }

  forExport() {
    return yup
      .mixed()
      .label(this.label)
      .transform((value) => this.forPresenter(value));
  }

  forImport() {
    let yupChain = yup
      .mixed()
      .nullable(true)
      .label(this.label)
      .test('is-date', i18n('validation.mixed.default'), (value) => {
        if (!value || value == undefined) {
          return true;
        }
        /* 
        if (!moment(value).isValid()) {
          return moment(value, 'YYYY-MM-DD').isValid();
        } */
        /* return moment(value).isValid(); */
        return true;
      })
      .transform((value) => {
        /*  let parsedDate = moment(value, 'dd.MM.yyyy');
        if (!moment(value).isValid()) {
          parsedDate = moment(value);
        } */
        //const formatted = parsedDate.format('yyyy-MM-dd');
        return moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
      });

    if (this.required) {
      yupChain = yupChain.required();
    }

    return yupChain;
  }
}
