<template>
  <div class="el-input el-input-group">
    <el-select
      v-if="showSelectElement"
      :disabled="disabled"
      :loading="loading"
      :remote-method="handleSearch"
      :value="value"
      @change="onChange"
      :clearable="clearable"
      default-first-option
      filterable
      multiple
      placeholder
      remote
      reserve-keyword
      value-key="id"
    >
      <el-option
        :key="initialOption.id"
        :label="initialOption.label"
        :value="initialOption"
        v-for="initialOption of initialOptions"
      ></el-option>
      <el-option
        :key="record.id"
        :label="record.label"
        :value="record"
        v-for="record in dataSource"
      ></el-option>
    </el-select>
    <div class="el-input-group__append" v-if="allowCreate">
      <el-button
        size="mini"
        icon="el-icon-plus"
        v-on:click="$emit('doOpenModal')"
      ></el-button>
    </div>
  </div>
</template>

<script>
import { debounce, isString } from 'lodash';

const AUTOCOMPLETE_SERVER_FETCH_SIZE = 50;

export default {
  name: 'app-autocomplete-many-input',

  props: {
    value: {
      type: Array,
    },
    fetchFn: {
      type: Function,
    },
    inMemoryFilter: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: String,
      default: '',
    },
    filter: {
      type: String,
      default: '',
    },
    filterServerDataSourceBy: {
      type: String,
    },
    showSelectElement: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
      fullDataSource: [],
      serverSideDataSource: [],
      inMemoryDataSource: [],
      currentQuery: 'NOT_INITIALIZED',
      debouncedSearch: () => {},
    };
  },

  mounted() {
    this.debouncedSearch = debounce(this.handleSearch.bind(this), 1000);

    // Fetch first results
    this.handleSearch('');
  },

  watch: {
    parentId() {
      // when the parentId prop changes, this function will be fired.
      this.handleServerSearch('');
    },

    filter() {
      // when filter prop changes, this function will be fired.
      this.handleServerSearch('');
    },

    filterServerDataSourceBy(id) {
      this.serverSideDataSource = this.serverSideDataSource.filter(
        (f) => f.id != id,
      );
    },
  },

  computed: {
    initialOptions() {
      if (!this.value || !this.value.length) {
        return [];
      }

      return this.value.filter(
        (currentValue) =>
          !this.dataSource.map((item) => item.id).includes(currentValue.id),
      );
    },

    dataSource() {
      if (this.inMemoryFilter) {
        return this.inMemoryDataSource;
      }

      return this.serverSideDataSource;
    },

    parentId_Internal() {
      return this.parentId;
    },
  },

  methods: {
    onChange(value) {
      this.$emit('input', value || []);
    },

    async handleSearch(value) {
      if (!isString(value)) {
        return;
      }

      if (this.inMemoryFilter) {
        return this.handleInMemorySearch(value);
      }

      return this.handleServerSearch(value, this.filter);
    },

    async handleInMemorySearch(value) {
      if (!this.fullDataSource || !this.fullDataSource.length) {
        await this.fetchAllResults();
      }

      this.inMemoryDataSource = this.fullDataSource.filter((item) =>
        String(item.label || '')
          .toLowerCase()
          .includes(String(value || '').toLowerCase()),
      );

      this.loading = false;
    },

    async fetchAllResults(value, filter) {
      this.loading = true;
      if (this.parentId_Internal == null) {
        this.parentId_Internal = '';
      }
      try {
        const fullDataSource = await this.fetchFn(
          AUTOCOMPLETE_SERVER_FETCH_SIZE,
          value,
          this.parentId_Internal,
          filter,
        );
        this.fullDataSource = fullDataSource;
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.fullDataSource = [];
        this.loading = false;
      }
    },

    async handleServerSearch(value, filter) {
      if (this.parentId_Internal == null) {
        this.parentId_Internal = '';
      }

      if (
        value === this.currentQuery &&
        this.parentId_Internal === '' &&
        filter === ''
      ) {
        return;
      }

      this.currentQuery = value;
      this.loading = true;

      try {
        const serverSideDataSource = await this.fetchFn(
          AUTOCOMPLETE_SERVER_FETCH_SIZE,
          value,
          this.parentId_Internal,
          filter,
        );

        if (this.currentQuery === value) {
          this.serverSideDataSource = serverSideDataSource;
          this.loading = false;
        }
      } catch (error) {
        console.error(error);

        if (this.currentQuery === value) {
          this.serverSideDataSource = [];
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style></style>
