import { StatistikAustriaModel } from '@/modules/statistik-austria/statistik-austria-model';

const { fields } = StatistikAustriaModel;

export default [
  fields.name,
  fields.mitarbeiterNr,
  fields.sex,
  fields.gebj,
  fields.staat,
  fields.ausb,
  fields.taet1,
  fields.taet2,
  fields.fte,
  fields.verw,
  fields.fkt,
];
