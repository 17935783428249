export default {
  namespaced: true,
  state: {
    gesamtbruttoGrundgehalt: null,
    gesamtbruttoGehaltszulage: null,
    grundgehaltTable: [],
    gehaltszulageTable: [],
    anstellung: [],
    grundgehaltSet: false,
    gehaltszulageSet: false,
    recalculateGesamtkosten: false,
  },

  getters: {
    gesamtbruttoGrundgehalt: (state) => state.gesamtbruttoGrundgehalt,
    gesamtbruttoGehaltszulage: (state) => state.gesamtbruttoGehaltszulage,
    recalculateGesamtkosten: (state) =>
      state.grundgehaltSet && state.gehaltszulageSet ? true : false,
  },
  mutations: {
    RESET(state) {
      state.gesamtbruttoGrundgehalt = null;
      state.gesamtbruttoGehaltszulage = null;
      state.grundgehaltTable = [];
      state.gehaltszulageTable = [];
      state.anstellung = [];
      state.recalculateGesamtkosten = false;
      state.grundgehaltSet = false;
      state.gehaltszulageSet = false;
    },
    SET_GEHALTSZULAGE_GESAMTBRUTTO(state, payload) {
      state.gesamtbruttoGehaltszulage = payload;
      state.gehaltszulageSet = true;
    },
    SET_GESAMTBRUTTO_GRUNDGEHALT(state, payload) {
      state.gesamtbruttoGrundgehalt = payload;
      state.grundgehaltSet = true;
    },
    SET_ANSTELLUNG(state, payload) {
      state.anstellung = payload;
    },
    RESET_RECALCULATE_SET_STATES(state) {
      state.grundgehaltSet = false;
      state.gehaltszulageSet = false;
    },
  },
  actions: {
    doReset({ commit }) {
      commit('RESET');
    },
    async doSetGesamtbruttoGrundgehalt({ commit }, value) {
      commit('SET_GESAMTBRUTTO_GRUNDGEHALT', value);
    },
    async doSetGehaltszulageGesamtbrutto({ commit }, value) {
      commit('SET_GEHALTSZULAGE_GESAMTBRUTTO', value);
    },
    async doSetAnstellung({ commit }, value) {
      commit('SET_ANSTELLUNG', value);
    },

    async doResetRecalculateSet({ commit }) {
      commit('RESET_RECALCULATE_SET_STATES');
    },
  },
};
