import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class KostenstellePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(Permissions.values.kostenstelleRead);
    this.kostenstelleAutocomplete = permissionChecker.match(
      Permissions.values.kostenstelleAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.kostenstelleCreate,
    );
    this.edit = permissionChecker.match(Permissions.values.kostenstelleEdit);
    this.destroy = permissionChecker.match(
      Permissions.values.kostenstelleDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.kostenstelleNavigate,
    );
    this.import = permissionChecker.match(
      Permissions.values.kostenstelleImport,
    );
  }
}
