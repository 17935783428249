<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <app-user-new-form
        :modal="true"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
      />
    </el-dialog>
  </div>
</template>

<script>
import UserNewForm from '@/modules/user/components/user-new-form';
import { UserService } from '@/modules/user/user-service';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';

export default {
  name: 'app-user-new-form-modal',

  props: ['visible'],

  components: {
    [UserNewForm.name]: UserNewForm,
  },

  data() {
    return {
      record: null,
      saveLoading: false,
    };
  },

  computed: {
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    title() {
      return i18n('user.new.titleModal');
    },
  },

  methods: {
    async doSubmit(payload) {
      try {
        this.saveLoading = true;
        await UserService.create(payload.values);
        const { rows } = await UserService.fetchUsers(
          {
            email: payload.values.emails[0],
          },
          null,
          1,
          0,
        );

        this.$emit('success', rows[0]);
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },

    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
