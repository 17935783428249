<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="40%"
    >
      <app-staatsbuergerschaft-form
        :modal="true"
        :record="record"
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
      />
    </el-dialog>
  </div>
</template>

<script>
import StaatsbuergerschaftForm from '@/modules/staatsbuergerschaft/components/staatsbuergerschaft-form.vue';
import { StaatsbuergerschaftService } from '@/modules/staatsbuergerschaft/staatsbuergerschaft-service';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';

export default {
  name: 'app-staatsbuergerschaft-form-modal',

  props: ['visible'],

  components: {
    'app-staatsbuergerschaft-form': StaatsbuergerschaftForm,
  },

  data() {
    return {
      record: null,
      saveLoading: false,
    };
  },

  computed: {
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    title() {
      return i18n('entities.staatsbuergerschaft.new.title');
    },
  },

  methods: {
    async doSubmit(payload) {
      try {
        this.saveLoading = true;
        const { id } = await StaatsbuergerschaftService.create(payload.values);
        const record = await StaatsbuergerschaftService.find(id);
        this.$emit('success', record);
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.saveLoading = false;
      }
    },

    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
