import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class StaatsbuergerschaftPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(
      Permissions.values.staatsbuergerschaftRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.staatsbuergerschaftImport,
    );
    this.staatsbuergerschaftAutocomplete = permissionChecker.match(
      Permissions.values.staatsbuergerschaftAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.staatsbuergerschaftCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.staatsbuergerschaftEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.staatsbuergerschaftDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.staatsbuergerschaftNavigate,
    );
  }
}
