import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class VertragsartPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.vertragsartRead,
    );
    this.vertragsartAutocomplete = permissionChecker.match(
      Permissions.values.vertragsartAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.vertragsartCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.vertragsartEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.vertragsartDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.vertragsartNavigate,
    );
  }
}
