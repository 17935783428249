import authAxios from '@/shared/axios/auth-axios';

export class UserService {
  static async enable(ids) {
    return this._changeStatus(ids, 'enabled');
  }

  static async disable(ids) {
    return this._changeStatus(ids, 'disabled');
  }

  static async _changeStatus(ids, status) {
    const body = {
      ids,
      status: status,
    };

    const response = await authAxios.put('/user/status', body);

    return response.data;
  }

  static async changePassword(data) {
    const body = {
      data,
    };
    const response = await authAxios.put('/user/change-password', body);
    return response.data;
  }

  static async edit(data) {
    const body = {
      data,
    };
    const response = await authAxios.put('/user', body);
    return response.data;
  }

  static async destroy(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/user`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post('/user', body);
    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: {
        ...values,
      },
      importHash,
    };

    const response = await authAxios.post('/user/import', body);
    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/user/${id}`);
    return response.data;
  }

  static async fetchUsers(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/user`, {
      params,
    });
    return response.data;
  }

  static async fetchRoles(filter, orderBy) {
    const params = {
      filter,
      orderBy,
    };

    const response = await authAxios.get(`/user/role`, {
      params,
    });

    return {
      rows: response.data,
      count: response.data.length,
    };
  }

  static async fetchUserAutocomplete(limit, query) {
    const params = {
      limit,
      query,
    };

    const response = await authAxios.get(`/user/user/autocomplete`, {
      params,
    });
    return response.data;
  }
}
