import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import { GenericModel } from '@/shared/model/generic-model';
import { StatistikAustriaAuswertungField } from '@/modules/statistik-austria/statistik-austria-auswertung-field';
import StringField from '@/shared/fields/string-field';
import { StaatsbuergerschaftField } from '@/modules/staatsbuergerschaft/staatsbuergerschaft-field';
import DateField from '@/shared/fields/date-field';
import BooleanField from '@/shared/fields/boolean-field';

function label(name) {
  return i18n(`entities.statistikAustria.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name')),
  mitarbeiterNr: new StringField('mitarbeiterNr', label('mitarbeiterNr'), {
    required: false,
    max: 50,
  }),
  pers: StatistikAustriaAuswertungField.relationToOne('pers', label('pers'), {
    exportField: 'nummer',
  }),
  gebj: new StringField('gebj', label('gebj')),
  staat: StaatsbuergerschaftField.relationToOne('staat', label('staat'), {
    exportField: 'nationscode',
    presenterField: 'nationscode',
  }),
  ausb: StatistikAustriaAuswertungField.relationToOne('ausb', label('ausb'), {
    exportField: 'nummer',
  }),
  sex: StatistikAustriaAuswertungField.relationToOne('sex', label('sex'), {
    exportField: 'nummer',
  }),
  taet1: StatistikAustriaAuswertungField.relationToOne(
    'taet1',
    label('taet1'),
    { exportField: 'nummer' },
  ),
  taet2: StatistikAustriaAuswertungField.relationToOne(
    'taet2',
    label('taet2'),
    { exportField: 'nummer' },
  ),
  fte: new StringField('fte', label('fte')),
  verw: StatistikAustriaAuswertungField.relationToOne('verw', label('verw'), {
    exportField: 'nummer',
  }),
  fkt: StatistikAustriaAuswertungField.relationToOne('fkt', label('fkt'), {
    exportField: 'nummer',
  }),
  year: new DateField('year', label('year')),
  plan: new BooleanField('plan', label('plan')),
};

export class StatistikAustriaModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
