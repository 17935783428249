import authAxios from '@/shared/axios/auth-axios';

export class ConversionService {
  static async getPdf(id) {
    const params = {
      id,
    };

    const response = await authAxios({
      url: '/document/pdf',
      method: 'GET',
      responseType: 'blob', // important
      params: params,
    }).then(async (response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf',
      });
      return await new Response(blob).arrayBuffer();
    });
    return response;
  }

  static async getTempPdf(privateUrl) {
    const params = {
      privateUrl,
    };

    const response = await authAxios({
      url: '/document/temp-pdf',
      method: 'GET',
      responseType: 'blob', // important
      params: params,
    }).then(async (response) => {
      const blob = new Blob([response.data], {
        type: 'application/pdf',
      });
      return await new Response(blob).arrayBuffer();
    });
    return response;
  }
}
