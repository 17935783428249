import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const VorlageDienstgeberListPage = () =>
  import(
    '@/modules/vorlage-dienstgeber/components/vorlage-dienstgeber-list-page.vue'
  );
const VorlageDienstgeberFormPage = () =>
  import(
    '@/modules/vorlage-dienstgeber/components/vorlage-dienstgeber-form-page.vue'
  );

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'vorlageDienstgeber',
        path: '/vorlage-dienstgeber',
        component: VorlageDienstgeberListPage,
        meta: {
          auth: true,
          permission: Permissions.values.vorlageDienstgeberNavigate,
        },
      },
      {
        name: 'vorlageDienstgeberNew',
        path: '/vorlage-dienstgeber/new',
        component: VorlageDienstgeberFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.vorlageDienstgeberCreate,
        },
      },
      {
        name: 'vorlageDienstgeberEdit',
        path: '/vorlage-dienstgeber/:id/edit',
        component: VorlageDienstgeberFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.vorlageDienstgeberEdit,
        },
        props: true,
      },
    ],
  },
];
