import authAxios from '@/shared/axios/auth-axios';

export class BerufService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/beruf/${id}`,
      body,
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/beruf`, body);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/beruf`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(
    limit,
    query,
    parentId,
    filter,
  ) {
    const params = {
      limit,
      query,
      parentId,
      filter,
    };

    const response = await authAxios.get(
      `/beruf/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }
}
