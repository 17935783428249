import importerStore from '@/shared/importer/importer-store-mitarbeiter';
import { MitarbeiterService } from '@/modules/mitarbeiter/mitarbeiter-service';
import mitarbeiterImportFields from '@/modules/mitarbeiter/mitarbeiter-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  MitarbeiterService.importSalaryAdjustment,
  mitarbeiterImportFields,
  i18n('user.importer.fileName'),
  i18n('user.importer.hint'),
  'salary',
);
