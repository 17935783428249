import fakultaetListStore from '@/modules/fakultaet/fakultaet-list-store';
import fakultaetFormStore from '@/modules/fakultaet/fakultaet-form-store';
import fakultaetDestroyStore from '@/modules/fakultaet/fakultaet-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: fakultaetDestroyStore,
    form: fakultaetFormStore,
    list: fakultaetListStore,
  },
};
