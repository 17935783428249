<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="20%"
    >
      <el-form
        label-position="top"
        :label-width="labelWidthForm"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
      >
        <el-form-item
          :label="fields.karenzvertretungType.label"
          :prop="fields.karenzvertretungType.name"
          :required="fields.karenzvertretungType.required"
        >
          <el-select
            placeholder="Bitte wählen Sie eine"
            v-model="model[fields.karenzvertretungType.name]"
            @change="handleTypeChange"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.karenzvertretungType.options"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          :label="fields.karenzvertretungMitarbeiter.label"
          :prop="fields.karenzvertretungMitarbeiter.name"
          :required="fields.karenzvertretungMitarbeiter.required"
          v-if="model[fields.karenzvertretungType.name] === 'karenzvertretung'"
        >
          <app-mitarbeiter-autocomplete-input
            :fetchFn="fields.karenzvertretungMitarbeiter.fetchFn"
            :mapperFn="fields.karenzvertretungMitarbeiter.mapperFn"
            mode="single"
            v-model="model[fields.karenzvertretungMitarbeiter.name]"
          ></app-mitarbeiter-autocomplete-input>
        </el-form-item>
        <el-form-item
          :label="fields.karenzvertretungFuerMitarbeiter.label"
          :prop="fields.karenzvertretungFuerMitarbeiter.name"
          :required="fields.karenzvertretungFuerMitarbeiter.required"
          v-if="
            model[fields.karenzvertretungType.name] === 'karenzvertretungFuer'
          "
        >
          <app-mitarbeiter-autocomplete-input
            :fetchFn="fields.karenzvertretungFuerMitarbeiter.fetchFn"
            :mapperFn="fields.karenzvertretungFuerMitarbeiter.mapperFn"
            mode="single"
            v-model="model[fields.karenzvertretungFuerMitarbeiter.name]"
          ></app-mitarbeiter-autocomplete-input>
        </el-form-item>
        <el-form-item>
          <div class="form-buttons">
            <el-button
              :disabled="saveLoading"
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="common.save"></app-i18n>
            </el-button>

            <el-button
              :disabled="saveLoading"
              @click="doCancel"
              icon="el-icon-fa-close"
            >
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { MitarbeiterAnstellungModel } from '@/modules/mitarbeiter/mitarbeiter-anstellung-model';

const { fields } = MitarbeiterAnstellungModel;
const formSchema = new FormSchema([
  fields.karenzvertretungMitarbeiter,
  fields.karenzvertretungFuerMitarbeiter,
]);

export default {
  name: 'app-mitarbeiter-anstellung-karenzvertretung-form-modal',

  props: ['anstellung', 'visible'],

  data() {
    return {
      rules: formSchema.rules(),
      model: {},
      saveLoading: false,
    };
  },
  created() {
    this.model = formSchema.initialValues(this.anstellung || {});
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    fields() {
      return fields;
    },

    title() {
      return 'Karenzvertretung';
    },
  },

  methods: {
    async doSubmit() {
      await this.validateForm();
      return this.$emit('doSubmitKarenzvertretung', this.model);
    },

    async validateForm() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
    },

    async handleTypeChange() {
      this.model.karenzvertretungMitarbeiter = null;
      this.model.karenzvertretungFuerMitarbeiter = null;
    },

    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
