<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-on:close="handleTempFiles"
      width="40%"
    >
      <el-form
        label-position="top"
        :label-width="labelWidthForm"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
        v-loading="saveLoading"
      >
        <el-form-item
          :label="fields.vertragsaenderungStatus.label"
          :prop="fields.vertragsaenderungStatus.name"
          :required="fields.vertragsaenderungStatus.required"
        >
          <el-select
            placeholder
            v-model="model[fields.vertragsaenderungStatus.name]"
          >
            <el-option :value="undefined">--</el-option>
            <el-option
              :key="option.id"
              :label="option.label"
              :value="option.id"
              v-for="option in fields.vertragsaenderungStatus.options"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="fields.vertrag.label" :prop="fields.vertrag.name">
          <app-autocomplete-one-input
            :filter="mitarbeiterId"
            :fetchFn="fields.vertrag.fetchFn"
            v-model="model[fields.vertrag.name]"
          ></app-autocomplete-one-input>
        </el-form-item>
        <el-form-item
          :label="fields.unterschriebenerVertragsaenderung.label"
          :prop="fields.unterschriebenerVertragsaenderung.name"
          :required="fields.unterschriebenerVertragsaenderung.required"
        >
          <app-file-upload
            :max="fields.unterschriebenerVertragsaenderung.max"
            :storage="fields.unterschriebenerVertragsaenderung.storage"
            :formats="fields.unterschriebenerVertragsaenderung.formats"
            :permissions="fields.unterschriebenerVertragsaenderung.permissions"
            v-model="model[fields.unterschriebenerVertragsaenderung.name]"
          ></app-file-upload>
        </el-form-item>
        <el-form-item>
          <div class="form-buttons">
            <el-button
              :disabled="saveLoading"
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="common.save"></app-i18n>
            </el-button>
            <el-button
              :disabled="saveLoading"
              @click="doCancel"
              icon="el-icon-fa-close"
            >
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { VertragsaenderungModel } from '@/modules/vertragsaenderung/vertragsaenderung-model';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { FormSchema } from '@/shared/form/form-schema';
import { i18n } from '@/i18n';
const { fields } = VertragsaenderungModel;
const formSchema = new FormSchema([
  fields.id,
  fields.vertragsaenderungStatus,
  fields.unterschriebenerVertragsaenderung,
  fields.vertrag,
]);
export default {
  name: 'app-vertragsaenderung-form-upload-modal',
  props: ['record', 'mitarbeiterId', 'visible'],
  data() {
    return {
      model: null,
      rules: formSchema.rules(),
    };
  },
  created() {
    this.model = formSchema.initialValues(this.record || {});
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      loading: 'vertragsaenderung/loading',
      saveLoading: 'vertragsaenderung/saveLoading',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    pendingFiles() {
      if (!this.model) return [];
      let unsaved = [];
      const unterschriebenerVertragsaenderung =
        this.model.unterschriebenerVertragsaenderung.filter((f) => f.new);
      unsaved = unsaved.concat(unterschriebenerVertragsaenderung);
      return unsaved;
    },
    fields() {
      return fields;
    },
    title() {
      return i18n('entities.vertragsaenderung.uploadManual.title');
    },
  },
  methods: {
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      const { id, ...values } = formSchema.cast(this.model);
      values.mitarbeiter = this.mitarbeiterId;
      return this.$emit('create', {
        id,
        values,
      });
    },
    doCancel() {
      this.handleTempFiles();
      this.dialogVisible = false;
    },
    handleTempFiles() {
      if (this.pendingFiles.length > 0) {
        FileUploader.removePendingFiles(this.pendingFiles);
      }
    },
  },
};
</script>
<style scoped>
* >>> th .el-checkbox {
  display: none !important;
}
</style>
