import vorlageDienstgeberListStore from '@/modules/vorlage-dienstgeber/vorlage-dienstgeber-list-store';
import vorlageDienstgeberFormStore from '@/modules/vorlage-dienstgeber/vorlage-dienstgeber-form-store';
import vorlageDienstgeberDestroyStore from '@/modules/vorlage-dienstgeber/vorlage-dienstgeber-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: vorlageDienstgeberDestroyStore,
    form: vorlageDienstgeberFormStore,
    list: vorlageDienstgeberListStore,
  },
};
