import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class TemplateTypePermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(
      Permissions.values.templateTypeRead,
    );
    this.templateTypeAutocomplete = permissionChecker.match(
      Permissions.values.templateTypeAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.templateTypeCreate,
    );
  }
}
