import authAxios from '@/shared/axios/auth-axios';
export class HauptkostenstelleService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(`/hauptkostenstelle/${id}`, body);
    return response.data;
  }
  static async updateStatus(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(
      `/hauptkostenstelle/status/${id}`,
      body,
    );
    return response.data;
  }
  static async destroyAll(ids) {
    const params = {
      ids,
    };
    const response = await authAxios.delete(`/hauptkostenstelle`, {
      params,
    });
    return response.data;
  }
  static async create(data) {
    const body = {
      data,
    };
    const response = await authAxios.post(`/hauptkostenstelle`, body);
    return response.data;
  }
  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };
    const response = await authAxios.post(`/hauptkostenstelle/import`, body);
    return response.data;
  }
  static async find(id) {
    const response = await authAxios.get(`/hauptkostenstelle/${id}`);
    return response.data;
  }
  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };
    const response = await authAxios.get(`/hauptkostenstelle`, {
      params,
    });
    return response.data;
  }
  static async listAutocomplete(limit, query, parentId) {
    const params = {
      limit,
      query,
      parentId,
    };
    const response = await authAxios.get(`/hauptkostenstelle/autocomplete`, {
      params,
    });
    return response.data;
  }
}
