import { KostenstelleService } from '@/modules/kostenstelle/kostenstelle-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class KostenstelleField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/kostenstelle',
      Permissions.values.kostenstelleRead,
      Permissions.values.kostenstelleNavigate,
      KostenstelleService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        let label = record.label;

        if (record.name) {
          label = record.name;
        }

        return {
          id: record.id,
          label: label,
        };
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/kostenstelle',
      Permissions.values.kostenstelleRead,
      Permissions.values.kostenstelleNavigate,
      KostenstelleService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: record.name,
        };
      },
      options,
    );
  }
}
