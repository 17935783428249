import Errors from '@/shared/error/errors';
import { i18n } from '@/i18n';
import importerStatuses from '@/shared/importer/importer-statuses';
import Importer from '@/shared/importer/importer';
import { EXCEL_TYPE } from '@/shared/excel/excel';
import { chunk, groupBy } from 'lodash';
import md5 from 'md5';

async function importRow(commit, importer, importFn, row) {
  try {
    const importableRow = await importer.castForImport(row);
    const importHash = md5(JSON.stringify(importableRow));
    await importFn(importableRow, importHash);
    commit('IMPORT_BATCH_SUCCESS', {
      line: row._line,
    });
  } catch (error) {
    commit('IMPORT_BATCH_ERROR', {
      line: row._line,
      errorMessage: Errors.selectMessage(error),
    });
  }
}

function groupByPersonalNr(rows) {
  const a = groupBy(rows, 'mitarbeiterNr');
  return a;
}

async function importRows(commit, importer, importFn, rows) {
  let processingRows = [];
  try {
    const importableRows = await Promise.all(
      rows.map(async (row) => {
        return await importer.castForImport(row);
      }),
    );

    processingRows = rows.map((f) => f._line);
    const importHash = md5(JSON.stringify(importableRows));
    await importFn(importableRows, importHash);

    commit('IMPORT_BATCH_SUCCESS', {
      line: processingRows,
    });

    console.log('no-error');
  } catch (error) {
    console.log('error');
    commit('IMPORT_BATCH_ERROR', {
      line: processingRows,
      errorMessage: Errors.selectMessage(error),
    });
  }
}

export default (
  importFn,
  importFields,
  templateFileName,
  importerHint,
  customImport,
  batchSize = 100000,
) => ({
  namespaced: true,
  state: {
    rows: null,
    errorMessage: null,
    importing: false,
    completed: false,
  },
  getters: {
    rows: (state) => state.rows || [],
    hasRows: (state, getters) => !!getters.rows.length,
    errorMessage: (state) => state.errorMessage,
    pendingRows: (state, getters) =>
      getters.rows.filter((row) => row._status === importerStatuses.PENDING),
    pendingRowsCount: (state, getters) => getters.pendingRows.length,
    rowsCount: (state, getters) => getters.rows.length,
    importedRowsCount: (state, getters) =>
      getters.rows.filter((row) => row._status === importerStatuses.IMPORTED)
        .length,
    nonPendingRowsCount: (state, getters) =>
      getters.rowsCount - getters.pendingRowsCount,
    errorRowsCount: (state, getters) =>
      getters.rows.filter((row) => row._status === importerStatuses.ERROR)
        .length,
    importing: (state) => !!state.importing,
    completed: (state) => !!state.completed,
    percent: (state, getters) =>
      Math.round((getters.nonPendingRowsCount * 100) / getters.rowsCount),
    fields: () => importFields,
    hint: () => importerHint,
    custom: () => customImport,
  },
  mutations: {
    RESETED(state) {
      state.rows = null;
      state.errorMessage = null;
      state.importing = false;
      state.completed = false;
    },
    FILE_READ_ERROR(state, payload) {
      state.errorMessage = payload.message ? payload.message : payload;
    },
    FILE_READ_SUCCESS(state, payload) {
      state.errorMessage = null;
      state.rows = payload;
    },
    IMPORT_STARTED(state) {
      state.importing = true;
    },
    IMPORT_PAUSED(state) {
      state.importing = false;
    },
    IMPORT_SUCCESS(state) {
      state.importing = false;
      state.completed = true;
    },
    IMPORT_ERROR(state) {
      state.importing = false;
    },
    IMPORT_BATCH_SUCCESS(state, payload) {
      if (Array.isArray(payload.line)) {
        for (const row of payload.line) {
          const item = (state.rows || []).find((item) => item._line === row);
          if (!item) {
            return;
          }
          item._status = importerStatuses.IMPORTED;
          state.rows = [...state.rows];
        }
      } else {
        const item = (state.rows || []).find(
          (item) => item._line === payload.line,
        );
        if (!item) {
          return;
        }
        item._status = importerStatuses.IMPORTED;
        state.rows = [...state.rows];
      }
    },
    IMPORT_BATCH_ERROR(state, payload) {
      if (Array.isArray(payload.line)) {
        for (const row of payload.line) {
          const item = (state.rows || []).find((item) => item._line === row);
          if (!item) {
            return;
          }
          item._status = importerStatuses.ERROR;
          item._errorMessage = payload.errorMessage;
          state.rows = [...state.rows];
        }
      } else {
        const item = (state.rows || []).find(
          (item) => item._line === payload.line,
        );
        if (!item) {
          return;
        }
        item._status = importerStatuses.ERROR;
        item._errorMessage = payload.errorMessage;
        state.rows = [...state.rows];
      }
    },
  },
  actions: {
    doReset({ commit }) {
      commit('RESETED');
    },
    doPause({ commit }) {
      commit('IMPORT_PAUSED');
    },
    async doImport({ commit, getters }) {
      try {
        commit('IMPORT_STARTED');
        const pendingRows = getters.pendingRows;
        const importer = new Importer(importFields);

        if (getters.custom == 'salary') {
          const groupedRows = groupByPersonalNr(pendingRows);

          for (const key of Object.keys(groupedRows)) {
            await importRows(commit, importer, importFn, groupedRows[key]);
          }
        } else {
          const pendingBatches = chunk(pendingRows, batchSize);
          for (let batch of pendingBatches) {
            const paused = !getters.importing;
            if (paused) {
              return;
            }
            for (const item of batch) {
              await importRow(commit, importer, importFn, item);
            }
            /*  await Promise.all(
              batch.map((row) => importRow(commit, importer, importFn, row)),
            ); */
          }
        }

        commit('IMPORT_SUCCESS');
      } catch (error) {
        Errors.handle(error);
        commit('IMPORT_ERROR');
      }
    },

    doDownloadTemplate() {
      const importer = new Importer(importFields);
      importer.downloadTemplate(templateFileName);
    },
    async doReadFile({ commit }, file) {
      try {
        const isExcel = file.type === EXCEL_TYPE;
        if (!isExcel) {
          throw new Error(i18n('importer.errors.invalidFileExcel'));
        }
        const importer = new Importer(importFields);
        let rawData = await importer.convertExcelFileToJson(file);
        if (!rawData || !rawData.length) {
          throw new Error(i18n('importer.errors.invalidFileEmpty'));
        }

        let rawData2 = await Promise.all(
          rawData.filter((row) => row[30] != undefined),
        );

        let rawData3 = await Promise.all(
          rawData2.map(async (row, index) => {
            return await importer.castForDisplay(row, index);
          }),
        );

        commit('FILE_READ_SUCCESS', rawData3);
      } catch (error) {
        console.error(error);
        commit('FILE_READ_ERROR', error);
      }
    },
  },
});
