import { EinstufungService } from '@/modules/einstufung/einstufung-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class EinstufungField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/einstufung',
      Permissions.values.einstufungRead,
      Permissions.values.einstufungNavigate,
      EinstufungService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: record.name,
        };
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/einstufung',
      Permissions.values.einstufungRead,
      Permissions.values.einstufungNavigate,
      EinstufungService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: record.name,
        };
      },
      options,
    );
  }
}
