let settings = {};

export class SettingsStorage {
  static async get() {
    return settings || null;
  }

  static getByName(name) {
    return settings[`${name}`];
  }

  static async save(data) {
    //write to memory variable
    settings.geringfuegigkeitsgrenze = data.geringfuegigkeitsgrenze;
    settings.hbglProTag = data.hbglProTag;
    settings.hbglProMonat = data.hbglProMonat;
    settings.hbglProJahr = data.hbglProJahr;

    settings.sv = data.sv;
    settings.sv_60_bis_63 = data.sv_60_bis_63;
    settings.sv_uber_63 = data.sv_uber_63;
    settings.sv_geringfuegig = data.sv_geringfuegig;
    settings.sv_geringfuegig_uber_60 = data.sv_geringfuegig_uber_60;
    settings.sv_sz = data.sv_sz;
    settings.sv_sz_60_bis_63 = data.sv_sz_60_bis_63;
    settings.sv_sz_uber_63 = data.sv_sz_uber_63;
    settings.db = data.db;
    settings.db_uber_60 = data.db_uber_60;
    settings.dz = data.dz;
    settings.bv = data.bv;
    settings.KoST = data.koSt;

    /* //write to local storage
    localStorage.setItem(
      'geringfuegigkeitsgrenze',
      data.geringfuegigkeitsgrenze || '',
    );
    localStorage.setItem('hbglProTag', data.hbglProTag || '');
    localStorage.setItem('hbglProMonat', data.hbglProMonat || '');
    localStorage.setItem('hbglProJahr', data.hbglProJahr || ''); */
  }

  static async clear() {
    settings = {};
    /*  localStorage.setItem('geringfuegigkeitsgrenze', '');
    localStorage.setItem('hbglProTag', '');
    localStorage.setItem('hbglProMonat', '');
    localStorage.setItem('hbglProJahr', ''); */
  }
}
