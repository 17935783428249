import importerStore from '@/shared/importer/importer-store';
import { StaatsbuergerschaftService } from '@/modules/staatsbuergerschaft/staatsbuergerschaft-service';
import staatsbuergerschaftImporterFields from '@/modules/staatsbuergerschaft/staatsbuergerschaft-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  StaatsbuergerschaftService.import,
  staatsbuergerschaftImporterFields,
  i18n('entities.staatsbuergerschaft.importer.fileName'),
  i18n('entities.staatsbuergerschaft.importer.hint'),
);
