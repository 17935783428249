import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const FakultaetListPage = () =>
  import('@/modules/fakultaet/components/fakultaet-list-page.vue');
const FakultaetFormPage = () =>
  import('@/modules/fakultaet/components/fakultaet-form-page.vue');

export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'fakultaet',
        path: '/fakultaet',
        component: FakultaetListPage,
        meta: {
          auth: true,
          permission: Permissions.values.fakultaetNavigate,
        },
      },
      {
        name: 'fakultaetNew',
        path: '/fakultaet/new',
        component: FakultaetFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.fakultaetNavigate,
        },
      },
      {
        name: 'fakultaetEdit',
        path: '/fakultaet/:id/edit',
        component: FakultaetFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.fakultaetNavigate,
        },
        props: true,
      },
    ],
  },
];
