import { i18n } from '@/i18n';
import DateField from '@/shared/fields/date-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import DecimalField from '@/shared/fields/decimal-field';
import BooleanField from '@/shared/fields/boolean-field';
import MoneyField from '@/shared/fields/money-field';

function label(name) {
  return i18n(`entities.mitarbeiter.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`entities.mitarbeiter.enumerators.${name}.${value}`);
}
const fields = {
  mitarbeiterNr: new StringField('mitarbeiterNr', label('mitarbeiterNr'), {
    required: false,
    max: 50,
  }),
  anrede: new EnumeratorField('anrede', label('anrede'), [
    {
      id: 'Herr',
      label: enumeratorLabel('anrede', 'herr'),
    },
    {
      id: 'Frau',
      label: enumeratorLabel('anrede', 'frau'),
    },
  ]),
  titel: new StringField('titel', label('titel'), {
    required: false,
    max: 50,
  }),
  nachname: new StringField('nachname', label('nachname'), {
    required: false,
    max: 100,
  }),
  vorname: new StringField('vorname', label('vorname'), {
    required: false,
    max: 100,
  }),
  vollNameExport: new StringField('vollNameExport', label('vollNameExport'), {
    required: false,
    max: 100,
  }),
  email: new StringField('email', label('email'), {
    required: false,
    matches:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    max: 254,
  }),
  vollname: new StringField('vollname', label('vollname')),
  strasse: new StringField('strasse', label('strasse'), {
    required: false,
    max: 255,
  }),
  plz: new StringField('plz', label('plz'), {
    required: false,
    max: 10,
  }),
  stadt: new StringField('stadt', label('stadt'), {
    required: false,
    max: 50,
  }),
  svNummer: new StringField('svNummer', label('svNummer'), {
    required: false,
    matches: /^[0-9]{4}/,
    max: 20,
  }),
  geburtsdatum: new DateField('geburtsdatum', label('geburtsdatum'), {
    format: 'DD.MM.YYYY',
  }),
  telefon: new StringField('telefon', label('telefon'), {
    required: false,
    max: 255,
  }),
  beruf: new StringField('beruf', label('beruf')),
  funktion: new StringField('funktion', label('funktion')),
  sa_ausb: new StringField('sa_ausb', label('sa_ausb'), {}),
  sa_fkt: new StringField('sa_fkt', label('sa_fkt'), {}),
  sa_taet1: new StringField('sa_taet1', label('sa_taet1'), {}),
  sa_taet2: new StringField('sa_taet2', label('sa_taet2'), {}),
  sa_verw: new StringField('sa_verw', label('sa_verw'), {}),
  saPers: new StringField('saPers', label('saPers'), {
    required: false,
    max: 255,
  }),
  sa_sex: new StringField('sa_sex', label('sa_sex'), {}),
  saStaat: new StringField('saStaat', label('saStaat'), {
    required: false,
    max: 255,
  }),
  saFte: new StringField('saFte', label('saFte'), {
    required: false,
    max: 255,
  }),
  sa_gebj: new StringField('sa_gebj', label('sa_gebj'), {
    required: false,
    max: 34,
  }),
  saStudienrichtung: new StringField(
    'saStudienrichtung',
    label('saStudienrichtung'),
    {
      required: false,
      max: 255,
    },
  ),
  iban: new StringField('iban', label('iban'), {
    required: false,
    max: 50,
  }),
  bic: new StringField('bic', label('bic'), {
    required: false,
    max: 20,
  }),
  gesamtwochenstunden: new DecimalField(
    'gesamtwochenstunden',
    label('gesamtwochenstunden'),
    {
      scale: 2,
      min: 0,
      max: 1000,
      required: false,
    },
  ),
  eintrittsdatum: new DateField('eintrittsdatum', label('eintrittsdatum'), {
    format: 'DD.MM.YYYY',
  }),
  austrittsdatum: new DateField('austrittsdatum', label('austrittsdatum'), {
    format: 'DD.MM.YYYY',
  }),
     dienstjahreInt: new StringField('dienstjahreInt', label('dienstjahreInt'), {
    required: false,
  }),
  dienstjahre: new StringField('dienstjahre', label('dienstjahre'), {
    required: false,
  }),
  /*   gehaltProJahr: new EnumeratorField('gehaltProJahr', label('gehaltProJahr'), [
    {
      id: '12',
      label: enumeratorLabel('gehaltProJahr', '12'),
    },
    {
      id: '14',
      label: enumeratorLabel('gehaltProJahr', '14'),
    },
  ]), */
  gehaltProJahr: new StringField('gehaltProJahr', label('gehaltProJahr'), {}),
  uBahn: new StringField('uBahn', label('uBahn'), {}),
  betriebsumlage: new StringField(
    'betriebsumlage',
    label('betriebsumlage'),
    {},
  ), 
  karenzvertretung: new BooleanField(
    'karenzvertretung',
    label('karenzvertretung'),
  ),
  mandant: new StringField('mandant', label('mandant'), {}),
  vertragsart: new StringField('vertragsart', label('vertragsart'), {}),
  kostenstelle: new StringField('kostenstelle', label('kostenstelle'), {}),
  hauptkostenstelle: new StringField(
    'hauptkostenstelle',
    label('hauptkostenstelle'),
    {},
  ),
  kostentraeger: new StringField('kostentraeger', label('kostentraeger'), {}),
  prozent: new DecimalField('prozent', label('prozent'), {
    scale: 2,
    min: 0,
    max: 1000,
    required: false,
  }),
  von: new DateField('von', label('von'), { format: 'DD.MM.YYYY' }),
  bis: new DateField('bis', label('bis'), { format: 'DD.MM.YYYY' }),
  wochenstunden: new DecimalField('wochenstunden', label('wochenstunden'), {
    scale: 2,
    min: 0,
    max: 1000,
    required: false,
  }),
  brutto: new MoneyField('brutto', label('brutto'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  neuBrutto: new MoneyField('neuBrutto', label('neuBrutto'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  gesamtbrutto: new MoneyField('gesamtbrutto', label('gesamtbrutto'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  gesamtkosten: new MoneyField('gesamtkosten', label('gesamtkosten'), {
    scale: 2,
    min: 0,
    max: 10000000,
    required: false,
  }),
  fakultaet: new StringField('fakultaet', label('fakultaet')),
  verwendungKostenstelle: new StringField(
    'verwendungKostenstelle',
    label('verwendungKostenstelle'),
    {},
  ),
  einstufung: new StringField('einstufung', label('einstufung'), {}),
};
export class MitarbeiterExportModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
