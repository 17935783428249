<template>
  <div style="display: flex">
    <app-autocomplete-one-input
      :fetchFn="fetchFn"
      :filter="filter"
      v-if="mode !== 'multiple'"
      v-model="model"
      :disabled="disabled"
    ></app-autocomplete-one-input>
    <app-autocomplete-many-input
      :fetchFn="fetchFn"
      :filter="filter"
      v-if="mode === 'multiple'"
      v-model="model"
      :disabled="disabled"
    ></app-autocomplete-many-input>
  </div>
</template>

<script>
export default {
  name: 'app-statistik-austria-autocomplete-input',
  props: [
    'value',
    'mode',
    'fetchFn',
    'mapperFn',
    'showCreate',
    'filter',
    'disabled',
  ],

  components: {},

  data() {
    return {};
  },

  computed: {
    model: {
      get: function () {
        return this.value;
      },

      set: function (value) {
        this.$emit('input', value);
      },
    },
  },

  methods: {},
};
</script>

<style>
.el-form-item .el-form-item {
  margin-bottom: 22px;
}
</style>
