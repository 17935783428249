<template>
  <div class="app-pdf-view">
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="60%"
      top="0"
      destroy-on-close
    >
      <el-row :gutter="10">
        <el-col :span="20" :offset="0">
          <div v-loading="loading">
            <app-pdf-view
              v-on:loading="setLoading"
              v-model="loading"
              :privateUrl="privateUrl"
            >
            </app-pdf-view>
          </div>
        </el-col>
        <div>
          <el-col :span="4" :offset="0">
            <el-card class="box-card actions" shadow="never">
              <div slot="header" class="clearfix">
                <span>Aktionen</span>
              </div>
              <div class="clearfix">
                <el-button
                  :disabled="manualUploadMode || loading"
                  v-on:click="doSubmit()"
                  type="text"
                >
                  <app-i18n code="common.sendForApproval"></app-i18n>
                </el-button>
              </div>
              <div class="clearfix">
                <el-button
                  :disabled="loading"
                  v-on:click="doDownload()"
                  type="text"
                >
                  <app-i18n code="common.download"></app-i18n>
                </el-button>
              </div>
              <div class="clearfix">
                <el-button
                  :disabled="loading"
                  v-on:click="dialogVisible = false"
                  type="text"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </el-button>
              </div>
            </el-card>
          </el-col>
        </div>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
/* import Errors from '@/shared/error/errors'; */
import { mapGetters } from 'vuex';
import { EventBus } from '@/eventBus';

export default {
  name: 'app-beendigung-entwurf-modal',
  props: ['file', 'visible'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      manualUploadMode: 'beendigung/manualUploadMode',
    }),
    id() {
      return this.record.id;
    },
    downloadUrl() {
      return this.file.downloadUrl;
    },
    privateUrl() {
      return this.file.privateUrl;
    },
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close', this.file);
        }
      },
    },
    title() {
      return 'Preview'; //i18n('user.new.titleModal');
    },
  },

  methods: {
    setLoading(value) {
      this.loading = value;
    },

    doSubmit() {
      EventBus.$emit('doSetBeendigungentwurf');
      EventBus.$emit('doSubmitBeendigungsentwurf');
      this.$store.dispatch('beendigung/doClearEntwurfFile');
      this.dialogVisible = false;
    },
    doDownload() {
      window.open(this.downloadUrl, '_blank');
    },
  },
};
</script>

<style scoped>
.text-area {
  padding-top: 20px;
}
</style>
