<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-on:close="handleTempFiles"
      width="40%"
    >
      <el-form
        label-position="top"
        :label-width="labelWidthForm"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
        v-loading="saveLoading"
        :disabled="vertragUploaded"
      >
        <div v-if="!isEditing">
          <el-form-item
            :prop="fields.inflationGehaltsanpassung.name"
            :required="fields.inflationGehaltsanpassung.required"
          >
            <el-checkbox v-model="model[fields.inflationGehaltsanpassung.name]">
              {{ fields.inflationGehaltsanpassung.label }}
            </el-checkbox>
          </el-form-item>

          <el-form-item
            :label="fields.vorlageDienstgeber.label"
            :prop="fields.vorlageDienstgeber.name"
            :required="fields.vorlageDienstgeber.required"
          >
            <app-vorlage-dienstgeber-autocomplete-input
              :fetchFn="fields.vorlageDienstgeber.fetchFn"
              :mapperFn="fields.vorlageDienstgeber.mapperFn"
              mode="single"
              filter="vertragsaenderung"
              v-model="model[fields.vorlageDienstgeber.name]"
            ></app-vorlage-dienstgeber-autocomplete-input>
          </el-form-item>
          <el-form-item
            :label="fields.vertrag.label"
            :prop="fields.vertrag.name"
          >
            <app-autocomplete-one-input
              :filter="mitarbeiterId"
              :fetchFn="fields.vertrag.fetchFn"
              v-model="model[fields.vertrag.name]"
            ></app-autocomplete-one-input>
          </el-form-item>

          <el-table
            :data="tableData"
            ref="table"
            style="width: 100%"
            max-height="450"
            :select-all="selectAll()"
          >
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column
              :label="fields.kostenstelle.label"
              :prop="fields.kostenstelle.name"
            >
              <template v-slot="scope">
                <app-list-item-relation-to-one
                  :label="fields.kostenstelle.label"
                  :readPermission="fields.kostenstelle.readPermission"
                  :navigatePermission="false"
                  :value="presenter(scope.row, 'kostenstelle')"
                ></app-list-item-relation-to-one>
              </template>
            </el-table-column>
            <el-table-column :label="fields.von.label" :prop="fields.von.name">
              <template v-slot="scope">
                {{ presenter(scope.row, 'von') }}
              </template>
            </el-table-column>
            <el-table-column :label="fields.bis.label" :prop="fields.bis.name">
              <template v-slot="scope">
                {{ presenter(scope.row, 'bis') }}
              </template>
            </el-table-column>
            <el-table-column
              :label="fields.brutto.label"
              :prop="fields.brutto.name"
            >
              <template v-slot="scope">
                {{ presenter(scope.row, 'brutto') }}
              </template>
            </el-table-column>
          </el-table>

          <el-form-item>
            <el-checkbox v-model="uploadMode">
              <app-i18n code="common.manualPreparation"></app-i18n>
            </el-checkbox>
          </el-form-item>

          <el-form-item
            :label="fields.entwurf.label"
            :prop="fields.entwurf.name"
            :required="fields.entwurf.required"
            v-if="uploadMode"
          >
            <app-file-upload
              :disabled="isEditing"
              :max="fields.entwurf.max"
              :storage="fields.entwurf.storage"
              :formats="fields.entwurf.formats"
              :permissions="fields.entwurf.permissions"
              v-model="model[fields.entwurf.name]"
            ></app-file-upload>
          </el-form-item>
        </div>

        <div v-if="isEditing">
          <el-form-item
            :label="fields.entwurfStatus.label"
            :prop="fields.entwurfStatus.name"
            :required="fields.entwurfStatus.required"
          >
            <el-tag
              :type="getStatusTagType(model[fields.entwurfStatus.name])"
              v-if="isStatusNull(model[fields.entwurfStatus.name])"
            >
              {{ model[fields.entwurfStatus.name] }}
            </el-tag>
          </el-form-item>
          <el-form-item
            :label="fields.decisionDate.label"
            :prop="fields.decisionDate.name"
            :required="fields.decisionDate.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-date-picker
                :readonly="isEditing"
                :format="elementUiDateFormat"
                placeholder
                type="date"
                v-model="model[fields.decisionDate.name]"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item
            :label="fields.sentAt.label"
            :prop="fields.sentAt.name"
            :required="fields.sentAt.required"
          >
            <el-col :lg="13" :md="16" :sm="24">
              <el-date-picker
                :readonly="isEditing"
                :format="elementUiDateFormat"
                placeholder
                type="date"
                v-model="model[fields.sentAt.name]"
              ></el-date-picker>
            </el-col>
          </el-form-item>

          <el-form-item
            :label="fields.unterschriebenerVertragsaenderung.label"
            :prop="fields.unterschriebenerVertragsaenderung.name"
            :required="fields.unterschriebenerVertragsaenderung.required"
          >
            <app-file-upload
              :max="fields.unterschriebenerVertragsaenderung.max"
              :storage="fields.unterschriebenerVertragsaenderung.storage"
              :formats="fields.unterschriebenerVertragsaenderung.formats"
              :permissions="
                fields.unterschriebenerVertragsaenderung.permissions
              "
              v-model="model[fields.unterschriebenerVertragsaenderung.name]"
            ></app-file-upload>
          </el-form-item>
        </div>

        <el-form-item>
          <div class="form-buttons">
            <el-button
              :disabled="saveLoading"
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
              v-if="uploadMode || isEditing"
            >
              <app-i18n code="common.save"></app-i18n>
            </el-button>
            <el-button
              :disabled="saveLoading"
              @click="createDraft"
              icon="el-icon-fa-floppy-o"
              type="primary"
              v-if="!isEditing"
            >
              <app-i18n code="common.prepare"></app-i18n>
            </el-button>

            <el-button
              :disabled="saveLoading"
              @click="doCancel"
              icon="el-icon-fa-close"
            >
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { VertragsaenderungModel } from '@/modules/vertragsaenderung/vertragsaenderung-model';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { FormSchema } from '@/shared/form/form-schema';
import { i18n } from '@/i18n';
import { EventBus } from '@/eventBus';
import Message from '@/shared/message/message';

const { fields } = VertragsaenderungModel;
const formSchema = new FormSchema([
  fields.id,
  fields.entwurfStatus,
  fields.decisionDate,
  fields.sentAt,
  fields.entwurf,
  fields.unterschriebenerVertragsaenderung,
  fields.vorlageDienstgeber,
  fields.vertrag,
  fields.kostenstelle,
  fields.von,
  fields.bis,
  fields.brutto,
  fields.inflationGehaltsanpassung,
]);

export default {
  name: 'app-vertragsaenderung-form-modal',

  props: ['record', 'grundgehalt', 'mitarbeiterId', 'visible'],

  data() {
    return {
      model: null,
      rules: formSchema.rules(),
      tableData: [],
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});

    if (this.grundgehalt) {
      const filteredGrundgehalt = this.grundgehalt.filter((f) => {
        if (Vue.DateHelper.isKstActive(f) || Vue.DateHelper.isInFuture(f.von)) {
          return f;
        }
      });

      this.tableData = filteredGrundgehalt.map((f) => {
        const values = formSchema.initialValues(f || {});
        return values;
      });
    }

    EventBus.$once('doSubmitVertragsaenderungsentwurf', () => {
      this.doSubmit();
    });
    EventBus.$once('doSetVertragsaenderungsentwurf', () => {
      this.doSetVertragsaenderungsentwurf();
    });
  },

  beforeDestroy() {
    EventBus.$off('doSubmitVertragsaenderungsentwurf');
    EventBus.$off('doSetVertragsaenderungsentwurf');
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
      loading: 'vertragsaenderung/loading',
      saveLoading: 'vertragsaenderung/saveLoading',
      entwurf: 'vertragsaenderung/entwurfFile',
      manualUploadMode: 'vertragsaenderung/manualUploadMode',
    }),

    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$store.dispatch('vertragsaenderung/doSetUploadMode', false);
          this.$emit('close');
        }
      },
    },
    uploadMode: {
      get() {
        return this.manualUploadMode;
      },
      set(value) {
        this.$store.dispatch('vertragsaenderung/doSetUploadMode', value);
      },
    },
    isEditing() {
      return !!this.record;
    },
    pendingFiles() {
      if (!this.model) return [];

      let unsaved = [];
      const entwurf = this.model.entwurf.filter((f) => f.new);
      if (this.model.vertragsaenderung) {
        const vertragsaenderung = this.model.vertragsaenderung.filter(
          (f) => f.new,
        );
        unsaved = unsaved.concat(vertragsaenderung);
      }

      unsaved = unsaved.concat(entwurf);
      return unsaved;
    },
    fields() {
      return fields;
    },
    title() {
      return this.isEditing
        ? i18n('entities.vertragsaenderung.edit.title')
        : i18n('entities.vertragsaenderung.new.title');
    },
    vertragUploaded() {
      if (this.record) {
        return !!this.record.vertragStatus;
      } else return false;
    },
  },

  methods: {
    ...mapActions({
      doCreateDraft: 'vertragsaenderung/doCreateDraft',
    }),
    selectAll() {
      return;
    },
    presenter(row, fieldName) {
      return VertragsaenderungModel.presenter(row, fieldName);
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
        if (!this.isEditing) {
          if (this.$refs.table.selection.length === 0) {
            Message.error('Bitte wählen Sie eine Kostenstelle.');
            return;
          }
        }
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);
      values.mitarbeiter = this.mitarbeiterId;

      if (!this.isEditing) {
        values.kostenstelle = this.$refs.table.selection;
      }

      let action = 'create';
      if (this.isEditing) {
        action = 'update';
      }

      return this.$emit(action, {
        id,
        values,
      });
    },
    async createDraft() {
      try {
        if (!this.model.vorlageDienstgeber) {
          await this.$refs.form.validateField('vorlageDienstgeber');
          return;
        }
        if (this.$refs.table.selection.length === 0) {
          Message.error('Bitte wählen Sie eine Kostenstelle.');
          return;
        }
      } catch (error) {
        console.log(error);
        return;
      }
      const { id, ...values } = formSchema.cast(this.model);
      values.mitarbeiter = this.mitarbeiterId;
      values.kostenstelle = this.$refs.table.selection;

      await this.doCreateDraft({
        id,
        values,
      });

      this.$emit('openEntwurfDialog');
    },
    doSetVertragsaenderungsentwurf() {
      this.model.entwurf = [this.entwurf];
    },
    doCancel() {
      this.handleTempFiles();
      this.dialogVisible = false;
    },
    handleTempFiles() {
      if (this.pendingFiles.length > 0) {
        FileUploader.removePendingFiles(this.pendingFiles);
      }
    },
    isStatusNull(entwurfStatus) {
      return entwurfStatus === null ? false : true;
    },
    getStatusTagType(entwurfStatus) {
      if (entwurfStatus === 'active') {
        return 'success';
      }
      if (
        entwurfStatus === 'requested' ||
        entwurfStatus === 'revisionRequested'
      ) {
        return 'warning';
      }
      if (entwurfStatus === 'rejected') {
        return 'danger';
      }
      if (entwurfStatus === 'releasePending') {
        return '';
      }
      if (entwurfStatus === 'inactive') {
        return 'info';
      }
      return '';
    },
  },
};
</script>

<style scoped>
* >>> th .el-checkbox {
  display: none !important;
}
</style>
