import authAxios from '@/shared/axios/auth-axios';

export class VorlageDienstgeberService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/vorlage-dienstgeber/${id}`, body);

    return response.data;
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/vorlage-dienstgeber`, {
      params,
    });

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/vorlage-dienstgeber`, body);

    return response.data;
  }

  static async find(id) {
    const response = await authAxios.get(`/vorlage-dienstgeber/${id}`);
    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/vorlage-dienstgeber`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(limit, query, parentId, filter) {
    const params = {
      limit,
      query,
      parentId,
      filter,
    };

    const response = await authAxios.get(`/vorlage-dienstgeber/autocomplete`, {
      params,
    });

    return response.data;
  }
}
