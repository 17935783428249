import * as yup from 'yup';
import GenericField from '@/shared/fields/generic-field';
import moment from 'moment';
import { i18n } from '@/i18n';

export default class DateRangeField extends GenericField {
  constructor(name, label, { required = false } = {}) {
    super(name, label);
    this.required = required;
  }

  forPresenter(value) {
    return value
      ? value.map((f) => moment(f).format('DD.MM.YYYY')).join(' - ')
      : null;
  }

  forFormInitialValue(value) {
    return value || [];
  }

  forFilterInitialValue(value) {
    return value || [];
  }

  forFilterRules() {
    const output = [];

    if (this.required) {
      output.push({
        type: 'array',
        required: !!this.required,
        message: i18n('validation.mixed.required').replace(
          '${path}',
          this.label,
        ),
      });

      return output;
    }
  }

  forFilterCast() {
    return yup.mixed().transform((value, originalValue) => {
      if (!originalValue) {
        return originalValue;
      }

      if (!originalValue.length) {
        return originalValue;
      }

      return originalValue.map((value) => {
        return value ? moment(value).format('YYYY-MM-DD') : null;
      });
    });
  }

  forFormRules() {
    const output = [];

    if (this.required) {
      output.push({
        type: 'array',
        required: !!this.required,
        message: i18n('validation.mixed.required').replace(
          '${path}',
          this.label,
        ),
      });

      return output;
    }
  }

  forFormCast() {
    let yupChain = yup
      .array()
      .compact()
      .ensure()
      .label(this.label)
      .transform((value, originalValue) => {
        if (!originalValue) {
          return originalValue;
        }

        if (Array.isArray(originalValue)) {
          return originalValue;
        }

        return [originalValue];
      });

    return yupChain;
  }
}
