import mandantListStore from '@/modules/mandant/mandant-list-store';
import mandantFormStore from '@/modules/mandant/mandant-form-store';
import mandantDestroyStore from '@/modules/mandant/mandant-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: mandantDestroyStore,
    form: mandantFormStore,
    list: mandantListStore,
  },
};
