import { JahreskarteService } from '@/modules/jahreskarte/jahreskarte-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    loading: (state) => !!state.loading,
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },

    DESTROY_STARTED(state) {
      state.loading = true;
    },

    DESTROY_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    async doDestroy({ commit, dispatch, rootGetters }, id) {
      try {
        commit('DESTROY_STARTED');

        await JahreskarteService.destroyAll([id]);

        dispatch(
          'jahreskarte/list/doFetch',
          { filter: rootGetters['jahreskarte/list/filter'] },
          {
            root: true,
          },
        );

        commit('DESTROY_SUCCESS');

        Message.success(i18n('entities.jahreskarte.destroy.success'));
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doDestroyAll({ commit, dispatch }, ids) {
      try {
        commit('DESTROY_ALL_STARTED');

        await JahreskarteService.destroyAll(ids);

        commit('DESTROY_ALL_SUCCESS');

        dispatch(`${'jahreskarte/list'}/doUnselectAll`, null, {
          root: true,
        });

        Message.success(i18n('entities.jahreskarte.destroyAll.success'));
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ALL_ERROR');
      }
    },
  },
};
