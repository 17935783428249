<template>
  <div>
    <div style="padding-bottom: 60px">
      <el-row>
        <el-card shadow="always" :body-style="{ padding: '10px' }">
          <span>
            <app-i18n code="entities.jahreskarte.name"></app-i18n>
          </span>
          <div class="anstellung">
            <el-row :gutter="20">
              <el-col :span="24" :offset="0">
                <app-jahreskarte-list-table
                  @doEdit="doEdit"
                  @doOpen="doOpen"
                  @doExtend="doExtend"
                  @doDelete="doDestroyWithConfirm"
                />
              </el-col>
            </el-row>
          </div>

          <div>
            <app-jahreskarte-form-modal
              :visible="dialogJahreskarteFormVisible"
              :record="row"
              :isExtending="isExtending"
              :isViewing="isViewing"
              :mitarbeiter="mitarbeiter"
              v-if="dialogJahreskarteFormVisible"
              @close="doCloseDlg"
            />
          </div>
          <div class="form-buttons">
            <el-button
              v-if="isCreateButtonVisible"
              v-on:click="
                dialogJahreskarteFormVisible = !dialogJahreskarteFormVisible
              "
              plain
              type="info"
            >
              <app-i18n code="common.add"></app-i18n>
            </el-button>
          </div>
        </el-card>
      </el-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import JahreskarteFormModal from '@/modules/jahreskarte/components/jahreskarte-form-modal.vue';
import JahreskarteListTable from '@/modules/jahreskarte/components/jahreskarte-list-table.vue';
import { JahreskartePermissions } from '@/modules/jahreskarte/jahreskarte-permissions';

import { i18n } from '@/i18n';

export default {
  name: 'app-jahreskarte-main',
  components: {
    [JahreskarteFormModal.name]: JahreskarteFormModal,
    [JahreskarteListTable.name]: JahreskarteListTable,
  },
  data() {
    return {
      row: null,
      dialogJahreskarteFormVisible: false,
      isExtending: false,
      isViewing: false,
    };
  },
  async mounted() {
    const filter = {
      mitarbeiter: this.mitarbeiter,
    };

    await this.doFetch({
      filter,
    });
  },
  computed: {
    ...mapGetters({
      mitarbeiter: 'mitarbeiter/form/id',
      currentUser: 'auth/currentUser',
    }),
    isCreateButtonVisible() {
      if (this.hasPermissionToCreate && this.mitarbeiter != null) {
        return true;
      }
      return false;
    },
    hasPermissionToCreate() {
      return new JahreskartePermissions(this.currentUser).create;
    },
  },

  methods: {
    ...mapActions({
      doFetch: 'jahreskarte/list/doFetch',
      doDestroy: 'jahreskarte/destroy/doDestroy',
    }),

    async doDelete() {},

    doCloseDlg() {
      this.row = null;
      this.isExtending = false;
      this.isViewing = false;
      this.dialogJahreskarteFormVisible = false;
    },

    async doEdit(row) {
      this.row = row;
      this.dialogJahreskarteFormVisible = true;
    },

    async doOpen(row) {
      this.row = row;
      this.isViewing = true;
      this.dialogJahreskarteFormVisible = true;
    },

    async doExtend(row) {
      let newRow = { ...row };

      this.row = newRow;
      this.row.id = null;
      this.row.von = Vue.DateHelper.addDays(newRow.bis, 1);
      this.row.bis = null;
      this.isExtending = true;
      this.dialogJahreskarteFormVisible = true;
    },

    async doDestroyWithConfirm(id) {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroy(id);
      } catch (error) {
        // no
      }
    },
  },
};
</script>
