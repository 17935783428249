import { AuswertungExportModel } from '@/modules/auswertung/auswertung-export-model';

const { fields } = AuswertungExportModel;

export default [
  fields.mitarbeiterNr,
  fields.vollname,
  fields.titel,
  fields.beruf,
fields.funktion,
  fields.mandant,
  fields.kostentraeger,
  fields.fakultaet,
  fields.kostenstelle,
  fields.hauptkostenstelle,
  fields.verwendungKostenstelle
];
