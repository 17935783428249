import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class FakultaetPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.fakultaetRead,
    );
    this.fakultaetAutocomplete = permissionChecker.match(
      Permissions.values.fakultaetAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.fakultaetCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.fakultaetEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.fakultaetDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.fakultaetNavigate,
    );
  }
}
