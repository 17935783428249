<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <div class="app-page-spinner" v-if="loading" v-loading="loading"></div>
      <app-mitarbeiter-anstellung-form
        :modal="true"
        :record="record"
        @cancel="doCancel"
        v-if="!loading"
      />
    </el-dialog>
  </div>
</template>

<script>
import MitarbeiterAnstellungForm from '@/modules/mitarbeiter/components/mitarbeiter-anstellung-form.vue';
import { MitarbeiterService } from '@/modules/mitarbeiter/mitarbeiter-service';
import { i18n } from '@/i18n';
import Errors from '@/shared/error/errors';

export default {
  name: 'app-mitarbeiter-anstellung-form-modal',

  props: ['visible', 'anstellung'],

  components: {
    [MitarbeiterAnstellungForm.name]: MitarbeiterAnstellungForm,
  },

  data() {
    return {
      /*  record: null, */
      loading: false,
      record: null,
    };
  },

  async created() {
    await this.doFind();
  },

  computed: {
    dialogVisible: {
      get: function() {
        return this.visible;
      },

      set: function(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    isEditing() {
      return !!this.anstellung;
    },

    title() {
      return i18n('entities.anstellung.view.title');
    },
  },

  methods: {
    async doFind() {
      try {
        this.loading = true;

        this.record = await MitarbeiterService.findHistory(this.anstellung);
      } catch (error) {
        Errors.handle(error);
      } finally {
        this.loading = false;
      }
    },

    doCancel() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style></style>
