import authAxios from '@/shared/axios/auth-axios';
export class AenderungService {
  static async send(data) {
    const body = {
      data,
    };
    const response = await authAxios.post(`/aenderung/senden`, body);
    return response.data;
  }
  static async list(filter, orderBy, limit, offset, isExport) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      isExport,
    };
    const response = await authAxios.get(`/aenderung`, {
      params,
    });
    return response.data;
  }
}
