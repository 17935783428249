<template>
  <div class="app-pdf-view">
    <el-card class="box-card">
      <div class="pdf-app">
        <div v-loading="loading">
          <vue-pdf-app
            :pdf="pdf"
            @open="openHandler"
            theme="light"
          ></vue-pdf-app>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import VuePdfApp from 'vue-pdf-app';
import { ConversionService } from '@/shared/pdf-view/conversion-service';
import Errors from '@/shared/error/errors';
import 'vue-pdf-app/dist/icons/main.css';

export default {
  name: 'app-pdf-view',

  props: ['fileId', 'privateUrl', 'arrayBuffer'],

  components: {
    VuePdfApp,
  },
  data() {
    return {
      pdf: null,
      loading: false,
    };
  },
  async created() {
    if (this.fileId) {
      //load already saved file
      await this.getPdf(this.fileId);
    } else if (!this.fileId && this.privateUrl) {
      //ina case when a new tmp file is created
      //it sill has no file saved, we only get back privateUrl path
      await this.getTempPdf(this.privateUrl);
    } else if (!this.fileId && !this.privateUrl && this.arrayBuffer) {
      this.pdf = this.arrayBuffer;
    }
  },
  methods: {
    openHandler(pdfApp) {
      window._pdfApp = pdfApp;
    },
    async getPdf(id) {
      try {
        this.loading = true;
        this.$emit('loading', true);
        this.pdf = await ConversionService.getPdf(id);
        this.loading = false;
        this.$emit('loading', false);
      } catch (error) {
        Errors.handle(error);
      }
    },
    async getTempPdf(privateUrl) {
      try {
        this.loading = true;
        this.$emit('loading', true);
        this.pdf = await ConversionService.getTempPdf(privateUrl);
        this.loading = false;
        this.$emit('loading', false);
      } catch (error) {
        Errors.handle(error);
      }
    },
  },
};
</script>

<style scoped></style>
