import { SettingsStorage } from '@/modules/settings/settings-storage';
import dateHelper from './dateHelper';

//salary calculations
const SV = 21.23;
const SV_60_63 = 20.03;
const SV_UBER_63 = 16.83;
const DB = 3.9;
const DB_UBER_60 = 0;
const DZ = 0;
const BV = 1.53;
const KoST = 3;

export default {
  gesamtkosten(
    gesamtbrutto,
    age,
    eintrittsdatum,
    uBahn,
    kommunalsteuer,
    gesamtwochenstunden,
  ) {
    const SV_HBGL_PRO_TAG = SettingsStorage.getByName('hbglProTag');
    const SV_HBGL_PRO_MONAT = SettingsStorage.getByName('hbglProMonat');
    const GERINGFUEGIG_GRENZE = SettingsStorage.getByName(
      'geringfuegigkeitsgrenze',
    );
    let gesamtkosten = 0;
    let gesamtbruttoRest = 0;
    let hbgl = 0;
    let isFirstMonth = false;
    let sundays = null;
    const isGeringfuegig = gesamtbrutto <= GERINGFUEGIG_GRENZE;

    if (gesamtbrutto === 0) {
      return 0;
    }

    if (dateHelper.isInCurrentMonth(eintrittsdatum)) {
      if (dateHelper.isFirstDayInMonth(eintrittsdatum)) {
        hbgl = SV_HBGL_PRO_MONAT;
      } else {
        const numberOfDays = dateHelper.getNumberOfDaysInMonth(eintrittsdatum);
        const daysToCalculate =
          numberOfDays - dateHelper.noTimeDate(eintrittsdatum).getDate() + 1; // +1 as date is start day, so that one is paid
        //get HBGL brutto limit
        hbgl = daysToCalculate * SV_HBGL_PRO_TAG;
        isFirstMonth = true;
        sundays = dateHelper.getNumberOfWeekDays(
          eintrittsdatum,
          dateHelper.lastDayOfMonth(eintrittsdatum),
        );
      }
    } else {
      hbgl = SV_HBGL_PRO_MONAT;
    }

    /* if sum is greater than hbgl
     * it is split to gesamtbrutto of hbgl and gesamtbruttoRest */
    if (gesamtbrutto > hbgl) {
      gesamtbruttoRest = gesamtbrutto - hbgl;
      gesamtbrutto = hbgl;
    }
    //calculate gesamtkosten for brutto less than hbgl
    let percentage = this.getPercentageByAge(
      age,
      isFirstMonth,
      false,
      kommunalsteuer,
      isGeringfuegig,
    );
    gesamtkosten = gesamtbrutto + this._percentage(gesamtbrutto, percentage);

    //if there is gesamtbruttoRest calculate percentage and add to gesamtkosten
    if (gesamtbruttoRest) {
      let percentage2 = this.getPercentageByAge(
        age,
        isFirstMonth,
        true,
        kommunalsteuer,
        isGeringfuegig,
      );
      gesamtkosten +=
        gesamtbruttoRest + this._percentage(gesamtbruttoRest, percentage2);
    }

    // geringfugun ne placa uban, dodat
    // TODO
    // umjesto gesamtbrutto stavit da ako ima gesamtstunde <= 10 sati
    if (uBahn && age < 55 && gesamtwochenstunden > 10) {
      gesamtkosten += this.addUbahnCost(sundays);
    }
    return parseFloat(gesamtkosten.toFixed(2), 10);
  },

  isFirstEmploymentAndStartingFirstOfMonth(date) {
    return (
      dateHelper.isInCurrentMonth(date) && dateHelper.isFirstDayInMonth(date)
    );
  },

  calculateBruttoBasedOnWorkingDays(brutto, date, isKstVon) {
    let daysToCalculate = 0;
    //get days to pay in this month as start day is not 1th in month
    const numberOfDays = dateHelper.getNumberOfDaysInMonth(date);
    if (isKstVon) {
      daysToCalculate = numberOfDays - date.getDate() + 1; // +1 as date is start day, so that one is paid
    } else {
      daysToCalculate = date.getDate() + 1;
    }
    //get HBGL brutto limit
    /*  const BRUTTO_HBGL = daysToCalculate * SV_HBGL_PRO_TAG; */
    const bruttoForDays = (brutto / numberOfDays) * daysToCalculate;
    return bruttoForDays;
  },

  sumArrayObjectField(data, field) {
    return data
      .map((f) => {
        if (f[`${field}`]) {
          if (dateHelper.isKstActive(f)) {
            return f[`${field}`];
          }
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);
  },

  getGesamtbrutto(data, field, eintrittsdatum, austrittsdatum) {
    const gesamtbrutto = data
      .map((f) => {
        //if KST status is inactive or is inactive by dates it's not processed
        if (
          f.status &&
          (f.status === 'inactive' ||
            f.status === 'mutterschutz' ||
            f.status === 'mutterkarenz' ||
            f.status === 'bildungskarenz' ||
            f.status === 'papamonat' ||
            !dateHelper.isKstActive(f))
        ) {
          return 0;
        }

        //check if value is set, iof not return 0
        if (f[`${field}`]) {
          //check if KST von is in future
          //if it is do not include it in calculation
          if (dateHelper.isInFuture(f.von)) {
            return 0;
          }

          //check if KST bis is in current month
          //if it is so there are additional checks to be done
          if (dateHelper.isInCurrentMonth(f.bis)) {
            if (dateHelper.areDatesOnSameDay(austrittsdatum, f.bis)) {
              if (dateHelper.isLastDayInMonth(f.bis)) {
                return f[`${field}`];
              } else {
                return this.calculateBruttoBasedOnWorkingDays(
                  f[`${field}`],
                  dateHelper.noTimeDate(new Date(f.bis)),
                  false,
                );
              }
            } else {
              if (dateHelper.isLastDayInMonth(f.bis)) {
                return f[`${field}`];
              } else {
                if (dateHelper.isKstActive(f)) {
                  return this.calculateBruttoBasedOnWorkingDays(
                    f[`${field}`],
                    dateHelper.noTimeDate(new Date(f.bis)),
                    false,
                  );
                }
                return 0;
              }
            }
          }

          //check if KST von is in current month
          //if it is so there are additional checks to be done
          if (dateHelper.isInCurrentMonth(f.von)) {
            if (dateHelper.areDatesOnSameDay(eintrittsdatum, f.von)) {
              if (dateHelper.isFirstDayInMonth(f.von)) {
                return f[`${field}`];
              } else {
                return this.calculateBruttoBasedOnWorkingDays(
                  f[`${field}`],
                  dateHelper.noTimeDate(new Date(f.von)),
                  true,
                );
              }
            } else {
              if (dateHelper.isFirstDayInMonth(f.von)) {
                return f[`${field}`];
              } else {
                if (dateHelper.isKstActive(f)) {
                  return this.calculateBruttoBasedOnWorkingDays(
                    f[`${field}`],
                    dateHelper.noTimeDate(new Date(f.von)),
                    true,
                  );
                }
                return 0;
              }
            }
          }

          //check if KST von is in past and active (has no bis set or bis is in the future)
          //if it is so include it in calculation
          if (dateHelper.isInPast(f.von) && dateHelper.isKstActive(f)) {
            return f[`${field}`];
          }

          if (austrittsdatum) {
            return;
          }

          if (dateHelper.isKstActive(f)) {
            return this.calculateBruttoBasedOnWorkingDays(
              f[`${field}`],
              dateHelper.noTimeDate(new Date(f.von)),
              true,
            );
          }
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);

    return parseFloat(gesamtbrutto.toFixed(2), 10);
  },

  addUbahnCost(sundays) {
    //counting by sundays in month
    if (sundays) {
      return sundays * 2;
    }

    // counting for a full month
    const weeksCount = dateHelper.sundaysInMonth();
    if (weeksCount === 4) {
      return 8;
    } else if (weeksCount === 5) {
      return 10;
    }
  },

  getPercentageByAge(
    age,
    isFirstMonth,
    isGesamtbruttoRest,
    kommunalsteuer,
    isGeringfuegig,
  ) {
    let SV_Int = 0;
    let SV_REST = 0;
    let BV_Int = isFirstMonth ? 0 : BV;
    let KoST_Int = kommunalsteuer ? KoST : 0;

    if (isGeringfuegig) {
      if (age < 60) {
        SV_Int = 17.6;
        return SV_Int + DB + DZ + KoST_Int + BV_Int;
      } else if (age >= 60) {
        SV_Int = 16.4;
        return SV_Int + DB_UBER_60 + DZ + KoST_Int + BV_Int;
      }
    }

    if (age < 55) {
      SV_Int = isGesamtbruttoRest ? SV_REST : SV;
      return SV_Int + DB + DZ + KoST_Int + BV_Int;
    } else if (age >= 55 && age < 60) {
      SV_Int = isGesamtbruttoRest ? SV_REST : SV;
      return SV_Int + DB + DZ + KoST_Int + BV_Int;
    } else if (age >= 60 && age < 63) {
      SV_Int = isGesamtbruttoRest ? SV_REST : SV_60_63;
      return SV_Int + DB_UBER_60 + DZ + KoST_Int + BV_Int;
    } else if (age >= 63) {
      SV_Int = isGesamtbruttoRest ? SV_REST : SV_UBER_63;
      return SV_Int + DB_UBER_60 + DZ + KoST_Int + BV_Int;
    }
  },

  isEintrittdatumActive(date) {
    return (
      dateHelper.noTimeDate(new Date(date)) <=
      dateHelper.noTimeDate(new Date(date))
    );
  },

  _percentage(num, per) {
    if (isNaN(per)) {
      num = parseFloat(num);
    }
    return (num / 100) * per;
  },
};
