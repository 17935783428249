import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import MoneyField from '@/shared/fields/money-field';

function label(name) {
  return i18n(`entities.einstufung.fields.${name}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new StringField('name', label('name'), {
    required: true,
    max: 255,
  }),
  grundgehaltBrutto: new MoneyField(
    'grundgehaltBrutto',
    label('grundgehaltBrutto'),
    {
      required: true,
      min: 0,
      max: 10000000,
      scale: 2,
    },
  ),
};

export class EinstufungModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
