import Layout from '@/modules/layout/components/layout.vue';
import Permissions from '@/security/permissions';

const VertragsartListPage = () =>
  import('@/modules/vertragsart/components/vertragsart-list-page.vue');
const VertragsartFormPage = () =>
  import('@/modules/vertragsart/components/vertragsart-form-page.vue');
export default [
  {
    name: '',
    path: '',
    component: Layout,
    meta: { auth: true },
    children: [
      {
        name: 'vertragsart',
        path: '/vertragsart',
        component: VertragsartListPage,
        meta: {
          auth: true,
          permission: Permissions.values.vertragsartNavigate,
        },
      },
      {
        name: 'vertragsartNew',
        path: '/vertragsart/new',
        component: VertragsartFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.vertragsartNavigate,
        },
      },
      {
        name: 'vertragsartEdit',
        path: '/vertragsart/:id/edit',
        component: VertragsartFormPage,
        meta: {
          auth: true,
          permission: Permissions.values.vertragsartNavigate,
        },
        props: true,
      },
    ],
  },
];
