import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class BestaetigungPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.create = permissionChecker.match(
      Permissions.values.bestaetigungCreate,
    );
  }
}
