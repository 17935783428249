import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class BerufPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.berufRead,
    );
    this.berufAutocomplete = permissionChecker.match(
      Permissions.values.berufAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.berufCreate,
    );
  }
}
