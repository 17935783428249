<template>
  <div class="app-pdf-view">
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      width="80%"
    >
      <el-row :gutter="20">
        <el-col :span="18" :offset="0">
          <app-pdf-view
            v-on:loading="setLoading"
            v-model="loading"
            :fileId="fileId"
          ></app-pdf-view>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <span>Actions</span>
            </div>
            <div class="clearfix">
              <el-button
                :disabled="actionsDisabled || loading"
                v-on:click="doUpdateStatus('approved')"
                type="text"
              >
                <app-i18n code="common.approve"></app-i18n>
              </el-button>
            </div>
            <div class="clearfix">
              <el-button
                :disabled="actionsDisabled || loading"
                v-on:click="doUpdateStatus('rejected')"
                type="text"
              >
                <app-i18n code="common.reject"></app-i18n>
              </el-button>
            </div>
            <div class="clearfix">
              <el-button
                :disabled="actionsDisabled || loading"
                v-on:click="doUpdateStatus('revisionRequested')"
                type="text"
              >
                <app-i18n code="common.revise"></app-i18n>
              </el-button>
              <hr class="solid" />
              <div class="clearfix">
                <el-button
                  :disabled="loading"
                  v-on:click="dialogVisible = false"
                  type="text"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </el-button>
              </div>
            </div>
          </el-card>
          <div>
            <el-form
              :model="ruleForm"
              ref="ruleForm"
              :rules="rules"
              label-position="top"
              :inline="false"
              size="normal"
              @submit.native.prevent="doSubmit"
              :disabled="actionsDisabled || loading"
            >
              <el-form-item prop="comment" label="Notiz">
                <el-input
                  class="text-area"
                  type="textarea"
                  size="2"
                  v-model="ruleForm.comment"
                />
              </el-form-item>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
/* import Errors from '@/shared/error/errors'; */
import { mapGetters } from 'vuex';

export default {
  name: 'app-vertragsaenderung-approval-modal',
  props: ['record', 'approvers', 'visible'],
  data() {
    return {
      fileId: null,
      comment: '',
      loading: false,
      ruleForm: {
        comment: '',
      },
      rules: {
        comment: [
          {
            min: 0,
            max: 1024,
            required: false,
            message: 'Bitte fügen Sie einen Kommentar hinzu.',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.fileId = this.record.entwurf[0].id;
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    id() {
      return this.record.id;
    },
    isApprover() {
      return this.approvers.some((f) => f === this.currentUser.id);
    },
    myRequest() {
      if (this.isApprover) {
        return this.record.approvalRequest.find(
          (f) => f.approver.id === this.currentUser.id,
        );
      }
      return null;
    },
    anyRevisedOrRejected() {
      return (
        this.record.approvalRequest.some((f) => f.status === 'rejected') ||
        this.record.approvalRequest.some(
          (f) => f.status === 'revisionRequested',
        )
      );
    },
    decisionMade() {
      return this.myRequest.status != 'requested';
    },
    actionsDisabled() {
      return !this.isApprover || this.decisionMade || this.anyRevisedOrRejected;
    },
    data() {
      return {
        approvalRequest: {
          id: this.record.approvalRequest.find(
            (f) => f.approver.id === this.currentUser.id,
          ).id,
          comment: this.ruleForm.comment,
        },
        updatedAt: this.record.updatedAt,
      };
    },
    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    title() {
      return 'Preview'; //i18n('user.new.titleModal');
    },
  },

  methods: {
    async doCancel() {
      this.dialogVisible = false;
    },
    setLoading(value) {
      this.loading = value;
    },
    async doUpdateStatus(status) {
      if (status != 'approved') {
        this.rules.comment[0].required = true;
        await this.$refs.ruleForm.validate();
      }
      return this.$emit('doUpdateApprovalRequest', {
        id: this.id,
        data: {
          ...this.data,
          approvalRequest: {
            ...this.data.approvalRequest,
            status,
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.text-area {
  padding-top: 20px;
}
</style>
