import authAxios from '@/shared/axios/auth-axios';

export class VertragsaenderungService {
  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/vertragsaenderung`, body);

    return response.data;
  }
  static async createDraft(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.post(`/vertragsaenderung/entwurf`, body);

    return response.data;
  }
  static async createManual(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/vertragsaenderung/manual`, body);

    return response.data;
  }
  static async list(id, orderBy, limit, offset) {
    const params = {
      id,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/vertragsaenderung`, {
      params,
    });

    return response.data;
  }
  static async listPending() {
    const response = await authAxios.get(`/vertragsaenderung/pending`, {});

    return response.data;
  }
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/vertragsaenderung/${id}`, body);

    return response.data;
  }
  static async updateStatus(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/vertragsaenderung/entwurf-status/${id}`,
      body,
    );

    return response.data;
  }
  static async setInactive(id, data) {
    const body = {
      id,
      data,
    };
    const response = await authAxios.put(
      `/vertragsaenderung/set-inactive/${id}`,
      body,
    );
    return response.data;
  }
  static async sendEmailToMitarbeiter(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(
      `/vertragsaenderung/send-email/${id}`,
      body,
    );

    return response.data;
  }
  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const response = await authAxios.delete(`/vertragsaenderung`, {
      params,
    });

    return response.data;
  }
}
