import authAxios from '@/shared/axios/auth-axios';

export class StatistikAustriaService {
  static async listAutocomplete(limit, query, parentId, filter) {
    const params = {
      limit,
      query,
      parentId,
      filter,
    };
    const response = await authAxios.get(`/statistik-austria/autocomplete`, {
      params,
    });
    return response.data;
  }
  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };
    const response = await authAxios.get(`/statistik-austria`, {
      params,
    });
    return response.data;
  }
}
