import { i18n } from '@/i18n';
import { GenericModel } from '@/shared/model/generic-model';
import { UserField } from '@/modules/user/user-field';
import IdField from '@/shared/fields/id-field';
import DateTimeField from '@/shared/fields/date-time-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import StringField from '@/shared/fields/string-field';

function label(name) {
  return i18n(`entities.approvalRequest.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.approvalRequest.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  approver: UserField.relationToOne('approver', label('approver'), {}),
  status: new EnumeratorField(
    'status',
    label('status'),
    [
      {
        id: 'requested',
        label: enumeratorLabel('status', 'requested'),
      },
      {
        id: 'rejected',
        label: enumeratorLabel('status', 'rejected'),
      },
      {
        id: 'revisionRequested',
        label: enumeratorLabel('status', 'revisionRequested'),
      },
      {
        id: 'approved',
        label: enumeratorLabel('status', 'approved'),
      },
    ],
    {},
  ),
  comment: new StringField('comment', label('comment'), {
    max: 1024,
  }),

  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
};

export class ApprovalRequestModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
