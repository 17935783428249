<template>
  <div>
    <div>
      <el-form
        label-position="top"
        :label-width="labelWidthForm"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
      >
        <el-tabs type="border-card">
          <el-tab-pane label="Allgemeine Daten">
            <el-card shadow="never" :body-style="{ padding: '20px' }">
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="4">
                  <el-form-item
                    :label="fields.svNummer.label"
                    :prop="fields.svNummer.name"
                    :required="fields.svNummer.required"
                  >
                    <el-input
                      v-model="model[fields.svNummer.name]"
                      ref="focus"
                      :disabled="isFormDisabled"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item
                    :label="fields.anrede.label"
                    :prop="fields.anrede.name"
                    :required="fields.anrede.required"
                  >
                    <el-select
                      v-model="model[fields.anrede.name]"
                      :disabled="isFormDisabled"
                    >
                      <el-option :value="undefined">--</el-option>
                      <el-option
                        :key="option.id"
                        :label="option.label"
                        :value="option.id"
                        v-for="option in fields.anrede.options"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item
                    :label="fields.titel.label"
                    :prop="fields.titel.name"
                    :required="fields.titel.required"
                    style="formTitel"
                  >
                    <el-input
                      v-model="model[fields.titel.name]"
                      :disabled="isFormDisabled"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="4" :offset="8">
                  <el-form-item
                    :label="fields.mitarbeiterNr.label"
                    :prop="fields.mitarbeiterNr.name"
                    :required="fields.mitarbeiterNr.required"
                  >
                    <el-input
                      :readonly="true"
                      v-model="model[fields.mitarbeiterNr.name]"
                      tabindex="-1"
                      :disabled="isFormDisabled"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="4">
                  <el-form-item
                    :label="fields.nachname.label"
                    :prop="fields.nachname.name"
                    :required="fields.nachname.required"
                  >
                    <el-input
                      v-model="model[fields.nachname.name]"
                      :disabled="isFormDisabled"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item
                    :label="fields.vorname.label"
                    :prop="fields.vorname.name"
                    :required="fields.vorname.required"
                  >
                    <el-input
                      v-model="model[fields.vorname.name]"
                      :disabled="isFormDisabled"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    :label="fields.email.label"
                    :prop="fields.email.name"
                    :required="fields.email.required"
                  >
                    <el-input
                      v-model="model[fields.email.name]"
                      :disabled="isFormDisabled"
                    />
                  </el-form-item>
                </el-col>
                <el-col :offset="3" :span="7">
                  <el-form-item
                    :label="fields.funktion.label"
                    :prop="fields.funktion.name"
                    :required="fields.funktion.required"
                  >
                    <app-funktion-autocomplete-input
                      :fetchFn="fields.funktion.fetchFn"
                      :mapperFn="fields.funktion.mapperFn"
                      :showCreate="true"
                      mode="single"
                      v-model="model[fields.funktion.name]"
                    ></app-funktion-autocomplete-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="12" :offset="0"></el-col>
              </el-row>
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="8">
                  <el-form-item
                    :label="fields.strasse.label"
                    :prop="fields.strasse.name"
                    :required="fields.strasse.required"
                  >
                    <el-input
                      v-model="model[fields.strasse.name]"
                      :disabled="isFormDisabled"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item
                    :label="fields.plz.label"
                    :prop="fields.plz.name"
                    :required="fields.plz.required"
                  >
                    <el-input
                      v-model="model[fields.plz.name]"
                      :disabled="isFormDisabled"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-form-item
                    :label="fields.stadt.label"
                    :prop="fields.stadt.name"
                    :required="fields.stadt.required"
                  >
                    <el-input
                      v-model="model[fields.stadt.name]"
                      :disabled="isFormDisabled"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex" class="row-bg" :gutter="20">
                <el-col :span="4">
                  <el-form-item
                    :label="fields.geburtsdatum.label"
                    :prop="fields.geburtsdatum.name"
                    :required="fields.geburtsdatum.required"
                  >
                    <el-date-picker
                      @change="handleGeburtsdatumChange"
                      :format="elementUiDateFormat"
                      placeholder
                      v-model="model[fields.geburtsdatum.name]"
                      :disabled="isFormDisabled"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item
                    :label="fields.telefon.label"
                    :prop="fields.telefon.name"
                    :required="fields.telefon.required"
                  >
                    <el-input
                      v-model="model[fields.telefon.name]"
                      :disabled="isFormDisabled"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>
            <div style="padding-top: 10px">
              <el-card shadow="always" :body-style="{ padding: '20px' }">
                <span>Aufenthaltstitel</span>
                <el-row :gutter="20">
                  <el-col :span="3">
                    <el-form-item
                      :label="fields.aufenthaltstitelVon.label"
                      :prop="fields.aufenthaltstitelVon.name"
                      :required="fields.aufenthaltstitelVon.required"
                    >
                      <el-date-picker
                        :format="elementUiDateFormat"
                        placeholder
                        v-model="model[fields.aufenthaltstitelVon.name]"
                        :disabled="isFormDisabled"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3" :offset="0">
                    <el-form-item
                      :label="fields.aufenthaltstitelBis.label"
                      :prop="fields.aufenthaltstitelBis.name"
                      :required="fields.aufenthaltstitelBis.required"
                    >
                      <el-date-picker
                        :format="elementUiDateFormat"
                        placeholder
                        v-model="model[fields.aufenthaltstitelBis.name]"
                        :disabled="isFormDisabled"
                      ></el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      :label="fields.aufenthaltstitelArt.label"
                      :prop="fields.aufenthaltstitelArt.name"
                      :required="fields.aufenthaltstitelArt.required"
                    >
                      <el-input
                        v-model="model[fields.aufenthaltstitelArt.name]"
                        :disabled="isFormDisabled"
                      />
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-card>
              <div style="padding-top: 10px">
                <app-jahreskarte-main
                  ref="jahreskarte"
                  :modal="modal"
                  :isDisabled="isFormDisabled"
                ></app-jahreskarte-main>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Anstellungsdaten">
            <el-form-item>
              <app-mitarbeiter-anstellung-form
                v-if="anstellung"
                :record="anstellung"
                :formValidationSuccess="formValidationSuccess"
                ref="anstellung"
                :saveLoading="saveLoading"
                @doSetMutterschutz="doSetMutterschutz"
                @doUpdateMutterschutz="doUpdateMutterschutz"
                @doRemoveMutterschutz="doRemoveMutterschutz"
                @doSetMutterkarenz="doSetMutterkarenz"
                @doUpdateMutterkarenz="doUpdateMutterkarenz"
                @doRemoveMutterkarenz="doRemoveMutterkarenz"
                @doSetBildungskarenz="doSetBildungskarenz"
                @doUpdateBildungskarenz="doUpdateBildungskarenz"
                @doRemoveBildungskarenz="doRemoveBildungskarenz"
                @doSetPapamonat="doSetPapamonat"
                @doUpdatePapamonat="doUpdatePapamonat"
                @doRemovePapamonat="doRemovePapamonat"
                @doSubmitKarenzvertretung="doSubmitKarenzvertretung"
                @validateForm="validateForm"
              ></app-mitarbeiter-anstellung-form>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="Bankdaten">
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  :label="fields.iban.label"
                  :prop="fields.iban.name"
                  :required="fields.iban.required"
                >
                  <el-input
                    v-model="model[fields.iban.name]"
                    :disabled="isFormDisabled"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  :label="fields.bic.label"
                  :prop="fields.bic.name"
                  :required="fields.bic.required"
                >
                  <el-input
                    v-model="model[fields.bic.name]"
                    :disabled="isFormDisabled"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Statistik Austria">
            <el-row :gutter="20">
              <el-col :span="6">
                <el-form-item
                  :label="fields.staatsbuergerschaft.label"
                  :prop="fields.staatsbuergerschaft.name"
                  :required="fields.staatsbuergerschaft.required"
                >
                  <app-staatsbuergerschaft-autocomplete-input
                    :fetchFn="fields.staatsbuergerschaft.fetchFn"
                    :mapperFn="fields.staatsbuergerschaft.mapperFn"
                    :showCreate="!modal"
                    mode="single"
                    v-model="model[fields.staatsbuergerschaft.name]"
                    :disabled="isFormDisabled"
                  ></app-staatsbuergerschaft-autocomplete-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="fields.sa_sex.label"
                  :prop="fields.sa_sex.name"
                  :required="fields.sa_sex.required"
                >
                  <app-statistik-austria-autocomplete-input
                    :fetchFn="fields.sa_sex.fetchFn"
                    :mapperFn="fields.sa_sex.mapperFn"
                    :showCreate="!modal"
                    mode="single"
                    :filter="statistikAustria.sex"
                    v-model="model[fields.sa_sex.name]"
                    :disabled="isFormDisabled"
                  ></app-statistik-austria-autocomplete-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item
                  :label="fields.sa_ausb.label"
                  :prop="fields.sa_ausb.name"
                  :required="fields.sa_ausb.required"
                >
                  <app-statistik-austria-autocomplete-input
                    :fetchFn="fields.sa_ausb.fetchFn"
                    :mapperFn="fields.sa_ausb.mapperFn"
                    :showCreate="!modal"
                    mode="single"
                    :filter="statistikAustria.ausb"
                    v-model="model[fields.sa_ausb.name]"
                    :disabled="isFormDisabled"
                  ></app-statistik-austria-autocomplete-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  :label="fields.saGebj.label"
                  :prop="fields.saGebj.name"
                  :required="fields.saGebj.required"
                >
                  <el-input
                    v-model="model[fields.saGebj.name]"
                    :disabled="isFormDisabled"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  :label="fields.sa_taet1.label"
                  :prop="fields.sa_taet1.name"
                  :required="fields.sa_taet1.required"
                >
                  <app-statistik-austria-autocomplete-input
                    :fetchFn="fields.sa_taet1.fetchFn"
                    :mapperFn="fields.sa_taet1.mapperFn"
                    :showCreate="!modal"
                    mode="single"
                    :filter="statistikAustria.taet1"
                    v-model="model[fields.sa_taet1.name]"
                    :disabled="isFormDisabled"
                  ></app-statistik-austria-autocomplete-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="fields.sa_taet2.label"
                  :prop="fields.sa_taet2.name"
                  :required="fields.sa_taet2.required"
                >
                  <app-statistik-austria-autocomplete-input
                    :fetchFn="fields.sa_taet2.fetchFn"
                    :mapperFn="fields.sa_taet2.mapperFn"
                    :showCreate="!modal"
                    y
                    mode="single"
                    :filter="statistikAustria.taet2"
                    v-model="model[fields.sa_taet2.name]"
                    :disabled="isFormDisabled"
                  ></app-statistik-austria-autocomplete-input>
                </el-form-item>
              </el-col>

              <el-col :span="2">
                <el-form-item
                  :label="fields.sa_verw.label"
                  :prop="fields.sa_verw.name"
                  :required="fields.sa_verw.required"
                >
                  <app-statistik-austria-autocomplete-input
                    :fetchFn="fields.sa_verw.fetchFn"
                    :mapperFn="fields.sa_taet2.mapperFn"
                    :showCreate="!modal"
                    mode="single"
                    :filter="statistikAustria.verw"
                    v-model="model[fields.sa_verw.name]"
                    :disabled="isFormDisabled"
                  ></app-statistik-austria-autocomplete-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item
                  :label="fields.sa_fkt.label"
                  :prop="fields.sa_fkt.name"
                  :required="fields.sa_taet2.required"
                >
                  <app-statistik-austria-autocomplete-input
                    :fetchFn="fields.sa_fkt.fetchFn"
                    :mapperFn="fields.sa_fkt.mapperFn"
                    :showCreate="!modal"
                    mode="single"
                    :filter="statistikAustria.fkt"
                    v-model="model[fields.sa_fkt.name]"
                    :disabled="isFormDisabled"
                  ></app-statistik-austria-autocomplete-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  :label="fields.saFte.label"
                  :prop="fields.saFte.name"
                  :required="fields.saFte.required"
                >
                  <el-input
                    v-model="model[fields.saFte.name]"
                    :disabled="isFormDisabled"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  :label="fields.saStudienrichtung.label"
                  :prop="fields.saStudienrichtung.name"
                  :required="fields.saStudienrichtung.required"
                >
                  <el-input
                    v-model="model[fields.saStudienrichtung.name]"
                    :disabled="isFormDisabled"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="Personalakte">
            <div class="personal-akte-tabs">
              <el-tabs>
                <el-tab-pane label="Vertrag">
                  <app-vertrag-main></app-vertrag-main>
                </el-tab-pane>
                <el-tab-pane label="Vertragsergänzung">
                  <app-vertragsergaenzung-main></app-vertragsergaenzung-main>
                </el-tab-pane>
                <el-tab-pane label="Vertragsänderung">
                  <app-vertragsaenderung-main></app-vertragsaenderung-main>
                </el-tab-pane>
                <el-tab-pane label="Beendigung">
                  <app-beendigung-main></app-beendigung-main>
                </el-tab-pane>
                <el-tab-pane label="Persönliche Daten">
                  <el-form-item
                    :label="fields.persoenlicheData.label"
                    :prop="fields.persoenlicheData.name"
                    :required="fields.persoenlicheData.required"
                  >
                    <app-file-upload
                      :max="fields.persoenlicheData.max"
                      :storage="fields.persoenlicheData.storage"
                      :formats="fields.persoenlicheData.formats"
                      :permissions="fields.persoenlicheData.permissions"
                      v-model="model[fields.persoenlicheData.name]"
                      :disabled="isFormDisabled"
                    ></app-file-upload>
                  </el-form-item>
                </el-tab-pane>
                <el-tab-pane label="Mitteilung">
                  <el-form-item
                    :label="fields.mitteilung.label"
                    :prop="fields.mitteilung.name"
                    :required="fields.mitteilung.required"
                  >
                    <app-file-upload
                      :max="fields.mitteilung.max"
                      :storage="fields.mitteilung.storage"
                      :formats="fields.mitteilung.formats"
                      :permissions="fields.mitteilung.permissions"
                      v-model="model[fields.mitteilung.name]"
                      :disabled="isFormDisabled"
                    ></app-file-upload>
                  </el-form-item>
                </el-tab-pane>
                <el-tab-pane label="Karenz">
                  <el-form-item
                    :label="fields.karenz.label"
                    :prop="fields.karenz.name"
                    :required="fields.karenz.required"
                  >
                    <app-file-upload
                      :max="fields.karenz.max"
                      :storage="fields.karenz.storage"
                      :formats="fields.karenz.formats"
                      :permissions="fields.karenz.permissions"
                      v-model="model[fields.karenz.name]"
                      :disabled="isFormDisabled"
                    ></app-file-upload>
                  </el-form-item>
                </el-tab-pane>
                <el-tab-pane label="Krankmeldung">
                  <el-form-item
                    :label="fields.krankmeldung.label"
                    :prop="fields.krankmeldung.name"
                    :required="fields.krankmeldung.required"
                  >
                    <app-file-upload
                      :max="fields.krankmeldung.max"
                      :storage="fields.krankmeldung.storage"
                      :formats="fields.krankmeldung.formats"
                      :permissions="fields.krankmeldung.permissions"
                      v-model="model[fields.krankmeldung.name]"
                      :disabled="isFormDisabled"
                    ></app-file-upload>
                  </el-form-item>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-tab-pane>
          <el-tab-pane label="History">
            <div>
              <app-mitarbeiter-history-list-table></app-mitarbeiter-history-list-table>
            </div>
          </el-tab-pane>
          <div class="mitarbeiter-form-buttons">
            <el-button
              :disabled="saveLoading || isFormDisabled"
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="common.save"></app-i18n>
            </el-button>
            <el-button
              :disabled="saveLoading"
              @click="doCancel"
              icon="el-icon-fa-close"
            >
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-tabs>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { MitarbeiterModel } from '@/modules/mitarbeiter/mitarbeiter-model';
import AnstellungForm from '@/modules/mitarbeiter/components/mitarbeiter-anstellung-form.vue';
import MitarbeiterHistoryListTable from '@/modules/mitarbeiter/components/mitarbeiter-history-list-table.vue';
import VertragMain from '@/modules/vertrag/components/vertrag-main.vue';
import BeendigungMain from '@/modules/beendigung/components/beendigung-main.vue';
import VertragsergaenzungMain from '@/modules/vertragsergaenzung/components/vertragsergaenzung-main.vue';
import VertagsaenderungMain from '@/modules/vertragsaenderung/components/vertragsaenderung-main.vue';
import JahreskarteMainComponent from '@/modules/jahreskarte/components/jahreskarte-main.vue';

/* import iban from 'iban'; */
const { fields } = MitarbeiterModel;
const formSchema = new FormSchema([
  fields.id,
  fields.mitarbeiterNr,
  fields.anrede,
  fields.titel,
  fields.nachname,
  fields.vorname,
  fields.email,
  fields.strasse,
  fields.plz,
  fields.stadt,
  fields.svNummer,
  fields.geburtsdatum,
  fields.telefon,
  fields.staatsbuergerschaft,
  fields.saGebj,
  fields.saStudienrichtung,
  fields.funktion,
  fields.saFte,
  fields.sa_sex,
  fields.sa_ausb,
  fields.sa_verw,
  fields.sa_taet1,
  fields.sa_taet2,
  fields.sa_fkt,
  fields.iban,
  fields.bic,
  fields.aufenthaltstitelVon,
  fields.aufenthaltstitelBis,
  fields.aufenthaltstitelArt,
  fields.persoenlicheData,
  fields.mitteilung,
  fields.karenz,
  fields.krankmeldung,
  fields.updatedAt,
]);
export default {
  name: 'app-mitarbeiter-form',
  props: [
    'isEditing',
    'record',
    'saveLoading',
    'viewMode',
    'modal',
    'isFormDisabled',
  ],
  components: {
    [AnstellungForm.name]: AnstellungForm,
    [MitarbeiterHistoryListTable.name]: MitarbeiterHistoryListTable,
    [VertragMain.name]: VertragMain,
    [VertragsergaenzungMain.name]: VertragsergaenzungMain,
    [VertagsaenderungMain.name]: VertagsaenderungMain,
    [BeendigungMain.name]: BeendigungMain,
    [JahreskarteMainComponent.name]: JahreskarteMainComponent,
  },
  data() {
    return {
      rules: formSchema.rules(),
      anstellung: {},
      model: null,
      loaded: false,
      formValidationSuccess: false,
      statistikAustria: {
        ausb: 'sa_ausb',
        sex: 'sa_sex',
        verw: 'sa_verw',
        fkt: 'sa_fkt',
        taet1: 'sa_taet1',
        taet2: 'sa_taet2',
      },
    };
  },
  created() {
    this.model = formSchema.initialValues(this.record || {});
    if (this.isEditing) {
      this.anstellung = this.record.anstellung || {};
    }
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      isInactive: 'mitarbeiter/form/isInactive',
      reActivationStarted: 'mitarbeiter/form/reActivationStarted',
      currentUser: 'auth/currentUser',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),
    fields() {
      return fields;
    },
  },
  watch: {
    model: {
      deep: true,
      handler: function () {
        this.$store.dispatch('mitarbeiter/form/doSetFormModel', this.model);
      },
    },
  },
  methods: {
    doCancel() {
      this.$emit('cancel');
    },
    menuItemClick(value) {
      console.log(value);
    },
    handleGeburtsdatumChange(value) {
      this.model.saGebj = value.getFullYear().toString();
    },

    async doSetMutterschutz(payload) {
      let data = {};
      try {
        data = await this.prepareFormData(payload);
      } catch (error) {
        return;
      }
      return this.$emit('setMutterschutz', data);
    },

    async doUpdateMutterschutz(payload) {
      let data = {};
      try {
        data = await this.prepareFormData(payload);
      } catch (error) {
        return;
      }
      return this.$emit('updateMutterschutz', data);
    },

    async doRemoveMutterschutz() {
      let data = {};
      try {
        const { id, updatedAt } = formSchema.cast(this.model);
        data = {
          id,
          values: { updatedAt },
        };
      } catch (error) {
        return;
      }
      return this.$emit('removeMutterschutz', data);
    },

    async doSetMutterkarenz(payload) {
      let data = {};
      try {
        data = await this.prepareFormData(payload);
      } catch (error) {
        return;
      }
      return this.$emit('setMutterkarenz', data);
    },

    async doUpdateMutterkarenz(payload) {
      let data = {};
      try {
        data = await this.prepareFormData(payload);
      } catch (error) {
        return;
      }
      return this.$emit('updateMutterkarenz', data);
    },

    async doRemoveMutterkarenz() {
      let data = {};
      try {
        const { id, updatedAt } = formSchema.cast(this.model);
        data = {
          id,
          values: { updatedAt },
        };
      } catch (error) {
        return;
      }
      return this.$emit('removeMutterkarenz', data);
    },

    async doSetBildungskarenz(payload) {
      let data = {};
      try {
        data = await this.prepareFormData(payload);
      } catch (error) {
        return;
      }
      return this.$emit('setBildungskarenz', data);
    },

    async doUpdateBildungskarenz(payload) {
      let data = {};
      try {
        data = await this.prepareFormData(payload);
      } catch (error) {
        return;
      }
      return this.$emit('updateBildungskarenz', data);
    },

    async doRemoveBildungskarenz() {
      let data = {};
      try {
        const { id, updatedAt } = formSchema.cast(this.model);
        data = {
          id,
          values: { updatedAt },
        };
      } catch (error) {
        return;
      }
      return this.$emit('removeBildungskarenz', data);
    },

    async doSetPapamonat(payload) {
      let data = {};
      try {
        data = await this.prepareFormData(payload);
      } catch (error) {
        return;
      }
      return this.$emit('setPapamonat', data);
    },

    async doUpdatePapamonat(payload) {
      let data = {};
      try {
        data = await this.prepareFormData(payload);
      } catch (error) {
        return;
      }
      return this.$emit('updatePapamonat', data);
    },

    async doRemovePapamonat() {
      let data = {};
      try {
        const { id, updatedAt } = formSchema.cast(this.model);
        data = {
          id,
          values: { updatedAt },
        };
      } catch (error) {
        return;
      }
      return this.$emit('removePapamonat', data);
    },

    async doSubmitKarenzvertretung() {
      let data = {};
      try {
        data = await this.prepareFormData();
      } catch (error) {
        return;
      }
      return this.$emit('submitKarenzvertretung', data);
    },

    async doSubmit() {
      try {
        await this.validateForm();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);
      /*   values.anstellung = this.anstellung; */
      return this.$emit('submit', {
        id,
        values,
      });
    },

    async validateForm(done) {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        await this.$store.dispatch(
          'mitarbeiter/form/showMessage',
          'validation.mixed.notAllFieldsFilled',
        );
        throw error;
      }
      try {
        await this.$refs.anstellung.validateForm();
      } catch (error) {
        await this.$store.dispatch(
          'mitarbeiter/form/showMessage',
          'validation.mixed.notAllFieldsFilled',
        );
        throw error;
      }
      if (done) {
        done();
      }
    },

    async prepareFormData(payload) {
      if (payload) {
        const tmpArray = this.model.karenz.concat(payload);
        this.model.karenz = tmpArray;
      }

      try {
        await this.validateForm();
      } catch (error) {
        return;
      }
      const { id, ...values } = formSchema.cast(this.model);
      return {
        id,
        values,
      };
    },
  },
};
</script>
<style scoped>
.el-form-item {
  margin-bottom: 5px !important;
}
</style>
