import jahreskarteListStore from '@/modules/jahreskarte/jahreskarte-list-store';
import jahreskarteFormStore from '@/modules/jahreskarte/jahreskarte-form-store';
import jahreskarteDestroyStore from '@/modules/jahreskarte/jahreskarte-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: jahreskarteDestroyStore,
    form: jahreskarteFormStore,
    list: jahreskarteListStore,
  },
};
