import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class MitarbeiterPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(Permissions.values.mitarbeiterRead);
    this.mitarbeiterAutocomplete = permissionChecker.match(
      Permissions.values.mitarbeiterAutocomplete,
    );
    this.create = permissionChecker.match(Permissions.values.mitarbeiterCreate);
    this.edit = permissionChecker.match(Permissions.values.mitarbeiterEdit);
    this.editMitarbeiterNr = permissionChecker.match(
      Permissions.values.mitarbeiterEditMitarbeiterNr,
    );
    this.editInactiveMitarbeiter = permissionChecker.match(
      Permissions.values.mitarbeiterEditInactiveMitarbeiter,
    );
    this.import = permissionChecker.match(Permissions.values.mitarbeiterImport);
    this.destroy = permissionChecker.match(
      Permissions.values.mitarbeiterDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.mitarbeiterNavigate,
    );
    this.abmelden = permissionChecker.match(
      Permissions.values.mitarbeiterAbmelden,
    );
    this.removeKarenz = permissionChecker.match(
      Permissions.values.mitarbeiterRemoveKarenz,
    );
    this.createKostenstelle = permissionChecker.match(
      Permissions.values.mitarbeiterCreateKostenstelle,
    );
    this.editKostenstelle = permissionChecker.match(
      Permissions.values.mitarbeiterEditKostenstelle,
    );
    this.destroyKostenstelle = permissionChecker.match(
      Permissions.values.mitarbeiterDestroyKostenstelle,
    );
    this.setBackToActive = permissionChecker.match(
      Permissions.values.mitarbeiterSetBackToActive,
    );
    this.createVertragsentwurf = permissionChecker.match(
      Permissions.values.mitarbeiterVertragsentwurfCreate,
    );
    this.editVertragsentwurf = permissionChecker.match(
      Permissions.values.mitarbeiterVertragsentwurfEdit,
    );
    this.destroyVertragsentwurf = permissionChecker.match(
      Permissions.values.mitarbeiterVertragsentwurfDestroy,
    );
    this.sendEmailToMAVertragsentwurf = permissionChecker.match(
      Permissions.values.mitarbeiterVertragsentwurfSendEmailToMA,
    );
  }
}
