export default {
  formatCurrency(value, includeSymbol) {
    const formattedValue = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    }).format(value);

    let mainValue = formattedValue.slice(0, -2);

    if (includeSymbol) {
      let symbol = formattedValue.substring(formattedValue.length - 1);
      return symbol.concat(' ', mainValue);
    }
    return mainValue;
  },

  formatDecimal(value, decimals) {
    return new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimals,
    }).format(value);
  },
};
