import kostentraegerListStore from '@/modules/kostentraeger/kostentraeger-list-store';
import kostentraegerFormStore from '@/modules/kostentraeger/kostentraeger-form-store';
import kostentraegerDestroyStore from '@/modules/kostentraeger/kostentraeger-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: kostentraegerDestroyStore,
    form: kostentraegerFormStore,
    list: kostentraegerListStore,
  },
};
