<template>
  <div class="app-page-toolbar">
    <el-button
      v-on:click="$emit('doOpenFormUploadModal')"
      size="small"
      icon="el-icon-fa-plus"
      type="primary"
      v-if="hasPermissionToCreate"
      :disabled="isDisabled"
    >
      <app-i18n code="entities.vertrag.uploadManual.uploadButton"></app-i18n>
    </el-button>

    <el-tooltip
      :content="destroyButtonTooltip"
      :disabled="!destroyButtonTooltip"
      v-if="hasPermissionToDestroy"
    >
      <span>
        <el-button
          size="small"
          :disabled="destroyButtonDisabled"
          @click="doDestroyAllSelected"
          icon="el-icon-fa-trash"
          type="primary"
        >
          <app-i18n code="common.destroy"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { VertragPermissions } from '@/modules/vertrag/vertrag-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-vertrag-list-toolbar',

  props: ['isDisabled'],

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'vertrag/hasRows',
      loading: 'vertrag/loading',
      selectedRows: 'vertrag/selectedRows',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new VertragPermissions(this.currentUser).create;
    },

    hasPermissionToEdit() {
      return new VertragPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new VertragPermissions(this.currentUser).destroy;
    },

    removeButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    removeButtonTooltip() {
      return !this.selectedRows.length ? i18n('common.mustSelectARow') : null;
    },

    destroyButtonDisabled() {
      return !this.selectedRows.length || this.loading || this.isDisabled;
    },

    destroyButtonTooltip() {
      if (!this.selectedRows.length || this.loading) {
        return i18n('common.mustSelectARow');
      }

      return null;
    },
  },

  methods: {
    ...mapActions({
      doRemoveAllSelected: 'vertrag/doRemoveAllSelected',
      doDestroyAll: 'vertrag/doDestroyAll',
    }),

    async doDestroyAllSelected() {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroyAll(this.selectedRows.map((item) => item.id));
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
