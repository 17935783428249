<template>
  <div>
    <app-vertrag-list-toolbar
      v-on:doOpenFormUploadModal="doOpenFormUploadModal"
      :isDisabled="isFormDisabled"
    ></app-vertrag-list-toolbar>
    <app-vertrag-list-table
      v-on:doOpenPreviewModal="doOpenVertragPreviewModal"
      v-on:doOpenRequestsModal="doOpenVertragRequestsModal"
      v-on:doOpenFormModal="doOpenFormModal"
      :isDisabled="isFormDisabled"
    ></app-vertrag-list-table>
    <app-approval-request-list-table
      :visible="dialogApprovalRequestVisible"
      v-on:close="doCloseEntwurfRequestsModal"
      :rows="entwurfRequests"
    ></app-approval-request-list-table>
    <div>
      <portal to="modal">
        <app-vertrag-approval-modal
          :visible="dialogVertragApprovalVisible"
          v-on:close="doCloseVertragApprovalModal"
          v-on:success="doCloseVertragApprovalModal"
          v-on:doUpdateApprovalRequest="doUpdateApprovalRequest"
          v-if="dialogVertragApprovalVisible"
          :record="row"
          :approvers="approvers"
        ></app-vertrag-approval-modal>
        <app-vertrag-entwurf-modal
          :visible="dialogEntwurfVisible"
          v-on:close="closeEntwurfDialog"
          v-if="dialogEntwurfVisible"
          :file="entwurf"
        ></app-vertrag-entwurf-modal>
        <app-vertrag-form-modal
          v-if="formDialogVisible"
          :visible="formDialogVisible"
          :record="row"
          :mitarbeiterId="mitarbeiterId"
          v-on:close="doCloseFormModal"
          v-on:create="create"
          v-on:update="update"
          v-on:openEntwurfDialog="openEntwurfDialog"
          ref="formModal"
        ></app-vertrag-form-modal>
        <app-vertrag-form-upload-modal
          v-if="formUploadDialogVisible"
          :visible="formUploadDialogVisible"
          :record="row"
          :mitarbeiterId="mitarbeiterId"
          v-on:close="doCloseFormUploadModal"
          v-on:create="createManual"
        ></app-vertrag-form-upload-modal>
      </portal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VertragApprovalModal from '@/modules/vertrag/components/vertrag-approval-modal.vue';
import VertragEntwurfModal from '@/modules/vertrag/components/vertrag-entwurf-modal.vue';
import VertragListToolbar from '@/modules/vertrag/components/vertrag-list-toolbar.vue';
import VertragFormModal from '@/modules/vertrag/components/vertrag-form-modal.vue';
import VertragFormUploadModal from '@/modules/vertrag/components/vertrag-form-upload-modal.vue';
import VertragListTable from '@/modules/vertrag/components/vertrag-list-table.vue';
import ApprovalRequestListTable from '@/modules/approval-request/components/approval-request-list-table.vue';
import { FileUploader } from '@/shared/file-upload/file-uploader';

export default {
  name: 'app-vertrag-main',
  props: ['id'],
  components: {
    [VertragApprovalModal.name]: VertragApprovalModal,
    [VertragEntwurfModal.name]: VertragEntwurfModal,
    [VertragListToolbar.name]: VertragListToolbar,
    [VertragListTable.name]: VertragListTable,
    [VertragFormModal.name]: VertragFormModal,
    [VertragFormUploadModal.name]: VertragFormUploadModal,
    [ApprovalRequestListTable.name]: ApprovalRequestListTable,
  },
  data() {
    return {
      row: null,
      entwurfRequests: [],
      dialogVertragApprovalVisible: false,
      dialogApprovalRequestVisible: false,
      dialogEntwurfVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      contractApprover: 'settings/contractApprover',
      mitarbeiterId: 'mitarbeiter/form/id',
      isFormDisabled: 'mitarbeiter/form/isFormDisabled',
      formDialogVisible: 'vertrag/formDialogVisible',
      formUploadDialogVisible: 'vertrag/formUploadDialogVisible',
      entwurf: 'vertrag/entwurfFile',
    }),
    isEditing() {
      return !!this.id;
    },
    approvers() {
      return this.contractApprover.map((f) => f.id);
    },
  },
  async created() {},
  methods: {
    ...mapActions({
      loading: 'vertrag/loading',
      saveLoading: 'vertrag/saveLoading',
      doUpdateStatus: 'vertrag/doUpdateStatus',
      doShowFormDialog: 'vertrag/doShowFormDialog',
      doShowFormUploadDialog: 'vertrag/doShowFormUploadDialog',
      doCreate: 'vertrag/doCreate',
      doCreateManual: 'vertrag/doCreateManual',
      doUpdate: 'vertrag/doUpdate',
    }),

    async create(payload) {
      await this.doCreate(payload.values);
      this.doShowFormDialog();
    },

    async createManual(payload) {
      await this.doCreateManual(payload.values);
      this.doShowFormUploadDialog();
    },

    async update(payload) {
      await this.doUpdate(payload);
      this.doShowFormDialog();
    },

    onModalSuccess() {
      this.doCloseVertragApprovalModal();
    },
    doOpenVertragPreviewModal(row) {
      this.row = row;
      this.dialogVertragApprovalVisible = true;
    },
    doCloseVertragApprovalModal() {
      this.dialogVertragApprovalVisible = false;
      this.row = null;
    },
    doOpenVertragRequestsModal(rows) {
      this.entwurfRequests = rows;
      this.dialogApprovalRequestVisible = true;
    },
    doCloseEntwurfRequestsModal() {
      this.dialogApprovalRequestVisible = false;
    },
    doOpenFormModal(payload) {
      this.row = payload;
      this.doShowFormDialog();
    },
    doOpenFormUploadModal(payload) {
      this.row = payload;
      this.doShowFormUploadDialog();
    },
    doCloseFormModal() {
      this.doShowFormDialog();
      this.row = null;
    },
    doCloseFormUploadModal() {
      this.doShowFormUploadDialog();
      this.row = null;
    },
    openEntwurfDialog() {
      this.dialogEntwurfVisible = true;
    },
    closeEntwurfDialog() {
      this.dialogEntwurfVisible = false;
      if (this.entwurf) {
        FileUploader.removePendingFiles([this.entwurf]);
      }
    },

    async doUpdateApprovalRequest(payload) {
      this.dialogVertragApprovalVisible = false;
      await this.doUpdateStatus(payload);
    },
  },
};
</script>

<style></style>
