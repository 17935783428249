import { VorlageDienstgeberService } from '@/modules/vorlage-dienstgeber/vorlage-dienstgeber-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    record: null,
    formModel: null,
    isSaved: false,
  },

  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    isSaved: (state) => !!state.isSaved,
    formModel: (state) => state.formModel,
    pendingFiles: (state, getters) => {
      if (!getters.formModel) return [];

      return getters.formModel.vorlage.filter((f) => f.new);
    },
  },

  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
      state.isSaved = false;
      state.formModel = null;
    },

    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
      state.formModel = null;
      state.isSaved = false;
    },

    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
      state.formModel = null;
    },

    CREATE_STARTED(state) {
      state.saveLoading = true;
      state.isSaved = false;
    },

    CREATE_SUCCESS(state) {
      state.saveLoading = false;
      state.isSaved = true;
    },

    CREATE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
      state.isSaved = false;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
      state.formModel = null;
      state.isSaved = true;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },

    SET_FORM_MODEL(state, payload) {
      state.formModel = payload;
    },
  },

  actions: {
    doNew({ commit }) {
      commit('RESET');
    },

    async doSetFormModel({ commit }, payload) {
      commit('SET_FORM_MODEL', payload);
    },

    async doFind({ commit }, id) {
      try {
        commit('FIND_STARTED');

        const record = await VorlageDienstgeberService.find(id);

        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/vorlage-dienstgeber');
      }
    },

    async doCreate({ commit }, values) {
      try {
        commit('CREATE_STARTED');
        await VorlageDienstgeberService.create(values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('entities.vorlageDienstgeber.create.success'));
        routerAsync().push('/vorlage-dienstgeber');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },

    async doUpdate({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');

        await VorlageDienstgeberService.update(id, values);

        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.vorlageDienstgeber.update.success'));
        routerAsync().push('/vorlage-dienstgeber');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },
  },
};
