import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import EnumeratorField from '@/shared/fields/enumerator-field';
import DateField from '@/shared/fields/date-field';
import StringField from '@/shared/fields/string-field';
import Storage from '@/security/storage';
import FilesField from '@/shared/fields/files-field';
import Permissions from '@/security/permissions';

function label(name) {
  return i18n(`entities.jahreskarte.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.jahreskarte.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  nummer: new StringField('nummer', label('nummer'), {
    required: false,
    max: 30,
  }),
  zahlung: new EnumeratorField(
    'zahlung',
    label('zahlung'),
    [
      {
        id: 'jaehrlich',
        label: enumeratorLabel('zahlung', 'jaehrlich'),
      },
      {
        id: 'monatlich',
        label: enumeratorLabel('zahlung', 'monatlich'),
      },
    ],
    {
      required: true,
    },
  ),
  von: new DateField('von', label('von'), {
    required: true,
  }),
  bis: new DateField('bis', label('bis'), {
    required: true,
  }),
  rechnung: new FilesField(
    'rechnung',
    label('rechnung'),
    Storage.values.jahreskarteRechnung,
    Permissions.getFilePermissions(
      'jahreskarteFileFields',
      'jahreskarteRechnung',
    ),
    {
      required: true,
      max: 20 * 1024 * 1024,
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  mitarbeiter: new StringField('mitarbeiter', label('mitarbeiter')),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class JahreskarteModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
