import kostenstelleListStore from '@/modules/kostenstelle/kostenstelle-list-store';
import kostenstelleFormStore from '@/modules/kostenstelle/kostenstelle-form-store';
import kostenstelleDestroyStore from '@/modules/kostenstelle/kostenstelle-destroy-store';
import kostenstelleImporterStore from '@/modules/kostenstelle/kostenstelle-importer-store';

export default {
  namespaced: true,

  modules: {
    destroy: kostenstelleDestroyStore,
    form: kostenstelleFormStore,
    list: kostenstelleListStore,
    importer: kostenstelleImporterStore,
  },
};
